import styles from "./index.module.scss";
import { Select } from "../Select";

type Props = {
    setIsDesc: React.Dispatch<React.SetStateAction<boolean>>;
    isDesc: boolean;
    options: string[];
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
    selectedOption: string;
    classNames?: string;
    selectClassNames?:  string;
}

const Sort = ({setIsDesc, isDesc, selectedOption, setSelectedOption, options,classNames, selectClassNames}: Props) => {
    return (
        <div style={{display: 'flex'}} className={classNames}>
            <div style={{marginRight: 10}}>
                <Select
                    classNames={selectClassNames}
                    placeholder="Сортировка"
                    selectedOption={selectedOption} 
                    onChange={e => setSelectedOption(e)} 
                    options={options}
                    />
            </div>
            <div className={styles.ascDesc} onClick={() => setIsDesc(prevState => !prevState)}>
                <span className={ isDesc ? "" : styles.isActive}>
                    asc
                </span>
                <div className={styles.container} style={{alignItems: isDesc ? "end" : "start"}}>
                    <div/>
                </div>
                <span className={ isDesc ? styles.isActive : ""}>
                    desc
                </span>
            </div>
        </div>
    );
};

export {
    Sort
};