import Layout from "widgets/Layout/Layout";
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/ui/Button";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import Container from "widgets/Container/ui";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { getBillStatus } from "functions/billStatus";
import { useGetBillsPlacementQuery, useGetDocumentsExcelBillsQuery } from "api";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { BASE_URL, BASE_URL_FILES } from "app/store/consts";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { FileExportIcon } from "shared/ui/icons/icons";

const columns: ColumnHeaderType[] = [
    {width: "15%", name: 'ID'},
    {width: "10%", name: 'Статус'},
    {width: "15%", name: 'Юр лицо'},
    {width: "15%", name: 'Склад'},
    {width: "10%", name: 'Создано'},
    {width: "25%", name: 'Комментарий'},
    {width: "10%", name: 'Скачать'},
]

const PlacementBillsPage = () => {
    const {data: bills, refetch } = useGetBillsPlacementQuery();
    const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
    const {data: document} = useGetDocumentsExcelBillsQuery({billId: selectedBillId!}, {skip: selectedBillId == null});

    useEffect(() => {
        if (document != null)
        {
            window.open(BASE_URL_FILES + document.name, '_blank');
        }
    }, [document]);

    const [searchText, setSearchText] = useState("");
    
    const navigate = useNavigate();

    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["ID"]);
    const options = ["ID", "Статус", "Юр лицо", "Склад", "Создано", "Комментарий"];

    const filteredData = bills?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.id, option: "ID"},
        {value: el.status, option: "Статус"},
        // {value: el.organization?.name, option: "Юр лицо"},
        // {value: el., option: "Склад"}
        {value: el.objectCreateDate, option: "Создано"},
        {value: el.note, option: "Комментарий"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("ID");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.id, value1: el1.id, option: "ID"},
        {value2: el2.status,  value1: el1.status, option: "Статус"},
        // {value2: el2.organization?.name, value1: el1.organization?.name, option: "Юр лицо"},
        // {value2: el2.warehouseName, value1: el1.warehouseName, option: "Склад"},
        {value2: el2.objectCreateDate, value1: el1.objectCreateDate, option: "Создано"},
        {value2: el2.note, value1: el1.note, option: "Комментарий"},
    ], selectedOption, isDesc));

    return (
        <Layout>
            <Container>
                <div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        <Button inlineStyles={{marginRight: 10}} text="Добавить накладную" onClick={() => navigate(LinkPagesEnum.AddingPlacementBill)}/>
                        <Button text="Скачать шаблон" onClick={() => window.open(BASE_URL + "/media/productsTemplate.xlsx","_blank")}/>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SearchInput 
                            value={searchText} 
                            onChange={setSearchText}
                            inlineStyles={{marginRight: 10}}
                            classNames={styles.searchInput}
                            />
                        <MultipleSelect 
                            text="Параметры"
                            options={options} 
                            selectedOptions={selectedOptions} 
                            setSelectedOptions={setSelectedOptions}
                            inlineStyles={{marginRight: 10}}
                            inlineClasses={styles.select}
                            />
                        <Sort 
                            options={options}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setIsDesc={setIsDesc}
                            isDesc={isDesc}
                            selectClassNames={styles.select}
                            />
                    </div>
                </div>
                <Table columns={columns}>
                    {
                        sortedData?.map(el => (
                            <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => navigate(`${LinkPagesEnum.RedactPlacementBill}?billId=${el.id!}`)}>
                                <TableItemContainer width={columns[0].width}>{el.id}</TableItemContainer>
                                <TableItemContainer width={columns[1].width}>{getBillStatus(el.status!)}</TableItemContainer>
                                <TableItemContainer width={columns[2].width}>23</TableItemContainer>
                                <TableItemContainer width={columns[3].width}>wef</TableItemContainer>
                                <TableItemContainer width={columns[4].width}>{new Date(el.objectCreateDate!).toLocaleString()}</TableItemContainer>
                                <TableItemContainer width={columns[5].width}>{el.note}</TableItemContainer>
                                <TableItemContainer width={columns[6].width}><FileExportIcon onClick={e => {
                                    e.stopPropagation();
                                    setSelectedBillId(el.id!);
                                }}/></TableItemContainer>
                            </TableItem>
                        ))
                    }
                </Table>
            </Container>
        </Layout>
    )
};

export {PlacementBillsPage};