import { FC } from "react";
import styles from "./ItemTerminal.module.scss";
import { ItemTerminalCard } from "../../model";
import { Link } from "react-router-dom";

type ItemCardProps = {
    item: ItemTerminalCard,
}

const ItemCard: FC<ItemCardProps> = ({item}) => {
    return (
        <div className={styles.item}>
            <Link to={item.to || '/'}>
                <div className={styles.contentWrapper} style={{border: `2.5px solid ${item.background}`}}>
                    <img className={styles.image} src={item.icon} alt={item.title} />
                </div>
                <div className={styles.title}>{item.title}</div>
            </Link>
        </div>
    )
};

export default ItemCard;