import { ReactNode } from "react";
import styles from "./index.module.scss";

export type ColumnHeaderType = {
    name: string;
    width: string;
}

type PropsType = {
    columns: ColumnHeaderType[];
    children: ReactNode;
}

const Table = ({children, columns}: PropsType) => {
    return (
        <div className={styles.table}>
            <div className={styles.header}>
                {columns.map(column => <div className={styles.column} style={{width: column.width}}>{column.name}</div>)}
            </div>
            <div className={styles.body}>
                {children}
            </div>
        </div>
    );
};

export {
    Table
};