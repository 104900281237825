enum CellTypeEnum {
    /// <summary>
    /// День
    /// </summary>
    Shipment = 0,

    /// <summary>
    /// Месяц
    /// </summary>
    Acceptance = 1,

    /// <summary>
    /// Год
    /// </summary>
    Other = 2
};

export { CellTypeEnum };