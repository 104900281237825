import styles from "./TitleForm.module.scss";
import Title from "../Title/Title";

type Props = {
    title: string,
};

const TitleForm = ({title}: Props) => {
    return (
        <div className={styles.titleForm}>
            <Title>{title}</Title>
        </div>
    )
};

export default TitleForm;