import { ColumnHeaderType } from "widgets/Table";

export const tableHeadName: ColumnHeaderType[] = [
    {name: 'Ячейка', width: '15%'},
    {name: 'Заполненность', width: '20%'},
    {name: 'Код (ID)', width: '15%'},
    {name: 'Имя', width: '15%'},
    {name: 'Склад', width: '10%'},
    {name: 'Содержимое', width: '10%'},
    {name: 'Этикетка', width: '15%'},
];