import Container from "widgets/Container/ui";
import {Container as SharedContainer} from "shared/ui/Container";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { CreatePlacementBillModel, ReceivedProductOutputModel2, useGetProductsReceivedByWarehouseQuery, useGetStoragePlacesQuery, usePostBillsPlacementBillMutation } from "api";
import React, { useState } from "react";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import Modal from "shared/ui/Modal/Modal";
import { SearchInput } from "shared/ui/SearchInput";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import clsx from "clsx";
import img from "shared/assets/images/box.png";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TextArea } from "shared/ui/TextArea";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Наименование', width: '20%'},
    {name: 'Остаток', width: '10%'},
    {name: "Из", width: "10%" },
    {name: "В", width: "10%" },
    {name: 'Количество', width: '10%'},
];

const AddingPlacementBillPage = () => {
    const {data: productsByWarehouse} = useGetProductsReceivedByWarehouseQuery({warehouseId: 1});
    // const {data: cellsByProduct} = useGetStorageCellsByReceivedProductQuery({productId: productIdByOpenedSelect!}, {skip: productIdByOpenedSelect == null});
    const {data: allCells} = useGetStoragePlacesQuery({warehouseId: 1});
    const [newBill, setNewBill] = useState<CreatePlacementBillModel>({});

    const [addPlacementBill] = usePostBillsPlacementBillMutation();

    const addPlacementBillHandler = async () => {
        try {
            await addPlacementBill({
                createPlacementBillModel: {...newBill,
                    createPlacementBillProducts: addedProducts.map(el => ({
                        receivedProductCellId: el.p.cells!.find(x => x?.cell?.name == el.selectedFromCellName)?.id,
                        quantity: el.quantity,
                        toCellId: allCells!.find(x => x?.name == el.selectedToCellName)?.id,
                    }))
                }
            }).unwrap();

            setNewBill({});
            navigate(LinkPagesEnum.PlacementBills);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<{p: ReceivedProductOutputModel2, selectedFromCellName: string, selectedToCellName: string, quantity: number}[]>([]);
    const [modalSearch, setModalSearch] = useState("");
    const navigate = useNavigate();

    return (
        <Layout>
            <Container>
                <>
                    <div style={{display: "flex"}}>
                        <Button text="Назад" inlineStyles={{marginRight: 10}} className={styles.headerButtons} onClick={() => navigate(LinkPagesEnum.PlacementBills)}/>
                        <Button text="Добавить накладную" className={styles.headerButtons} onClick={addPlacementBillHandler}/>
                    </div>
                    <SharedContainer inlineClasses={styles.modal__fieldsContainer}>
                        <>
                            <Input 
                                classNamesContainer={styles.field}
                                placeholder="Ожидаемая дата реализациим"
                                type="date"
                                value={newBill.realizationDate?.split('T')[0]}
                                onChange={e => setNewBill({...newBill, realizationDate: (new Date(e.target.value).toISOString())})}
                                />
                            <TextArea
                                classNamesContainer={styles.note}
                                placeholder="Коментарий"
                                value={newBill.note}
                                onChange={e => setNewBill({...newBill, note: e.target.value})}
                                />
                        </>
                    </SharedContainer>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            addedProducts?.map(product => {
                                return (
                                    <TableItem classNames={styles.item} inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                        <>
                                            <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={product.p.imageName != null ? product.p.imageName : img} alt={product.p.name!}/></TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {product.p.barcode} </div> </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[2].width}>{product.p.name} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[3].width}>{product.p.article} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[4].width}><Select classNames={styles.select} placeholder="ячейки" selectedOption={product.selectedFromCellName} options={product?.p?.cells == null ? [] : product.p.cells?.map(el => el.cell!.name!)} onChange={val => setAddedProducts(prevState => prevState.map(x => x.p.id == product.p.id ? {...x, selectedFromCellName: val} : x))}/></TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[5].width}><Select classNames={styles.select} placeholder="ячейки" selectedOption={product.selectedToCellName} options={allCells!.map(el => el.name!)} onChange={val => setAddedProducts(prevState => prevState.map(x => x.p.id == product.p.id ? {...x, selectedToCellName: val} : x))}/></TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[6].width}><Input type="number" inlineStyles={{width: 63}} onChange={val => setAddedProducts(prevState => prevState.map(x => x.p.id == product.p.id ? {...x, quantity: Number(val.target.value)} : x))} value={product.quantity?.toString()}/></TableItemContainer> 
                                        </>
                                    </TableItem>
                                )
                            })
                        }
                    </Table>
                    <Modal isCloseCloseIcon isOpen={addProductModalVisible} classNames={styles.modalContainer} close={() => setAddProductModalVisible(prevState => !prevState)}>
                        <div style={{width: "100%"}}>
                            <TitleForm title="Выберите товары"/>
                            <div className={styles.searchContainer}>
                                <SearchInput
                                    value={modalSearch}
                                    onChange={e => setModalSearch(e)}
                                    classNames={styles.searchInput}
                                    />
                            </div>
                            <div style={{marginTop: 40}}>
                                {productsByWarehouse?.map((product) => 
                                    <TableItem 
                                        onClick={() => {
                                            if (addedProducts.filter(product2 => product2.p.id == product.id).length > 0) {
                                                setAddedProducts(prevState => prevState.filter(el => el.p.id != product.id));
                                            }
                                            else {
                                                setAddedProducts(prevState => [...prevState, {p: product, selectedToCellName: "", selectedFromCellName: "", quantity: 0}]);
                                            }
                                        }}   
                                        classNames={clsx(addedProducts.filter(el => el.p.id == product.id).length != 0 ? styles.itemSelected : "", styles.hover)} 
                                        inlineStyles={{marginBottom: 10, width: 690, padding: 20}}
                                        >
                                        <div style={{width: "100%"}}>
                                            <div style={{display: "flex", marginBottom: 20}}>
                                                <img src={product.imageName != null ? product.imageName : img} alt={product.name!} className={styles.modalImg}/>
                                                <div>
                                                    <div className={styles.modalText}>Название: {product.name}</div>
                                                    <div className={styles.modalText}>Артикул: {product.article}</div>
                                                    <div className={styles.modalText}>Штрих-код: {product.barcode}</div>
                                                    <div className={styles.modalText}>Остаток на складе: {product.quantitySum}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </TableItem>
                                )}
                            </div>
                            <Button
                                className={styles.button}
                                text="Закрыть"
                                onClick={e => setAddProductModalVisible(false)}
                                />
                        </div>
                    </Modal>
                </>
            </Container>
        </Layout>
    );
};

export {
    AddingPlacementBillPage,
};