import React, { ChangeEvent, useRef } from "react";
import styles from "./ImportButton.module.scss";
import { BaseSharedComponentType } from "types";
import { Button } from "shared/ui/Button";

type Props = {
    text: string,
    addFile: (file: File) => void,
} & BaseSharedComponentType;

const ImportButton = ({inlineClasses, inlineStyles, text, addFile}: Props) => {
    
    const onSubmitFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!!event.target.files) 
        {
            addFile(event.target.files[0]);
        }
    };
    
    const ref = useRef<HTMLInputElement>(null);

    return (
        <>
            <Button
                text={text}
                className={inlineClasses}
                inlineStyles={inlineStyles}
                onClick={() => ref.current?.click()}
                />
            <input 
                ref={ref} 
                className={styles.input} 
                type="file" 
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                onChange={onSubmitFile}
                />
        </>
    );
};

export default ImportButton;