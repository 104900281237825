import { CSSProperties } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";

type PropsType = {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    text: string;
    classNames?: string;
    inlineStyle?: CSSProperties;
    disabled?: boolean;
};

const Button = ({onClick, text, classNames, inlineStyle, disabled}: PropsType) => {
    return (
        <button disabled={disabled} style={inlineStyle} className={clsx(styles.button, classNames)} onClick={onClick}>
            {text}
        </button>
    );
};

export {
    Button
}