import { CSSProperties, ReactNode } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";

type PropsType = {
    children: ReactNode;
    inlineStyles?: CSSProperties;
    classNames?: string;
    onClick?: () => void;
    hover?: boolean;
}

const TableItem = ({children, inlineStyles, classNames, onClick, hover}: PropsType) => {
    return (
        <div className={clsx(styles.container, classNames, hover && styles.hover)} onClick={onClick} style={inlineStyles}>
            {children}
        </div>
    );
};

export {
    TableItem
};