import { Close2Icon, Search2Icon } from "shared/ui/icons/icons";
import styles from "./index.module.scss";
import { CSSProperties } from "react";
import clsx from "clsx";

type Props = {
    value: string;
    onChange: (value: string) => void;
    onRemoveClick?: () => void;
    onClick?: () => void;
    placeholder?: string;
    classNames?: string;
    inlineStyles?: CSSProperties;
}

const SearchInput = ({value, onChange, onClick, placeholder, classNames, inlineStyles, onRemoveClick}: Props) => {
    return (
        <div className={clsx(styles.container, classNames)} style={inlineStyles} onClick={onClick}>
            { value == "" 
            ? <div className={styles.iconsContainer}><Search2Icon inlineClass={styles.searchIcon}/></div>
            : <div className={styles.iconsContainer}><Close2Icon onClick={onRemoveClick ? () => onRemoveClick() : () => onChange("")} inlineClass={styles.close}/></div>}
            <input value={value} onChange={(e) => onChange(e.target.value)} className={styles.search} placeholder={placeholder == null ? "Введите название" : placeholder}/>
        </div>
    );
};

export {
    SearchInput
};