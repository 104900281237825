import Layout from "widgets/Layout/Layout";

const HomePage = () => {
    return (
        <Layout>
            <h1>Домашняя страница</h1>
            <p>
                Вы находитесь на домашней странице приложения.
                Эта страница предоставляет общее представление о приложении и его возможностях.
            </p>
        </Layout>
    )
};

export { HomePage };