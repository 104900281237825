import { baseApi as api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductsReceivedActions: build.query<
      GetProductsReceivedActionsApiResponse,
      GetProductsReceivedActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/received/actions`,
        params: { Skip: queryArg.skip, Take: queryArg.take },
      }),
    }),
    postProductsReceivedActionsAction: build.mutation<
      PostProductsReceivedActionsActionApiResponse,
      PostProductsReceivedActionsActionApiArg
    >({
      query: (queryArg) => ({
        url: `/products/received/actions/action`,
        method: "POST",
        params: {
          receivedProductCellId: queryArg.receivedProductCellId,
          actionTypeId: queryArg.actionTypeId,
        },
      }),
    }),
    getAdminClientsWarehouses: build.query<
      GetAdminClientsWarehousesApiResponse,
      GetAdminClientsWarehousesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/clients/warehouses`,
        body: queryArg.clientWarehouseFilter,
      }),
    }),
    postAdminClientsWarehouses: build.mutation<
      PostAdminClientsWarehousesApiResponse,
      PostAdminClientsWarehousesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/clients/warehouses`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postAdminEmpoloyee: build.mutation<
      PostAdminEmpoloyeeApiResponse,
      PostAdminEmpoloyeeApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/empoloyee`,
        method: "POST",
        body: queryArg.createEmployeeModel,
      }),
    }),
    postAnalyticsAddVisit: build.mutation<
      PostAnalyticsAddVisitApiResponse,
      PostAnalyticsAddVisitApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/addVisit`,
        method: "POST",
        body: queryArg.visitInputModel,
      }),
    }),
    getAnalyticsAddRecallRequest: build.query<
      GetAnalyticsAddRecallRequestApiResponse,
      GetAnalyticsAddRecallRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/addRecallRequest`,
        params: {
          userId: queryArg.userId,
          sessionId: queryArg.sessionId,
          name: queryArg.name,
          number: queryArg["number"],
          email: queryArg.email,
          telegram: queryArg.telegram,
        },
      }),
    }),
    postAuthLogin: build.mutation<
      PostAuthLoginApiResponse,
      PostAuthLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: "POST",
        body: queryArg.authCredentials,
      }),
    }),
    postAuthRegistration: build.mutation<
      PostAuthRegistrationApiResponse,
      PostAuthRegistrationApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/registration`,
        method: "POST",
        body: queryArg.authCredentials,
      }),
    }),
    getBills: build.query<GetBillsApiResponse, GetBillsApiArg>({
      query: (queryArg) => ({
        url: `/bills`,
        params: {
          Skip: queryArg.skip,
          Take: queryArg.take,
          Status: queryArg.status,
          Type: queryArg["type"],
          BillId: queryArg.billId,
          UserId: queryArg.userId,
          FactoryBarcode: queryArg.factoryBarcode,
        },
      }),
    }),
    deleteBills: build.mutation<DeleteBillsApiResponse, DeleteBillsApiArg>({
      query: (queryArg) => ({
        url: `/bills`,
        method: "DELETE",
        params: { id: queryArg.id },
      }),
    }),
    getBillsGetAcceptanceBillsByProductBarcode: build.query<
      GetBillsGetAcceptanceBillsByProductBarcodeApiResponse,
      GetBillsGetAcceptanceBillsByProductBarcodeApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/getAcceptanceBillsByProductBarcode`,
        params: { barcode: queryArg.barcode },
      }),
    }),
    getBillsGetShipmentBillsByProductBarcode: build.query<
      GetBillsGetShipmentBillsByProductBarcodeApiResponse,
      GetBillsGetShipmentBillsByProductBarcodeApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/getShipmentBillsByProductBarcode`,
        params: { barcode: queryArg.barcode },
      }),
    }),
    getBillsShipment: build.query<
      GetBillsShipmentApiResponse,
      GetBillsShipmentApiArg
    >({
      query: () => ({ url: `/bills/shipment` }),
    }),
    getBillsAcceptance: build.query<
      GetBillsAcceptanceApiResponse,
      GetBillsAcceptanceApiArg
    >({
      query: () => ({ url: `/bills/acceptance` }),
    }),
    getBillsPackage: build.query<
      GetBillsPackageApiResponse,
      GetBillsPackageApiArg
    >({
      query: () => ({ url: `/bills/package` }),
    }),
    getBillsPlacement: build.query<
      GetBillsPlacementApiResponse,
      GetBillsPlacementApiArg
    >({
      query: () => ({ url: `/bills/placement` }),
    }),
    getBillsBill: build.query<GetBillsBillApiResponse, GetBillsBillApiArg>({
      query: (queryArg) => ({
        url: `/bills/bill`,
        params: { billId: queryArg.billId },
      }),
    }),
    postBillsBill: build.mutation<
      PostBillsBillApiResponse,
      PostBillsBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/bill`,
        method: "POST",
        body: queryArg.createBillModel,
      }),
    }),
    getBillsPackageBill: build.query<
      GetBillsPackageBillApiResponse,
      GetBillsPackageBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/packageBill`,
        params: { billId: queryArg.billId },
      }),
    }),
    postBillsPackageBill: build.mutation<
      PostBillsPackageBillApiResponse,
      PostBillsPackageBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/packageBill`,
        method: "POST",
        body: queryArg.createPackageBillModel,
      }),
    }),
    getBillsPlacementBill: build.query<
      GetBillsPlacementBillApiResponse,
      GetBillsPlacementBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/placementBill`,
        params: { billId: queryArg.billId },
      }),
    }),
    postBillsPlacementBill: build.mutation<
      PostBillsPlacementBillApiResponse,
      PostBillsPlacementBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/placementBill`,
        method: "POST",
        body: queryArg.createPlacementBillModel,
      }),
    }),
    postBillsRedactPackageBill: build.mutation<
      PostBillsRedactPackageBillApiResponse,
      PostBillsRedactPackageBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/redactPackageBill`,
        method: "POST",
        body: queryArg.redactPackageBillModel,
      }),
    }),
    postBillsRedactBill: build.mutation<
      PostBillsRedactBillApiResponse,
      PostBillsRedactBillApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/redactBill`,
        method: "POST",
        body: queryArg.redactBillModel,
      }),
    }),
    postBillsFileExcel: build.mutation<
      PostBillsFileExcelApiResponse,
      PostBillsFileExcelApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/file/excel`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postBillsEmployeeFileExcel: build.mutation<
      PostBillsEmployeeFileExcelApiResponse,
      PostBillsEmployeeFileExcelApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/employee/file/excel`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    putBillsStatus: build.mutation<
      PutBillsStatusApiResponse,
      PutBillsStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/bills/status`,
        method: "PUT",
        params: { billId: queryArg.billId, status: queryArg.status },
      }),
    }),
    getStoragePlaces: build.query<
      GetStoragePlacesApiResponse,
      GetStoragePlacesApiArg
    >({
      query: (queryArg) => ({
        url: `/storage/places`,
        params: {
          CellsType: queryArg.CellsType,
          Skip: queryArg.skip,
          Take: queryArg.take,
          warehouseId: queryArg.warehouseId,
          cellsType: queryArg.cellsType,
        },
      }),
    }),
    getStorage: build.query<GetStorageApiResponse, GetStorageApiArg>({
      query: (queryArg) => ({
        url: `/storage`,
        params: { productId: queryArg.productId },
      }),
    }),
    getStorageCellsByReceivedProduct: build.query<
      GetStorageCellsByReceivedProductApiResponse,
      GetStorageCellsByReceivedProductApiArg
    >({
      query: (queryArg) => ({
        url: `/storage/cellsByReceivedProduct`,
        params: { productId: queryArg.productId },
      }),
    }),
    getStorageCell: build.query<
      GetStorageCellApiResponse,
      GetStorageCellApiArg
    >({
      query: (queryArg) => ({
        url: `/storage/cell`,
        params: { cellId: queryArg.cellId },
      }),
    }),
    postStoragePlace: build.mutation<
      PostStoragePlaceApiResponse,
      PostStoragePlaceApiArg
    >({
      query: (queryArg) => ({
        url: `/storage/place`,
        method: "POST",
        body: queryArg.createStoragePlaceRequest,
      }),
    }),
    getConsumables: build.query<
      GetConsumablesApiResponse,
      GetConsumablesApiArg
    >({
      query: () => ({ url: `/consumables` }),
    }),
    postConsumables: build.mutation<
      PostConsumablesApiResponse,
      PostConsumablesApiArg
    >({
      query: (queryArg) => ({
        url: `/consumables`,
        method: "POST",
        body: queryArg.createConsumablesModel,
      }),
    }),
    getConsumablesSpending: build.query<
      GetConsumablesSpendingApiResponse,
      GetConsumablesSpendingApiArg
    >({
      query: (queryArg) => ({
        url: `/consumables/spending`,
        body: queryArg.consumablesFilter,
      }),
    }),
    getConsumablesSpendingActual: build.query<
      GetConsumablesSpendingActualApiResponse,
      GetConsumablesSpendingActualApiArg
    >({
      query: (queryArg) => ({
        url: `/consumables/spending/actual`,
        body: queryArg.body,
      }),
    }),
    postDicAddVisit: build.mutation<
      PostDicAddVisitApiResponse,
      PostDicAddVisitApiArg
    >({
      query: (queryArg) => ({
        url: `/dic/addVisit`,
        method: "POST",
        body: queryArg.dicVisitInputModel,
      }),
    }),
    getDicAddRecallRequest: build.query<
      GetDicAddRecallRequestApiResponse,
      GetDicAddRecallRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/dic/addRecallRequest`,
        params: {
          productId: queryArg.productId,
          userId: queryArg.userId,
          sessionId: queryArg.sessionId,
          name: queryArg.name,
          number: queryArg["number"],
          email: queryArg.email,
          telegram: queryArg.telegram,
        },
      }),
    }),
    getDocuments: build.query<GetDocumentsApiResponse, GetDocumentsApiArg>({
      query: () => ({ url: `/documents` }),
    }),
    getDocumentsDocxBills: build.query<
      GetDocumentsDocxBillsApiResponse,
      GetDocumentsDocxBillsApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/docx/bills`,
        params: { billId: queryArg.billId, userId: queryArg.userId },
      }),
    }),
    getDocumentsExcelBills: build.query<
      GetDocumentsExcelBillsApiResponse,
      GetDocumentsExcelBillsApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/excel/bills`,
        params: {
          Skip: queryArg.skip,
          Take: queryArg.take,
          Status: queryArg.status,
          Type: queryArg["type"],
          BillId: queryArg.billId,
          UserId: queryArg.userId,
          FactoryBarcode: queryArg.factoryBarcode,
        },
      }),
    }),
    postDocumentsTemplate: build.mutation<
      PostDocumentsTemplateApiResponse,
      PostDocumentsTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/template`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getAdminEmployees: build.query<
      GetAdminEmployeesApiResponse,
      GetAdminEmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/employees`,
        body: queryArg.collectionFilter,
      }),
    }),
    getAdminEmployeesPayments: build.query<
      GetAdminEmployeesPaymentsApiResponse,
      GetAdminEmployeesPaymentsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/employees/payments`,
        body: queryArg.collectionFilter,
      }),
    }),
    postFilesUpload: build.mutation<
      PostFilesUploadApiResponse,
      PostFilesUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/files/upload`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProductsInventorizeWarehouse: build.query<
      GetProductsInventorizeWarehouseApiResponse,
      GetProductsInventorizeWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/products/inventorize/warehouse`,
        params: { warehouseId: queryArg.warehouseId },
      }),
    }),
    getProductsInventorizeClients: build.query<
      GetProductsInventorizeClientsApiResponse,
      GetProductsInventorizeClientsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/inventorize/clients`,
        params: { Skip: queryArg.skip, Take: queryArg.take },
      }),
    }),
    getProductsInventorizeClient: build.query<
      GetProductsInventorizeClientApiResponse,
      GetProductsInventorizeClientApiArg
    >({
      query: (queryArg) => ({
        url: `/products/inventorize/client`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getProductsInventorizeProducts: build.query<
      GetProductsInventorizeProductsApiResponse,
      GetProductsInventorizeProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/inventorize/products`,
        params: {
          ClientId: queryArg.clientId,
          WarehouseId: queryArg.warehouseId,
          ProductId: queryArg.productId,
        },
      }),
    }),
    postIntegrationMoySkladProduct: build.mutation<
      PostIntegrationMoySkladProductApiResponse,
      PostIntegrationMoySkladProductApiArg
    >({
      query: (queryArg) => ({
        url: `/integration/moy-sklad/product`,
        method: "POST",
        params: { productId: queryArg.productId },
      }),
    }),
    postIntegrationMoySkladData: build.mutation<
      PostIntegrationMoySkladDataApiResponse,
      PostIntegrationMoySkladDataApiArg
    >({
      query: (queryArg) => ({
        url: `/integration/moy-sklad/data`,
        method: "POST",
        body: queryArg.integrationDataModel,
      }),
    }),
    postNotificationsSeen: build.mutation<
      PostNotificationsSeenApiResponse,
      PostNotificationsSeenApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications/seen`,
        method: "POST",
        body: queryArg.notificationSeenModelInput,
      }),
    }),
    getNotifications: build.query<
      GetNotificationsApiResponse,
      GetNotificationsApiArg
    >({
      query: () => ({ url: `/notifications` }),
    }),
    postNotificationsNew: build.mutation<
      PostNotificationsNewApiResponse,
      PostNotificationsNewApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications/new`,
        method: "POST",
        params: { userId: queryArg.userId, text: queryArg.text },
      }),
    }),
    postPackageComplete: build.mutation<
      PostPackageCompleteApiResponse,
      PostPackageCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/package/complete`,
        method: "POST",
        body: queryArg.completePackageInputModel,
      }),
    }),
    postProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcess:
      build.mutation<
        PostProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiResponse,
        PostProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/package/setEmployeeInProcessAndGetEmployeesInProcess`,
          method: "POST",
          body: queryArg.processRealTimeEmployeeInputModel,
        }),
      }),
    postProcessesPackageRedactProcessEmployee: build.mutation<
      PostProcessesPackageRedactProcessEmployeeApiResponse,
      PostProcessesPackageRedactProcessEmployeeApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/package/redactProcessEmployee`,
        method: "POST",
        body: queryArg.redactProcessEmployeeModel,
      }),
    }),
    postProcessesPackageAddProcess: build.mutation<
      PostProcessesPackageAddProcessApiResponse,
      PostProcessesPackageAddProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/package/addProcess`,
        method: "POST",
        params: { billId: queryArg.billId, cellId: queryArg.cellId },
      }),
    }),
    getProcessesPackageGetProcessBillById: build.query<
      GetProcessesPackageGetProcessBillByIdApiResponse,
      GetProcessesPackageGetProcessBillByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/package/getProcessBillById`,
        params: { processBillId: queryArg.processBillId },
      }),
    }),
    getProcessesPackageGetProcessesBill: build.query<
      GetProcessesPackageGetProcessesBillApiResponse,
      GetProcessesPackageGetProcessesBillApiArg
    >({
      query: () => ({ url: `/processes/package/getProcessesBill` }),
    }),
    getProcessesPackageProcessModel: build.query<
      GetProcessesPackageProcessModelApiResponse,
      GetProcessesPackageProcessModelApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/package/ProcessModel`,
        params: { processId: queryArg.processId },
      }),
    }),
    postProcessesPackageComplete: build.mutation<
      PostProcessesPackageCompleteApiResponse,
      PostProcessesPackageCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/package/complete`,
        method: "POST",
        body: queryArg.inspectionCompleteModel,
      }),
    }),
    getPaymentsBill: build.query<
      GetPaymentsBillApiResponse,
      GetPaymentsBillApiArg
    >({
      query: (queryArg) => ({
        url: `/payments/bill`,
        params: { billId: queryArg.billId },
      }),
    }),
    getPaymentsBills: build.query<
      GetPaymentsBillsApiResponse,
      GetPaymentsBillsApiArg
    >({
      query: (queryArg) => ({
        url: `/payments/bills`,
        params: { userId: queryArg.userId },
      }),
    }),
    postNewBill: build.mutation<PostNewBillApiResponse, PostNewBillApiArg>({
      query: (queryArg) => ({
        url: `/newBill`,
        method: "POST",
        body: queryArg.createPaymentBillModel,
      }),
    }),
    postPlacementComplete: build.mutation<
      PostPlacementCompleteApiResponse,
      PostPlacementCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/placement/complete`,
        method: "POST",
        body: queryArg.placementBillInputModel,
      }),
    }),
    postProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcess:
      build.mutation<
        PostProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiResponse,
        PostProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/processes/placement/setEmployeeInProcessAndGetEmployeesInProcess`,
          method: "POST",
          body: queryArg.processRealTimeEmployeeInputModel,
        }),
      }),
    postProcessesPlacementRedactProcessEmployee: build.mutation<
      PostProcessesPlacementRedactProcessEmployeeApiResponse,
      PostProcessesPlacementRedactProcessEmployeeApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/placement/redactProcessEmployee`,
        method: "POST",
        body: queryArg.redactProcessEmployeeModel,
      }),
    }),
    postProcessesPlacementAddProcess: build.mutation<
      PostProcessesPlacementAddProcessApiResponse,
      PostProcessesPlacementAddProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/placement/addProcess`,
        method: "POST",
        params: { billId: queryArg.billId },
      }),
    }),
    getProcessesPlacementGetProcessBillById: build.query<
      GetProcessesPlacementGetProcessBillByIdApiResponse,
      GetProcessesPlacementGetProcessBillByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/placement/getProcessBillById`,
        params: { processBillId: queryArg.processBillId },
      }),
    }),
    getProcessesPlacementGetProcessesBill: build.query<
      GetProcessesPlacementGetProcessesBillApiResponse,
      GetProcessesPlacementGetProcessesBillApiArg
    >({
      query: () => ({ url: `/processes/placement/getProcessesBill` }),
    }),
    getProcessesPlacementProcessModel: build.query<
      GetProcessesPlacementProcessModelApiResponse,
      GetProcessesPlacementProcessModelApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/placement/ProcessModel`,
        params: { processId: queryArg.processId },
      }),
    }),
    postProcessesPlacementComplete: build.mutation<
      PostProcessesPlacementCompleteApiResponse,
      PostProcessesPlacementCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/placement/complete`,
        method: "POST",
        body: queryArg.inspectionCompleteModel,
      }),
    }),
    getProcessesSetEmployeeInProcessAndGetEmployeesInProcess: build.query<
      GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiResponse,
      GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/setEmployeeInProcessAndGetEmployeesInProcess`,
        params: { processId: queryArg.processId },
      }),
    }),
    postProcessesRedactProcessEmployee: build.mutation<
      PostProcessesRedactProcessEmployeeApiResponse,
      PostProcessesRedactProcessEmployeeApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/redactProcessEmployee`,
        method: "POST",
        body: queryArg.redactProcessEmployeeModel,
      }),
    }),
    postProcessesAddProcess: build.mutation<
      PostProcessesAddProcessApiResponse,
      PostProcessesAddProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/addProcess`,
        method: "POST",
        params: { billId: queryArg.billId, cellId: queryArg.cellId },
      }),
    }),
    getProcessesGetProcessBillById: build.query<
      GetProcessesGetProcessBillByIdApiResponse,
      GetProcessesGetProcessBillByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/getProcessBillById`,
        params: { processBillId: queryArg.processBillId },
      }),
    }),
    getProcessesGetProcessesBill: build.query<
      GetProcessesGetProcessesBillApiResponse,
      GetProcessesGetProcessesBillApiArg
    >({
      query: () => ({ url: `/processes/getProcessesBill` }),
    }),
    getProcessesInspectionProcessModel: build.query<
      GetProcessesInspectionProcessModelApiResponse,
      GetProcessesInspectionProcessModelApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/inspectionProcessModel`,
        params: { processId: queryArg.processId },
      }),
    }),
    postProcessesInspectionComplete: build.mutation<
      PostProcessesInspectionCompleteApiResponse,
      PostProcessesInspectionCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/processes/inspection/complete`,
        method: "POST",
        body: queryArg.inspectionCompleteModel,
      }),
    }),
    getProducts: build.query<GetProductsApiResponse, GetProductsApiArg>({
      query: (queryArg) => ({
        url: `/products`,
        params: {
          Skip: queryArg.skip,
          Take: queryArg.take,
          SearchText: queryArg.searchText,
          ClientId: queryArg.clientId,
          WarehouseId: queryArg.warehouseId,
          Barcodes: queryArg.barcodes,
        },
      }),
    }),
    postProducts: build.mutation<PostProductsApiResponse, PostProductsApiArg>({
      query: (queryArg) => ({
        url: `/products`,
        method: "POST",
        body: queryArg.productCreateModel,
      }),
    }),
    deleteProducts: build.mutation<
      DeleteProductsApiResponse,
      DeleteProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/products`,
        method: "DELETE",
        params: { id: queryArg.id },
      }),
    }),
    deleteProductsImage: build.mutation<
      DeleteProductsImageApiResponse,
      DeleteProductsImageApiArg
    >({
      query: (queryArg) => ({
        url: `/products/image`,
        method: "DELETE",
        body: queryArg.deleteProductImageInput,
      }),
    }),
    postProductsImage: build.mutation<
      PostProductsImageApiResponse,
      PostProductsImageApiArg
    >({
      query: (queryArg) => ({
        url: `/products/image`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postProductsKit: build.mutation<
      PostProductsKitApiResponse,
      PostProductsKitApiArg
    >({
      query: (queryArg) => ({
        url: `/products/kit`,
        method: "POST",
        body: queryArg.createKitModel,
      }),
    }),
    postProductsRedact: build.mutation<
      PostProductsRedactApiResponse,
      PostProductsRedactApiArg
    >({
      query: (queryArg) => ({
        url: `/products/redact`,
        method: "POST",
        body: queryArg.productRedactModel,
      }),
    }),
    getProductsReceived: build.query<
      GetProductsReceivedApiResponse,
      GetProductsReceivedApiArg
    >({
      query: () => ({ url: `/products/received` }),
    }),
    postProductsBillImport: build.mutation<
      PostProductsBillImportApiResponse,
      PostProductsBillImportApiArg
    >({
      query: (queryArg) => ({
        url: `/products/bill/import`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProductsReceivedCell: build.query<
      GetProductsReceivedCellApiResponse,
      GetProductsReceivedCellApiArg
    >({
      query: (queryArg) => ({
        url: `/products/received/cell`,
        params: {
          CellId: queryArg.cellId,
          SearchText: queryArg.searchText,
          Skip: queryArg.skip,
          Take: queryArg.take,
        },
      }),
    }),
    getProductsReceivedByWarehouse: build.query<
      GetProductsReceivedByWarehouseApiResponse,
      GetProductsReceivedByWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/products/received/byWarehouse`,
        params: { warehouseId: queryArg.warehouseId },
      }),
    }),
    postProductsReceivedShip: build.mutation<
      PostProductsReceivedShipApiResponse,
      PostProductsReceivedShipApiArg
    >({
      query: (queryArg) => ({
        url: `/products/received/ship`,
        method: "POST",
        body: queryArg.shipmentModel,
      }),
    }),
    getUsersUserOrganizations: build.query<
      GetUsersUserOrganizationsApiResponse,
      GetUsersUserOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/user/organizations`,
        params: { userId: queryArg.userId },
      }),
    }),
    getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
      query: () => ({ url: `/users/me` }),
    }),
    putUsersMe: build.mutation<PutUsersMeApiResponse, PutUsersMeApiArg>({
      query: (queryArg) => ({
        url: `/users/me`,
        method: "PUT",
        body: queryArg.body,
        params: {
          Name: queryArg.name,
          Password: queryArg.password,
          Email: queryArg.email,
        },
      }),
    }),
    getUsersClients: build.query<
      GetUsersClientsApiResponse,
      GetUsersClientsApiArg
    >({
      query: () => ({ url: `/users/clients` }),
    }),
    getUsersEmployees: build.query<
      GetUsersEmployeesApiResponse,
      GetUsersEmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/employees`,
        params: { warehouseId: queryArg.warehouseId },
      }),
    }),
    getWarehouse: build.query<GetWarehouseApiResponse, GetWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/warehouse`,
        params: { Skip: queryArg.skip, Take: queryArg.take },
      }),
    }),
    getWarehouseById: build.query<
      GetWarehouseByIdApiResponse,
      GetWarehouseByIdApiArg
    >({
      query: (queryArg) => ({ url: `/warehouse/${queryArg.id}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetProductsReceivedActionsApiResponse =
  /** status 200 Success */ DifProductCellAction[];
export type GetProductsReceivedActionsApiArg = {
  skip?: number;
  take?: number;
};
export type PostProductsReceivedActionsActionApiResponse = unknown;
export type PostProductsReceivedActionsActionApiArg = {
  /** Идентификатор ячейки с товаром */
  receivedProductCellId?: number;
  /** Идентификатор типа действия */
  actionTypeId?: number;
};
export type GetAdminClientsWarehousesApiResponse =
  /** status 200 Success */ ClientWarehouse[];
export type GetAdminClientsWarehousesApiArg = {
  clientWarehouseFilter: ClientWarehouseFilter;
};
export type PostAdminClientsWarehousesApiResponse =
  /** status 200 Success */ ClientWarehouse[];
export type PostAdminClientsWarehousesApiArg = {
  body: LinkUserWarehosue[];
};
export type PostAdminEmpoloyeeApiResponse =
  /** status 200 Success */ DifEmployee;
export type PostAdminEmpoloyeeApiArg = {
  createEmployeeModel: CreateEmployeeModel;
};
export type PostAnalyticsAddVisitApiResponse = unknown;
export type PostAnalyticsAddVisitApiArg = {
  visitInputModel: VisitInputModel;
};
export type GetAnalyticsAddRecallRequestApiResponse = unknown;
export type GetAnalyticsAddRecallRequestApiArg = {
  userId?: string;
  sessionId?: string;
  name?: string;
  number?: string;
  email?: string;
  telegram?: string;
};
export type PostAuthLoginApiResponse =
  /** status 200 Success */ AuthOutputModel;
export type PostAuthLoginApiArg = {
  /** Данные для входа */
  authCredentials: AuthCredentials;
};
export type PostAuthRegistrationApiResponse =
  /** status 200 Success */ AuthOutputModel;
export type PostAuthRegistrationApiArg = {
  /** Данные для регистрации */
  authCredentials: AuthCredentials;
};
export type GetBillsApiResponse = /** status 200 Success */ BillOutputModel[];
export type GetBillsApiArg = {
  /** Сколько нужно пропустить */
  skip?: number;
  /** Сколько нужно вернуть */
  take?: number;
  /** Статус накладной */
  status?: BillStatus;
  /** Тип накладной */
  type?: BillType;
  /** Идентификатор накладной */
  billId?: number;
  /** Идентификатор владельца накладной */
  userId?: number;
  factoryBarcode?: string;
};
export type DeleteBillsApiResponse = unknown;
export type DeleteBillsApiArg = {
  /** Идентификатор накладной */
  id?: number;
};
export type GetBillsGetAcceptanceBillsByProductBarcodeApiResponse =
  /** status 200 Success */ DifBill[];
export type GetBillsGetAcceptanceBillsByProductBarcodeApiArg = {
  barcode?: string;
};
export type GetBillsGetShipmentBillsByProductBarcodeApiResponse =
  /** status 200 Success */ DifBill[];
export type GetBillsGetShipmentBillsByProductBarcodeApiArg = {
  barcode?: string;
};
export type GetBillsShipmentApiResponse =
  /** status 200 Success */ BillOutputModel[];
export type GetBillsShipmentApiArg = void;
export type GetBillsAcceptanceApiResponse =
  /** status 200 Success */ BillOutputModel[];
export type GetBillsAcceptanceApiArg = void;
export type GetBillsPackageApiResponse =
  /** status 200 Success */ DifPackageBill[];
export type GetBillsPackageApiArg = void;
export type GetBillsPlacementApiResponse =
  /** status 200 Success */ DifPlacementBill[];
export type GetBillsPlacementApiArg = void;
export type GetBillsBillApiResponse = /** status 200 Success */ BillOutputModel;
export type GetBillsBillApiArg = {
  /** Идентификатор накладной */
  billId?: number;
};
export type PostBillsBillApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsBillApiArg = {
  /** Модель создания накладной */
  createBillModel: CreateBillModel;
};
export type GetBillsPackageBillApiResponse =
  /** status 200 Success */ PackageBillOutputModel;
export type GetBillsPackageBillApiArg = {
  billId?: number;
};
export type PostBillsPackageBillApiResponse = unknown;
export type PostBillsPackageBillApiArg = {
  /** Модель создания накладной */
  createPackageBillModel: CreatePackageBillModel;
};
export type GetBillsPlacementBillApiResponse =
  /** status 200 Success */ DifPlacementBill;
export type GetBillsPlacementBillApiArg = {
  billId?: number;
};
export type PostBillsPlacementBillApiResponse = unknown;
export type PostBillsPlacementBillApiArg = {
  /** Модель создания накладной */
  createPlacementBillModel: CreatePlacementBillModel;
};
export type PostBillsRedactPackageBillApiResponse = unknown;
export type PostBillsRedactPackageBillApiArg = {
  /** Модель создания накладной */
  redactPackageBillModel: RedactPackageBillModel;
};
export type PostBillsRedactBillApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsRedactBillApiArg = {
  redactBillModel: RedactBillModel;
};
export type PostBillsFileExcelApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsFileExcelApiArg = {
  body: {
    /** Идентификатор склада */
    WarehouseId?: number;
    ClientId?: number;
    /** Файл */
    File?: Blob;
  };
};
export type PostBillsEmployeeFileExcelApiResponse =
  /** status 200 Success */ BillOutputModel;
export type PostBillsEmployeeFileExcelApiArg = {
  body: {
    /** Идентификатор склада */
    WarehouseId?: number;
    ClientId?: number;
    /** Файл */
    File?: Blob;
  };
};
export type PutBillsStatusApiResponse = unknown;
export type PutBillsStatusApiArg = {
  /** Идентификатор налкдной */
  billId?: number;
  /** Новый статус */
  status?: BillStatus;
};
export type GetStoragePlacesApiResponse =
  /** status 200 Success */ StoragePlaceModel[];
export type GetStoragePlacesApiArg = {
  CellsType?: CellTypeEnum;
  skip?: number;
  take?: number;
  /** Идентификатор склада */
  warehouseId?: number;
  cellsType?: CellTypeEnum;
};
export type GetStorageApiResponse = /** status 200 Success */ DifCell[];
export type GetStorageApiArg = {
  productId?: number;
};
export type GetStorageCellsByReceivedProductApiResponse =
  /** status 200 Success */ DifCell[];
export type GetStorageCellsByReceivedProductApiArg = {
  productId?: number;
};
export type GetStorageCellApiResponse = /** status 200 Success */ DifCell;
export type GetStorageCellApiArg = {
  /** Идентификатор ячейки */
  cellId?: number;
};
export type PostStoragePlaceApiResponse = unknown;
export type PostStoragePlaceApiArg = {
  /** Модель создания ячейки */
  createStoragePlaceRequest: CreateStoragePlaceRequest;
};
export type GetConsumablesApiResponse =
  /** status 200 Success */ DifConsumables[];
export type GetConsumablesApiArg = void;
export type PostConsumablesApiResponse = unknown;
export type PostConsumablesApiArg = {
  /** Модель создания расходника */
  createConsumablesModel: CreateConsumablesModel;
};
export type GetConsumablesSpendingApiResponse =
  /** status 200 Success */ DifConsumablesProduct[];
export type GetConsumablesSpendingApiArg = {
  /** Фильтр */
  consumablesFilter: ConsumablesFilter;
};
export type GetConsumablesSpendingActualApiResponse =
  /** status 200 Success */ DifConsumablesProduct[];
export type GetConsumablesSpendingActualApiArg = {
  /** Фильтр по товарам и расходникам */
  body: ConsumablesProductsFilter[];
};
export type PostDicAddVisitApiResponse = unknown;
export type PostDicAddVisitApiArg = {
  dicVisitInputModel: DicVisitInputModel;
};
export type GetDicAddRecallRequestApiResponse = unknown;
export type GetDicAddRecallRequestApiArg = {
  productId?: number;
  userId?: string;
  sessionId?: string;
  name?: string;
  number?: string;
  email?: string;
  telegram?: string;
};
export type GetDocumentsApiResponse = /** status 200 Success */ DifDocument[];
export type GetDocumentsApiArg = void;
export type GetDocumentsDocxBillsApiResponse = unknown;
export type GetDocumentsDocxBillsApiArg = {
  /** Идентификатор накладной */
  billId?: number;
  /** Идентификатор пользователя */
  userId?: number;
};
export type GetDocumentsExcelBillsApiResponse =
  /** status 200 Success */ DocumentOutput;
export type GetDocumentsExcelBillsApiArg = {
  /** Сколько нужно пропустить */
  skip?: number;
  /** Сколько нужно вернуть */
  take?: number;
  /** Статус накладной */
  status?: BillStatus;
  /** Тип накладной */
  type?: BillType;
  /** Идентификатор накладной */
  billId?: number;
  /** Идентификатор владельца накладной */
  userId?: number;
  factoryBarcode?: string;
};
export type PostDocumentsTemplateApiResponse = /** status 200 Success */ string;
export type PostDocumentsTemplateApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetAdminEmployeesApiResponse =
  /** status 200 Success */ EmployeeOuttputModel[];
export type GetAdminEmployeesApiArg = {
  collectionFilter: CollectionFilter;
};
export type GetAdminEmployeesPaymentsApiResponse =
  /** status 200 Success */ DifEmployeePaymentsHistory[];
export type GetAdminEmployeesPaymentsApiArg = {
  collectionFilter: CollectionFilter;
};
export type PostFilesUploadApiResponse =
  /** status 200 Success */ CreateFileResponse;
export type PostFilesUploadApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetProductsInventorizeWarehouseApiResponse =
  /** status 200 Success */ WarehouseInventorizationModel;
export type GetProductsInventorizeWarehouseApiArg = {
  /** Идентификатор склада */
  warehouseId?: number;
};
export type GetProductsInventorizeClientsApiResponse =
  /** status 200 Success */ ClientInventorizationModel[];
export type GetProductsInventorizeClientsApiArg = {
  skip?: number;
  take?: number;
};
export type GetProductsInventorizeClientApiResponse =
  /** status 200 Success */ ClientInventorizationModel;
export type GetProductsInventorizeClientApiArg = {
  /** Идентификатор клиента */
  clientId?: number;
};
export type GetProductsInventorizeProductsApiResponse =
  /** status 200 Success */ ProductInventorizationModel[];
export type GetProductsInventorizeProductsApiArg = {
  clientId?: number;
  warehouseId?: number;
  productId?: number;
};
export type PostIntegrationMoySkladProductApiResponse = unknown;
export type PostIntegrationMoySkladProductApiArg = {
  productId?: number;
};
export type PostIntegrationMoySkladDataApiResponse = unknown;
export type PostIntegrationMoySkladDataApiArg = {
  integrationDataModel: IntegrationDataModel;
};
export type PostNotificationsSeenApiResponse =
  /** status 200 Success */ boolean;
export type PostNotificationsSeenApiArg = {
  notificationSeenModelInput: NotificationSeenModelInput;
};
export type GetNotificationsApiResponse =
  /** status 200 Success */ DifNotification[];
export type GetNotificationsApiArg = void;
export type PostNotificationsNewApiResponse = /** status 200 Success */ boolean;
export type PostNotificationsNewApiArg = {
  userId?: number;
  text?: string;
};
export type PostPackageCompleteApiResponse = unknown;
export type PostPackageCompleteApiArg = {
  completePackageInputModel: CompletePackageInputModel;
};
export type PostProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiResponse =
  /** status 200 Success */ ProcessRealTimeEmployeeOutputModel[];
export type PostProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessApiArg =
  {
    processRealTimeEmployeeInputModel: ProcessRealTimeEmployeeInputModel;
  };
export type PostProcessesPackageRedactProcessEmployeeApiResponse = unknown;
export type PostProcessesPackageRedactProcessEmployeeApiArg = {
  redactProcessEmployeeModel: RedactProcessEmployeeModel;
};
export type PostProcessesPackageAddProcessApiResponse =
  /** status 200 Success */ number;
export type PostProcessesPackageAddProcessApiArg = {
  billId?: number;
  cellId?: number;
};
export type GetProcessesPackageGetProcessBillByIdApiResponse =
  /** status 200 Success */ DifProcessPackageBill;
export type GetProcessesPackageGetProcessBillByIdApiArg = {
  processBillId?: number;
};
export type GetProcessesPackageGetProcessesBillApiResponse =
  /** status 200 Success */ ProcessPcakageBillModel[];
export type GetProcessesPackageGetProcessesBillApiArg = void;
export type GetProcessesPackageProcessModelApiResponse =
  /** status 200 Success */ ProcessModel;
export type GetProcessesPackageProcessModelApiArg = {
  processId?: number;
};
export type PostProcessesPackageCompleteApiResponse =
  /** status 200 Success */ boolean;
export type PostProcessesPackageCompleteApiArg = {
  inspectionCompleteModel: InspectionCompleteModel;
};
export type GetPaymentsBillApiResponse =
  /** status 200 Success */ DifPaymentBill;
export type GetPaymentsBillApiArg = {
  billId?: number;
};
export type GetPaymentsBillsApiResponse =
  /** status 200 Success */ DifPaymentBill[];
export type GetPaymentsBillsApiArg = {
  userId?: number;
};
export type PostNewBillApiResponse = unknown;
export type PostNewBillApiArg = {
  createPaymentBillModel: CreatePaymentBillModel;
};
export type PostPlacementCompleteApiResponse = unknown;
export type PostPlacementCompleteApiArg = {
  placementBillInputModel: PlacementBillInputModel;
};
export type PostProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiResponse =
  /** status 200 Success */ ProcessRealTimeEmployeeOutputModel[];
export type PostProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessApiArg =
  {
    processRealTimeEmployeeInputModel: ProcessRealTimeEmployeeInputModel;
  };
export type PostProcessesPlacementRedactProcessEmployeeApiResponse = unknown;
export type PostProcessesPlacementRedactProcessEmployeeApiArg = {
  redactProcessEmployeeModel: RedactProcessEmployeeModel;
};
export type PostProcessesPlacementAddProcessApiResponse =
  /** status 200 Success */ number;
export type PostProcessesPlacementAddProcessApiArg = {
  billId?: number;
};
export type GetProcessesPlacementGetProcessBillByIdApiResponse =
  /** status 200 Success */ DifProcessPlacementBill;
export type GetProcessesPlacementGetProcessBillByIdApiArg = {
  processBillId?: number;
};
export type GetProcessesPlacementGetProcessesBillApiResponse =
  /** status 200 Success */ ProcessPlacementBillModel[];
export type GetProcessesPlacementGetProcessesBillApiArg = void;
export type GetProcessesPlacementProcessModelApiResponse =
  /** status 200 Success */ PlacementProcessModel;
export type GetProcessesPlacementProcessModelApiArg = {
  processId?: number;
};
export type PostProcessesPlacementCompleteApiResponse =
  /** status 200 Success */ boolean;
export type PostProcessesPlacementCompleteApiArg = {
  inspectionCompleteModel: InspectionCompleteModel;
};
export type GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiResponse =
  /** status 200 Success */ ProcessRealTimeEmployeeOutputModel[];
export type GetProcessesSetEmployeeInProcessAndGetEmployeesInProcessApiArg = {
  processId?: number;
};
export type PostProcessesRedactProcessEmployeeApiResponse = unknown;
export type PostProcessesRedactProcessEmployeeApiArg = {
  redactProcessEmployeeModel: RedactProcessEmployeeModel;
};
export type PostProcessesAddProcessApiResponse =
  /** status 200 Success */ number;
export type PostProcessesAddProcessApiArg = {
  billId?: number;
  cellId?: number;
};
export type GetProcessesGetProcessBillByIdApiResponse =
  /** status 200 Success */ DifProcessBill;
export type GetProcessesGetProcessBillByIdApiArg = {
  processBillId?: number;
};
export type GetProcessesGetProcessesBillApiResponse =
  /** status 200 Success */ ArModel[];
export type GetProcessesGetProcessesBillApiArg = void;
export type GetProcessesInspectionProcessModelApiResponse =
  /** status 200 Success */ ProcessModel;
export type GetProcessesInspectionProcessModelApiArg = {
  processId?: number;
};
export type PostProcessesInspectionCompleteApiResponse =
  /** status 200 Success */ boolean;
export type PostProcessesInspectionCompleteApiArg = {
  inspectionCompleteModel: InspectionCompleteModel;
};
export type GetProductsApiResponse =
  /** status 200 Success */ ProductOutputModel[];
export type GetProductsApiArg = {
  skip?: number;
  take?: number;
  searchText?: string;
  clientId?: number;
  warehouseId?: number;
  barcodes?: string[];
};
export type PostProductsApiResponse = /** status 200 Success */ number;
export type PostProductsApiArg = {
  /** Модель создания товара */
  productCreateModel: ProductCreateModel;
};
export type DeleteProductsApiResponse = unknown;
export type DeleteProductsApiArg = {
  /** Идентификтаор товара */
  id?: number;
};
export type DeleteProductsImageApiResponse = unknown;
export type DeleteProductsImageApiArg = {
  deleteProductImageInput: DeleteProductImageInput;
};
export type PostProductsImageApiResponse = unknown;
export type PostProductsImageApiArg = {
  body: {
    File?: Blob;
    ProductId?: number;
  };
};
export type PostProductsKitApiResponse = /** status 200 Success */ KitOutput;
export type PostProductsKitApiArg = {
  /** Модель создания товара */
  createKitModel: CreateKitModel;
};
export type PostProductsRedactApiResponse = unknown;
export type PostProductsRedactApiArg = {
  /** Модель создания товара */
  productRedactModel: ProductRedactModel;
};
export type GetProductsReceivedApiResponse =
  /** status 200 Success */ ReceivedProductOutputModel23[];
export type GetProductsReceivedApiArg = void;
export type PostProductsBillImportApiResponse =
  /** status 200 Success */ ImportBillProductResponse;
export type PostProductsBillImportApiArg = {
  body: {
    ClientId?: number;
    File?: Blob;
  };
};
export type GetProductsReceivedCellApiResponse =
  /** status 200 Success */ ReceivedProductCellOutputModel[];
export type GetProductsReceivedCellApiArg = {
  cellId?: number;
  searchText?: string;
  /** Пропустить Skip объектов */
  skip?: number;
  /** Взять Take объектов */
  take?: number;
};
export type GetProductsReceivedByWarehouseApiResponse =
  /** status 200 Success */ ReceivedProductOutputModel2[];
export type GetProductsReceivedByWarehouseApiArg = {
  warehouseId?: number;
};
export type PostProductsReceivedShipApiResponse = unknown;
export type PostProductsReceivedShipApiArg = {
  /** Модель отгрузки */
  shipmentModel: ShipmentModel;
};
export type GetUsersUserOrganizationsApiResponse =
  /** status 200 Success */ OrganizationOutputModel[];
export type GetUsersUserOrganizationsApiArg = {
  userId?: number;
};
export type GetUsersMeApiResponse = /** status 200 Success */ UserModel;
export type GetUsersMeApiArg = void;
export type PutUsersMeApiResponse = /** status 200 Success */ UserModel;
export type PutUsersMeApiArg = {
  /** Имя */
  name?: string;
  /** Пароль */
  password?: string;
  /** Электронная почта */
  email?: string;
  body: {
    /** Аватар */
    Image?: Blob;
  };
};
export type GetUsersClientsApiResponse = /** status 200 Success */ UserModel[];
export type GetUsersClientsApiArg = void;
export type GetUsersEmployeesApiResponse =
  /** status 200 Success */ DifEmployee[];
export type GetUsersEmployeesApiArg = {
  warehouseId?: number;
};
export type GetWarehouseApiResponse = /** status 200 Success */ DifWarehouse[];
export type GetWarehouseApiArg = {
  skip?: number;
  take?: number;
};
export type GetWarehouseByIdApiResponse =
  /** status 200 Success */ DifWarehouse;
export type GetWarehouseByIdApiArg = {
  /** Мдентификатор склада */
  id: number;
};
export type DifActionType = {
  id?: number;
  objectCreateDate?: string;
  type?: string | null;
  productActions?: DifProductCellAction[] | null;
};
export type DifEmployeeType = {
  id?: number;
  objectCreateDate?: string;
  type?: string | null;
  employees?: DifEmployee[] | null;
};
export type ProductTypeEnum = 0 | 1;
export type PaymentStatusEnum = 0 | 1 | 2;
export type DifPaymentBillDetail = {
  id?: number;
  objectCreateDate?: string;
  paymentBillId?: number;
  paymentBill?: DifPaymentBill;
  name?: string | null;
  quantity?: string | null;
  paymentAmount?: number;
};
export type DifPaymentBill = {
  id?: number;
  objectCreateDate?: string;
  organizationId?: number;
  organization?: DifOrganization;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  paymentAmount?: number;
  paymentStatus?: PaymentStatusEnum;
  note?: string | null;
  paymentBillDetails?: DifPaymentBillDetail[] | null;
};
export type DifProcessPackageRealTimeEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPackageBill;
  employeeId?: number;
  employee?: DifEmployee;
};
export type DifProcessPackageBill = {
  id?: number;
  objectCreateDate?: string;
  billId?: number;
  bill?: DifPackageBill;
  dateEnd?: string | null;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  cellId?: number;
  processBillProducts?: DifProcessPackageBillProduct[] | null;
  processRealTimeEmployees?: DifProcessPackageRealTimeEmployee[] | null;
};
export type DifProcessPackageEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillProductId?: number;
  processBillProduct?: DifProcessPackageBillProduct;
  employeeId?: number;
  employee?: DifEmployee;
  quantity?: number;
};
export type DifProcessPackageBillProduct = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPackageBill;
  productBillId?: number;
  productBill?: DifPackageBillProduct;
  processEmployees?: DifProcessPackageEmployee[] | null;
};
export type CellTypeEnum = 0 | 1 | 2 | 3;
export type StoragePlaceStatus = 0 | 1;
export type DifHonestSign = {
  id?: number;
  objectCreateDate?: string;
  qrCode?: string | null;
  receivedProductCellId?: number;
  receivedProductCell?: DifReceivedProductCell;
};
export type DifBasket = {
  id?: number;
  objectCreateDate?: string;
  barcode?: string | null;
  cell?: string | null;
  productLinks?: DifBasketReceivedProductLink[] | null;
};
export type DifBasketReceivedProductLink = {
  id?: number;
  objectCreateDate?: string;
  receivedProductCellId?: number;
  receivedProductCell?: DifReceivedProductCell;
  basketId?: number;
  basket?: DifBasket;
};
export type DifPackageReceivedProductCellConsumable = {
  id?: number;
  objectCreateDate?: string;
  packageReceivedReceivedProductCellId?: number;
  packageReceivedReceivedProductCell?: DifPackageReceivedProductCell;
  consumableId?: number;
  consumable?: DifConsumables;
  quantity?: number;
};
export type DifPackageReceivedProductCell = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  receivedProductCellId?: number;
  receivedProductCell?: DifReceivedProductCell;
  packageReceivedProductCellConsumables?:
    | DifPackageReceivedProductCellConsumable[]
    | null;
};
export type BillStatus = 0 | 1 | 2 | 3 | 4;
export type DifPlacementBill = {
  id?: number;
  objectCreateDate?: string;
  note?: string | null;
  realizationDate?: string | null;
  actualRealizationDate?: string | null;
  status?: BillStatus;
  billProducts?: DifPlacementBillProduct[] | null;
};
export type DifProcessPlacementRealTimeEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPlacementBill;
  employeeId?: number;
  employee?: DifEmployee;
};
export type DifProcessPlacementBill = {
  id?: number;
  objectCreateDate?: string;
  billId?: number;
  bill?: DifPlacementBill;
  dateEnd?: string | null;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  processBillProducts?: DifProcessPlacementBillProduct[] | null;
  processRealTimeEmployees?: DifProcessPlacementRealTimeEmployee[] | null;
};
export type DifProcessPlacementEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillProductId?: number;
  processBillProduct?: DifProcessPlacementBillProduct;
  employeeId?: number;
  employee?: DifEmployee;
  quantity?: number;
};
export type DifProcessPlacementBillProduct = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessPlacementBill;
  productBillId?: number;
  productBill?: DifPlacementBillProduct;
  processEmployees?: DifProcessPlacementEmployee[] | null;
};
export type DifPlacementBillProduct = {
  id?: number;
  objectCreateDate?: string;
  placementBillId?: number;
  placementBill?: DifPlacementBill;
  receivedProductCellId?: number;
  receivedProductCell?: DifReceivedProductCell;
  toCellId?: number;
  toCell?: DifCell;
  quantity?: number;
  processBillProducts?: DifProcessPlacementBillProduct[] | null;
};
export type DifReceivedProductCell = {
  id?: number;
  objectCreateDate?: string;
  cellId?: number;
  cell?: DifCell;
  productId?: number;
  product?: DifProduct;
  honestSigns?: DifHonestSign[] | null;
  quantity?: number;
  basketReceivedProductLinks?: DifBasketReceivedProductLink[] | null;
  packageReceivedProductCells?: DifPackageReceivedProductCell[] | null;
  placementBillProducts?: DifPlacementBillProduct[] | null;
};
export type DifProductCellStage = {
  id?: number;
  objectCreateDate?: string;
  stage?: string | null;
  cells?: DifCell[] | null;
};
export type DifCell = {
  id?: number;
  objectCreateDate?: string;
  cellType?: CellTypeEnum;
  width?: number;
  height?: number;
  length?: number;
  place?: string | null;
  name?: string | null;
  description?: string | null;
  createdByEmployeeId?: number;
  status?: StoragePlaceStatus;
  createdByEmployee?: DifEmployee;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  productCells?: DifReceivedProductCell[] | null;
  stageId?: number;
  cellStage?: DifProductCellStage;
  placementBillProducts?: DifPlacementBillProduct[] | null;
};
export type DifProcessRealTimeEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessBill;
  employeeId?: number;
  employee?: DifEmployee;
};
export type DifProcessBill = {
  id?: number;
  objectCreateDate?: string;
  billId?: number;
  bill?: DifBill;
  dateEnd?: string | null;
  createdByEmployeeId?: number;
  createdByEmployee?: DifEmployee;
  cellId?: number;
  processBillProducts?: DifProcessBillProduct[] | null;
  processRealTimeEmployees?: DifProcessRealTimeEmployee[] | null;
};
export type DifProcessEmployee = {
  id?: number;
  objectCreateDate?: string;
  processBillProductId?: number;
  processBillProduct?: DifProcessBillProduct;
  employeeId?: number;
  employee?: DifEmployee;
  quantity?: number;
};
export type DifProcessBillProduct = {
  id?: number;
  objectCreateDate?: string;
  processBillId?: number;
  processBill?: DifProcessBill;
  productBillId?: number;
  productBill?: DifProductBill;
  processEmployees?: DifProcessEmployee[] | null;
};
export type DifProductBill = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  actualQuantity?: number;
  productId?: number;
  product?: DifProduct;
  billId?: number;
  bill?: DifBill;
  productOrganization?: string | null;
  serviceProductPrice?: string | null;
  processBillProducts?: DifProcessBillProduct[] | null;
};
export type DifCar = {
  id?: number;
  objectCreateDate?: string;
  note?: string | null;
  bills?: DifBill[] | null;
};
export type BillType = 0 | 1;
export type DifBill = {
  id?: number;
  objectCreateDate?: string;
  transporter?: string | null;
  deliveryNote?: string | null;
  externalBillId?: string | null;
  sender?: string | null;
  boxexCount?: number | null;
  palletsCount?: number | null;
  organizationName?: string | null;
  warehouseName?: string | null;
  quantity?: number;
  productsBills?: DifProductBill[] | null;
  ownerId?: number;
  owner?: DifUser;
  carId?: number | null;
  car?: DifCar;
  warehouseId?: number;
  note?: string | null;
  warehouse?: DifWarehouse;
  address?: string | null;
  realizationDate?: string | null;
  actualRealizationDate?: string | null;
  status?: BillStatus;
  type?: BillType;
  number?: number;
};
export type DifUserWarehouseLink = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  warehouseId?: number;
  warehouse?: DifWarehouse;
};
export type DifWarehouse = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  address?: string | null;
  country?: string | null;
  subject?: string | null;
  city?: string | null;
  street?: string | null;
  house?: string | null;
  coordinates?: string | null;
  consumables?: DifConsumables[] | null;
  employees?: DifEmployee[] | null;
  cells?: DifCell[] | null;
  bills?: DifBill[] | null;
  usersLinks?: DifUserWarehouseLink[] | null;
};
export type DifKitProduct = {
  id?: number;
  objectCreateDate?: string;
  parendId?: number;
  parent?: DifProduct;
  childId?: number;
  child?: DifProduct;
  quantity?: number;
  kitConsumables?: DifKitConsumable[] | null;
};
export type DifKitConsumable = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  consumableId?: number;
  consumable?: DifConsumables;
  kitProductId?: number;
  kitProduct?: DifKitProduct;
};
export type DifProductConsumable = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  consumableId?: number;
  consumable?: DifConsumables;
  productId?: number;
  product?: DifProduct;
};
export type DifConsumables = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  quantity?: number;
  spentQuantity?: number;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  kitConsumables?: DifKitConsumable[] | null;
  productConsumables?: DifProductConsumable[] | null;
  packageReceivedProductCellConsumables?:
    | DifPackageReceivedProductCellConsumable[]
    | null;
  packageBillProductConsumables?: DifPackageBillProductConsumable[] | null;
};
export type DifPackageBillProductConsumable = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  packageBillProductId?: number;
  packageBillProduct?: DifPackageBillProduct;
  consumableId?: number;
  consumable?: DifConsumables;
};
export type DifPackageBillProduct = {
  id?: number;
  objectCreateDate?: string;
  quantity?: number;
  actualQuantity?: number;
  productId?: number;
  product?: DifProduct;
  billId?: number;
  bill?: DifPackageBill;
  processBillProducts?: DifProcessPackageBillProduct[] | null;
  consumables?: DifPackageBillProductConsumable[] | null;
};
export type DifPackageBill = {
  id?: number;
  objectCreateDate?: string;
  externalBillId?: string | null;
  organizationId?: number;
  organization?: DifOrganization;
  productsBills?: DifPackageBillProduct[] | null;
  note?: string | null;
  realizationDate?: string | null;
  actualRealizationDate?: string | null;
  status?: BillStatus;
};
export type DifOrganization = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  name?: string | null;
  products?: DifProduct[] | null;
  paymentBills?: DifPaymentBill[] | null;
  packageBills?: DifPackageBill[] | null;
};
export type DifProduct = {
  id?: number;
  objectCreateDate?: string;
  type?: ProductTypeEnum;
  organizationId?: number;
  organization?: DifOrganization;
  name?: string | null;
  factoryBarcode?: string | null;
  barcode?: string | null;
  article?: string | null;
  color?: string | null;
  weight?: number | null;
  capacity?: number | null;
  width?: number;
  height?: number;
  length?: number;
  externalId?: string | null;
  brand?: string | null;
  serviceProductPrice?: number | null;
  receivedProductCells?: DifReceivedProductCell[] | null;
  imageLinks?: DifImageProductLink[] | null;
  billProducts?: DifProductBill[] | null;
  packageBillProducts?: DifPackageBillProduct[] | null;
  childs?: DifKitProduct[] | null;
  parents?: DifKitProduct[] | null;
  productConsumables?: DifProductConsumable[] | null;
};
export type DifImageProductLink = {
  id?: number;
  objectCreateDate?: string;
  imageId?: number;
  image?: DifImage;
  productId?: number;
  product?: DifProduct;
};
export type DifImage = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  imageProductLinks?: DifImageProductLink[] | null;
};
export type DifNotification = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  text?: string | null;
  isSeen?: boolean;
};
export type DocumentTypeEnum = 0 | 1 | 2 | 3;
export type DocumentExtensionEnum = 0 | 1 | 2;
export type DifDocument = {
  id?: number;
  objectCreateDate?: string;
  fileId?: number;
  file?: DifFile;
  documentType?: DocumentTypeEnum;
  documentExtension?: DocumentExtensionEnum;
};
export type DifFile = {
  id?: number;
  objectCreateDate?: string;
  userId?: number;
  user?: DifUser;
  fileName?: string | null;
  documents?: DifDocument[] | null;
};
export type DifUser = {
  id?: number;
  objectCreateDate?: string;
  name?: string | null;
  email?: string | null;
  imageId?: number | null;
  image?: DifImage;
  password?: string | null;
  telephone?: string | null;
  login?: string | null;
  otherContacts?: string | null;
  employee?: DifEmployee;
  notifications?: DifNotification[] | null;
  bills?: DifBill[] | null;
  organizations?: DifOrganization[] | null;
  files?: DifFile[] | null;
};
export type DifEmployee = {
  id?: number;
  objectCreateDate?: string;
  typeId?: number;
  type?: DifEmployeeType;
  userId?: number;
  user?: DifUser;
  warehouseId?: number;
  warehouse?: DifWarehouse;
  wortkSchedule?: string | null;
  productActions?: DifProductCellAction[] | null;
  processBills?: DifProcessBill[] | null;
  processPackageBills?: DifProcessPackageBill[] | null;
  processPlacementBills?: DifProcessPlacementBill[] | null;
  processEmployees?: DifProcessEmployee[] | null;
  processPackageEmployees?: DifProcessPackageEmployee[] | null;
  processPlacementEmployees?: DifProcessPlacementEmployee[] | null;
  processRealTimeEmployees?: DifProcessRealTimeEmployee[] | null;
  processPackageRealTimeEmployees?: DifProcessPackageRealTimeEmployee[] | null;
  processPlacementRealTimeEmployees?:
    | DifProcessPlacementRealTimeEmployee[]
    | null;
  paymentBills?: DifPaymentBill[] | null;
};
export type DifProductCellAction = {
  id?: number;
  objectCreateDate?: string;
  actionTime?: string;
  typeId?: number;
  type?: DifActionType;
  employeeId?: number;
  employee?: DifEmployee;
  receivedProductCellId?: number;
  receivedProductCell?: DifReceivedProductCell;
};
export type ClientWarehouse = {
  warehouseId?: number;
  clientId?: number;
  warehouseName?: string | null;
  warehouseAdress?: string | null;
  clientName?: string | null;
  clientEmail?: string | null;
};
export type ClientWarehouseFilter = {
  /** Пропустить Skip объектов */
  skip?: number | null;
  /** Взять Take объектов */
  take?: number | null;
  clientId?: number | null;
  warehouseId?: number | null;
};
export type LinkUserWarehosue = {
  userId?: number;
  warehouseId?: number;
};
export type CreateEmployeeModel = {
  userId?: number;
  typeId?: number;
  warehouseId?: number;
};
export type Position = {
  x?: number;
  y?: number;
};
export type VisitInputModel = {
  userId?: string;
  sessionId?: string;
  positions?: Position[] | null;
};
export type AuthOutputModel = {
  authToken?: string | null;
};
export type AuthCredentials = {
  /** Почта */
  email?: string | null;
  /** Пароль */
  password?: string | null;
};
export type ProductOutputModel = {
  /** дата создания товара */
  objectCreateDate?: string;
  /** Стоимость услуги товара */
  serviceProductPrice?: number | null;
  /** Наименование организации */
  organizationName?: string | null;
  /** Идентификатор товара */
  id?: number;
  /** Изображения товара */
  images?: string[] | null;
  /** Количесвто товара у текущего пользователя */
  quantity?: number;
  name?: string | null;
  /** Артиул товара */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  childs?: DifKitProduct[] | null;
  consumables?: DifProductConsumable[] | null;
};
export type ProductBillOutputModel = {
  id?: number;
  productId?: number;
  product?: ProductOutputModel;
  quantity?: number;
};
export type WarehouseOutputModel = {
  id?: number;
  /** Название склада */
  name?: string | null;
  /** Адрес склада */
  address?: string | null;
  /** Страна, в которой находится склад */
  country?: string | null;
  /** Субъект */
  subject?: string | null;
  /** Город, в котором находится склад */
  city?: string | null;
  /** Название улицы, на которой находится склад */
  street?: string | null;
  /** Номер дома */
  house?: string | null;
  /** Координаты */
  coordinates?: string | null;
};
export type BillOutputModel = {
  /** Перевозчик */
  transporter?: string | null;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Отправитель */
  sender?: string | null;
  /** Количество коробов */
  boxexCount?: number | null;
  /** Количество паллет */
  palletsCount?: number | null;
  id?: number;
  /** Количество товара */
  quantity?: number;
  /** Товары в накладной */
  productsBills?: ProductBillOutputModel[] | null;
  /** Идентификатор пользователя владельца товара */
  ownerId?: number;
  /** Идентификатор машины, увозящей товар */
  carId?: number | null;
  /** Идентификатор склада, с которого увозят товар */
  warehouseId?: number;
  warehouse?: WarehouseOutputModel;
  /** Текстовая заметка */
  note?: string | null;
  /** Комментарий к доставке */
  deliveryNote?: string | null;
  /** Адрес назначения товара */
  address?: string | null;
  /** Запланированное время отъезда машины */
  realizationDate?: string | null;
  /** Фактическое время отъезда машины */
  actualRealizationDate?: string | null;
  status?: BillStatus;
  type?: BillType;
  /** Номер  накладной */
  number?: number;
  /** Дата создания */
  objectCreateDate?: string;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Наименование организации */
  organizationName?: string | null;
};
export type ProductBillModel = {
  /** Идентификатор товара */
  id?: number;
  /** Количество */
  quantity?: number;
};
export type CreateBillModel = {
  /** Перевозчик */
  transporter?: string | null;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Отправитель */
  sender?: string | null;
  /** Количество коробов */
  boxexCount?: number | null;
  /** Количество паллет */
  palletsCount?: number | null;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Наименование организации */
  organizationName?: string | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Идентфикатор машины доставки */
  carId?: number | null;
  /** Идентификатор склада */
  warehouseId?: number;
  type?: BillType;
  /** Комментарий */
  note?: string | null;
  /** Комментарий к доставке */
  deliveryNote?: string | null;
  /** Адрес */
  address?: string | null;
  /** Список товаров */
  products?: ProductBillModel[] | null;
  ownerId?: number | null;
};
export type PackageBillProductConsumableOutputModel = {
  id?: number;
  consumableId?: number;
  name?: string | null;
  quantity?: number;
};
export type PackageProductBillOutput = {
  id?: number;
  organizationName?: string | null;
  /** Наименование изображения */
  imageName?: string | null;
  /** Наименование товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Внешний баракод */
  barcode?: string | null;
  /** Артикль товара */
  article?: string | null;
  quantity?: number;
  consumabes?: PackageBillProductConsumableOutputModel[] | null;
};
export type PackageBillOutputModel = {
  status?: BillStatus;
  id?: number;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Комментарий */
  note?: string | null;
  /** Список товаров */
  products?: PackageProductBillOutput[] | null;
};
export type CreatePackageBillProductConsumable = {
  consumableId?: number;
  quantity?: number;
};
export type CreatePackageBillProductModel = {
  id?: number;
  quantity?: number;
  consumables?: CreatePackageBillProductConsumable[] | null;
};
export type CreatePackageBillModel = {
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Идентификатор склада */
  warehouseId?: number;
  /** Комментарий */
  note?: string | null;
  /** Список товаров */
  products?: CreatePackageBillProductModel[] | null;
};
export type CreatePlacementBillProduct = {
  receivedProductCellId?: number;
  toCellId?: number;
  quantity?: number;
};
export type CreatePlacementBillModel = {
  /** Текстовая заметка */
  note?: string | null;
  /** Запланированное время реализации */
  realizationDate?: string | null;
  createPlacementBillProducts?: CreatePlacementBillProduct[] | null;
};
export type RedactPackageBillProductConsumable = {
  id?: number;
  consumableId?: number;
  quantity?: number;
};
export type RedactPackageBillProductModel = {
  id?: number;
  productId?: number;
  quantity?: number;
  consumables?: RedactPackageBillProductConsumable[] | null;
};
export type RedactPackageBillModel = {
  id?: number;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Идентификатор склада */
  warehouseId?: number;
  /** Комментарий */
  note?: string | null;
  /** Список товаров */
  products?: RedactPackageBillProductModel[] | null;
};
export type RedactBillModel = {
  /** Идентификатор накладной */
  id?: number;
  /** Перевозчик */
  transporter?: string | null;
  /** Внешний идентификатор накладной */
  externalBillId?: string | null;
  /** Отправитель */
  sender?: string | null;
  /** Количество коробов */
  boxexCount?: number | null;
  /** Количество паллет */
  palletsCount?: number | null;
  /** Наименование склада куда приходит товар */
  warehouseName?: string | null;
  /** Наименование организации */
  organizationName?: string | null;
  /** Идентификатор организации */
  organizationId?: number;
  /** Дата реализации */
  realizeDate?: string;
  /** Идентфикатор машины доставки */
  carId?: number | null;
  /** Идентификатор склада */
  warehouseId?: number;
  type?: BillType;
  /** Комментарий */
  note?: string | null;
  /** Комментарий к доставке */
  deliveryNote?: string | null;
  /** Адрес */
  address?: string | null;
  /** Список товаров */
  products?: ProductBillModel[] | null;
  ownerId?: number | null;
};
export type StoragePlaceModel = {
  /** Место */
  place?: string | null;
  /** Идентификатор */
  id?: number;
  /** Имя */
  name?: string | null;
  status?: StoragePlaceStatus;
  /** Код ячейки */
  code?: string | null;
  warehouse?: DifWarehouse;
  cellType?: CellTypeEnum;
  /** Процент заполненности ячейки */
  occupancyPercent?: number;
};
export type CreateStoragePlaceRequest = {
  /** Место */
  place?: string | null;
  /** Имя ячейки */
  name?: string | null;
  /** Описание */
  description?: string | null;
  status?: StoragePlaceStatus;
  /** Код ячейки */
  code?: string | null;
  /** TODO */
  stageId?: number;
  /** Идентификатор склада */
  warehouseId?: number;
};
export type CreateConsumablesModel = {
  name?: string | null;
  quantity?: number;
  warehouseId?: number;
};
export type DifConsumablesProduct = {
  id?: number;
  objectCreateDate?: string;
  employeeId?: number;
  employee?: DifEmployee;
  productId?: number;
  product?: DifProduct;
  consumablesId?: number;
  consumables?: DifConsumables;
  quantity?: number;
};
export type PeriodType = 0 | 1 | 2;
export type ConsumablesFilter = {
  /** Пропустить Skip объектов */
  skip?: number | null;
  /** Взять Take объектов */
  take?: number | null;
  /** Идентификатор сотрудника */
  employeeId?: number;
  /** Идентификаторы товаров, по которым нужны расходы */
  productsId?: number[] | null;
  byPeriodType?: PeriodType;
};
export type ConsumablesProductsFilter = {
  /** Идентификатор товара */
  productId?: number;
  /** Идентификатор расходника */
  consumablesId?: number;
};
export type DicPosition = {
  x?: number;
  y?: number;
};
export type DicVisitInputModel = {
  userId?: string;
  sessionId?: string;
  positions?: DicPosition[] | null;
};
export type DocumentOutput = {
  name?: string | null;
};
export type EmployeeTypeOutputModel = {
  id?: number;
  type?: string | null;
};
export type UserModel = {
  /** Имя */
  name?: string | null;
  /** Почта */
  email?: string | null;
  /** Аватар */
  image?: string | null;
  /** Идентифиатор пользователя */
  id?: number;
  /** Идентификатор сотрудника */
  employeeId?: number | null;
  /** Идентификатор администратора */
  adminId?: number | null;
};
export type EmployeeOuttputModel = {
  id?: number;
  /** Идентификатор типа сотрудника */
  typeId?: number;
  type?: EmployeeTypeOutputModel;
  /** Идентификатор пользователя */
  userId?: number;
  user?: UserModel;
  /** Идентификатор склада, на котром работает сотрудник */
  warehouseId?: number;
  warehouse?: WarehouseOutputModel;
  /** Расписание работы сотрудника */
  wortkSchedule?: string | null;
};
export type CollectionFilter = {
  /** Пропустить Skip объектов */
  skip?: number | null;
  /** Взять Take объектов */
  take?: number | null;
};
export type DifEmployeePaymentsHistory = {
  id?: number;
  objectCreateDate?: string;
  employeeId?: number;
  employee?: DifEmployee;
  rate?: number;
  amount?: number;
  workingHours?: number;
};
export type CreateFileResponse = {
  /** Путь к файлу */
  filePath?: string | null;
};
export type WarehouseInventorizationModel = {
  /** Текущее количество товара */
  actualQuantity?: number;
  /** Товар, который еще или уже не лежит на складе */
  undefinedQuantity?: number;
  /** Полное количество товара */
  quantity?: number;
  /** Название склада */
  warehouseName?: string | null;
  /** Идентификатор склада */
  warehouseId?: number;
};
export type ClientInventorizationModel = {
  /** Текущее количество товара */
  actualQuantity?: number;
  /** Товар, который еще или уже не лежит на складе */
  undefinedQuantity?: number;
  /** Полное количество товара */
  quantity?: number;
  /** Почта владельца товраа */
  email?: string | null;
  /** Идентификатор владельца товра */
  clientId?: number;
};
export type ProductInventorizationModel = {
  /** Текущее количество товара */
  actualQuantity?: number;
  /** Товар, который еще или уже не лежит на складе */
  undefinedQuantity?: number;
  /** Полное количество товара */
  quantity?: number;
  product?: DifProduct;
};
export type IntegrationDataModel = {
  credentials?: string | null;
};
export type NotificationSeenModelInput = {
  notificationId?: number;
};
export type ProductInputModel = {
  id?: number;
  productId?: number;
  quantity?: number;
};
export type CompletePackageInputModel = {
  billId?: number;
  cellId?: number;
  products?: ProductInputModel[] | null;
};
export type ProcessRealTimeEmployeeOutputModel = {
  processId?: number;
  employeeId?: number;
  name?: string | null;
  email?: string | null;
};
export type ProcessRealTimeEmployeeInputModel = {
  processId?: number;
};
export type RedactProcessEmployeeModel = {
  processBillProductId?: number;
  isIncrement?: boolean;
  quantity?: number | null;
};
export type ProcessPcakageBillModel = {
  id?: number;
  name?: string | null;
  emps?: DifProcessPackageEmployee[] | null;
  percent?: number;
  dateStart?: string;
  dateEnd?: string | null;
};
export type ProcessProductModel = {
  images?: string[] | null;
  productBillId?: number;
  productProcessId?: number;
  name?: string | null;
  article?: string | null;
  barcode?: string | null;
  factoryBarcode?: string | null;
  quantity?: number;
  acceptanceQuantity?: number;
};
export type ProcessModel = {
  products?: ProcessProductModel[] | null;
  percent?: number;
  empsName?: string[] | null;
};
export type InspectionCompleteModel = {
  processId?: number;
  requiredFull?: boolean;
};
export type CreatePaymentBillDetailModel = {
  name?: string | null;
  quantity?: string | null;
  paymentAmount?: number;
};
export type CreatePaymentBillModel = {
  paymentAmount?: number;
  organizationId?: number;
  note?: string | null;
  createPaymentBillDetailModels?: CreatePaymentBillDetailModel[] | null;
};
export type PlacementProductInputModel = {
  id?: number;
  quantity?: number;
};
export type PlacementBillInputModel = {
  products?: PlacementProductInputModel[] | null;
  id?: number;
};
export type ProcessPlacementBillModel = {
  id?: number;
  name?: string | null;
  emps?: DifProcessPlacementEmployee[] | null;
  percent?: number;
  dateStart?: string;
  dateEnd?: string | null;
};
export type PlacementProductModel = {
  images?: string[] | null;
  toCellName?: string | null;
  fromCellName?: string | null;
  productBillId?: number;
  productProcessId?: number;
  name?: string | null;
  article?: string | null;
  barcode?: string | null;
  factoryBarcode?: string | null;
  quantity?: number;
  acceptanceQuantity?: number;
};
export type PlacementProcessModel = {
  products?: PlacementProductModel[] | null;
  percent?: number;
  empsName?: string[] | null;
};
export type ProcessEmployeeOutputModel = {
  id?: number;
  email?: string | null;
  name?: string | null;
};
export type ArModel = {
  id?: number;
  name?: string | null;
  emps?: ProcessEmployeeOutputModel[] | null;
  percent?: number;
  dateStart?: string;
  dateEnd?: string | null;
};
export type ProductCreateModel = {
  /** Цена обслуживания товара */
  serviceProductPrice?: number | null;
  /** Название товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Вес */
  weight?: number | null;
  /** Объем */
  capacity?: number | null;
  /** Внешний идентификатор */
  externalId?: string | null;
  /** Бренд */
  brand?: string | null;
  /** Идентификатор владельца товара */
  organizationId?: number;
};
export type DeleteProductImageInput = {
  productId?: number;
};
export type KitOutput = {
  kitId?: number;
};
export type CreateKitConsumableModel = {
  cunsumableId?: number;
  quantity?: number;
};
export type CreateKitProductModel = {
  createKitConsumableModels?: CreateKitConsumableModel[] | null;
  producId?: number;
  quantity?: number;
};
export type CreateKitModel = {
  /** Цена обслуживания товара */
  serviceProductPrice?: number | null;
  /** Название товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Вес */
  weight?: number | null;
  /** Объем */
  capacity?: number | null;
  /** Внешний идентификатор */
  externalId?: string | null;
  /** Бренд */
  brand?: string | null;
  /** Идентификатор владельца товара */
  organizationId?: number;
  childs?: CreateKitProductModel[] | null;
  createKitConsumableModels?: CreateKitConsumableModel[] | null;
};
export type ProductRedactModel = {
  id?: number;
  /** Цена обслуживания товара */
  serviceProductPrice?: number | null;
  /** Цена обслуживания товара */
  organizationId?: number | null;
  /** Название товара */
  name?: string | null;
  /** Заводской баркод */
  factoryBarcode?: string | null;
  /** Артикул */
  article?: string | null;
  /** Цвет товара */
  color?: string | null;
  /** Внешний баркод */
  barcode?: string | null;
  /** Вес */
  weight?: number | null;
  /** Объем */
  capacity?: number | null;
  /** Внешний идентификатор */
  externalId?: string | null;
  /** Бренд */
  brand?: string | null;
};
export type ReceivedProductOutputModel23 = {
  product?: DifProduct;
  allQuantitiesSum?: number;
  pacakgeQuantitiesSum?: number;
  quantitiesSum?: number;
};
export type ImportBillProductResponse = {
  /** Товары */
  products?: ProductOutputModel[] | null;
  /** Ненайденные товары */
  undefinedProducts?: ProductOutputModel[] | null;
};
export type ReceivedProductOutputModel = {
  /** Баркод принятого товара */
  barcode?: string | null;
  product?: ProductOutputModel;
  /** Идентификатор владельца */
  ownerId?: number;
  /** Идентификатор склада */
  warehouseId?: number;
  /** Количество товара на складе */
  quantity?: number;
};
export type ReceivedProductCellOutputModel = {
  receivedProduct?: ReceivedProductOutputModel;
  cellId?: number;
  id?: number;
  quantity?: number;
  /** Процент заполненния ячейки товаром */
  occupancyPercent?: number;
  /** Процент заполненния ячейки единиций товара */
  occupancyPercentByUnit?: number;
};
export type ReceivedProductOutputModel2 = {
  imageName?: string | null;
  barcode?: string | null;
  article?: string | null;
  name?: string | null;
  id?: number;
  cells?: DifReceivedProductCell[] | null;
  quantitySum?: number;
};
export type ShipmentProductModel = {
  /** Наименование принятого товара */
  productName?: string | null;
  /** Идентификатор принятого товара */
  productId?: number;
  /** Количесвто */
  quantity?: number;
};
export type ShipmentModel = {
  models?: ShipmentProductModel[] | null;
  billId?: number;
  cellId?: number;
};
export type OrganizationOutputModel = {
  id?: number;
  name?: string | null;
};
export const {
  useGetProductsReceivedActionsQuery,
  usePostProductsReceivedActionsActionMutation,
  useGetAdminClientsWarehousesQuery,
  usePostAdminClientsWarehousesMutation,
  usePostAdminEmpoloyeeMutation,
  usePostAnalyticsAddVisitMutation,
  useGetAnalyticsAddRecallRequestQuery,
  usePostAuthLoginMutation,
  usePostAuthRegistrationMutation,
  useGetBillsQuery,
  useDeleteBillsMutation,
  useGetBillsGetAcceptanceBillsByProductBarcodeQuery,
  useGetBillsGetShipmentBillsByProductBarcodeQuery,
  useGetBillsShipmentQuery,
  useGetBillsAcceptanceQuery,
  useGetBillsPackageQuery,
  useGetBillsPlacementQuery,
  useGetBillsBillQuery,
  usePostBillsBillMutation,
  useGetBillsPackageBillQuery,
  usePostBillsPackageBillMutation,
  useGetBillsPlacementBillQuery,
  usePostBillsPlacementBillMutation,
  usePostBillsRedactPackageBillMutation,
  usePostBillsRedactBillMutation,
  usePostBillsFileExcelMutation,
  usePostBillsEmployeeFileExcelMutation,
  usePutBillsStatusMutation,
  useGetStoragePlacesQuery,
  useGetStorageQuery,
  useGetStorageCellsByReceivedProductQuery,
  useGetStorageCellQuery,
  usePostStoragePlaceMutation,
  useGetConsumablesQuery,
  usePostConsumablesMutation,
  useGetConsumablesSpendingQuery,
  useGetConsumablesSpendingActualQuery,
  usePostDicAddVisitMutation,
  useGetDicAddRecallRequestQuery,
  useGetDocumentsQuery,
  useGetDocumentsDocxBillsQuery,
  useGetDocumentsExcelBillsQuery,
  usePostDocumentsTemplateMutation,
  useGetAdminEmployeesQuery,
  useGetAdminEmployeesPaymentsQuery,
  usePostFilesUploadMutation,
  useGetProductsInventorizeWarehouseQuery,
  useGetProductsInventorizeClientsQuery,
  useGetProductsInventorizeClientQuery,
  useGetProductsInventorizeProductsQuery,
  usePostIntegrationMoySkladProductMutation,
  usePostIntegrationMoySkladDataMutation,
  usePostNotificationsSeenMutation,
  useGetNotificationsQuery,
  usePostNotificationsNewMutation,
  usePostPackageCompleteMutation,
  usePostProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessMutation,
  usePostProcessesPackageRedactProcessEmployeeMutation,
  usePostProcessesPackageAddProcessMutation,
  useGetProcessesPackageGetProcessBillByIdQuery,
  useGetProcessesPackageGetProcessesBillQuery,
  useGetProcessesPackageProcessModelQuery,
  usePostProcessesPackageCompleteMutation,
  useGetPaymentsBillQuery,
  useGetPaymentsBillsQuery,
  usePostNewBillMutation,
  usePostPlacementCompleteMutation,
  usePostProcessesPlacementSetEmployeeInProcessAndGetEmployeesInProcessMutation,
  usePostProcessesPlacementRedactProcessEmployeeMutation,
  usePostProcessesPlacementAddProcessMutation,
  useGetProcessesPlacementGetProcessBillByIdQuery,
  useGetProcessesPlacementGetProcessesBillQuery,
  useGetProcessesPlacementProcessModelQuery,
  usePostProcessesPlacementCompleteMutation,
  useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery,
  usePostProcessesRedactProcessEmployeeMutation,
  usePostProcessesAddProcessMutation,
  useGetProcessesGetProcessBillByIdQuery,
  useGetProcessesGetProcessesBillQuery,
  useGetProcessesInspectionProcessModelQuery,
  usePostProcessesInspectionCompleteMutation,
  useGetProductsQuery,
  usePostProductsMutation,
  useDeleteProductsMutation,
  useDeleteProductsImageMutation,
  usePostProductsImageMutation,
  usePostProductsKitMutation,
  usePostProductsRedactMutation,
  useGetProductsReceivedQuery,
  usePostProductsBillImportMutation,
  useGetProductsReceivedCellQuery,
  useGetProductsReceivedByWarehouseQuery,
  usePostProductsReceivedShipMutation,
  useGetUsersUserOrganizationsQuery,
  useGetUsersMeQuery,
  usePutUsersMeMutation,
  useGetUsersClientsQuery,
  useGetUsersEmployeesQuery,
  useGetWarehouseQuery,
  useGetWarehouseByIdQuery,
} = injectedRtkApi;
