import React, { useState } from "react";
import Container from "widgets/Container/ui";
import styles from "./index.module.scss";
import {Button} from "shared/ui/Button";
import Layout from "widgets/Layout/Layout";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { useGetUsersEmployeesQuery } from "api";

const columns: ColumnHeaderType[] = [{
  name: "ID",
  width: "25%"
},
{
  name: "Почта",
  width:"25%"
},
{
  name: "Склад",
  width: "25%"
}]

const options = ["ID", "Почта", "Склад"];

const UsersPage = ({}) =>  {
  const {data: employeesByWarehouse } = useGetUsersEmployeesQuery({warehouseId: 1});

  const [searchText, setSearchText] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Почта"]);

  const filteredData = employeesByWarehouse?.filter(el => filterDataEl(searchText, selectedOptions, [
      {value: el.id, option: "ID"},
      {value: el.user?.email, option: "Почта"},
      {value: el.warehouse?.name, option: "Склад"},
  ]));
  
  const [selectedOption, setSelectedOption] = useState("Почта");

  const [isDesc, setIsDesc] = useState(true);

  const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
      {value2: el2.id, value1: el1.id, option: "ID"},
      {value2: el2.user?.email, value1: el1.user?.email, option: "Почта"},
      {value2: el2.warehouse?.name, value1: el1.warehouse?.name, option: "Склад"},
  ], selectedOption, isDesc));

  return (
    <Layout>
      <Container>
          <div style={{display: 'flex', justifyContent:'space-between', marginBottom: 20}}>
              <div style={{display: "flex"}}>
                  <Button
                      inlineStyles={{marginRight: 10}}
                      text="Обновить"
                      onClick={() => {}}
                      />
                  <Button 
                    inlineStyles={{marginRight: 10}}  
                    text="Добавить" 
                    onClick={() => {}}
                    />
                  <Button 
                    inlineStyles={{marginRight: 10}}  
                    text="Редактировать"
                    onClick={() => {}}
                    />
                  <Button 
                    text="Заблокировать" 
                    onClick={() => {}}
                    />
              </div>
              <div style={{display: 'flex'}}>
                  <SearchInput 
                      value={searchText} 
                      onChange={setSearchText}
                      inlineStyles={{marginRight: 10}}
                      classNames={styles.searchInput}
                      />
                  <MultipleSelect 
                      text="Параметры"
                      options={options} 
                      selectedOptions={selectedOptions} 
                      setSelectedOptions={setSelectedOptions}
                      inlineStyles={{marginRight: 10, width: 135}}
                      />
                  <Sort 
                      options={options}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      setIsDesc={setIsDesc}
                      isDesc={isDesc}
                      selectClassNames={styles.select}
                      />
              </div>
          </div>
              <Table columns={columns}>
                  {sortedData?.length != 0 ?
                      sortedData?.map(el => (
                          <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                              <>
                                <TableItemContainer width={columns[0].width}>{el.id}</TableItemContainer>
                                <TableItemContainer width={columns[1].width}>{el.user?.email}</TableItemContainer>
                                <TableItemContainer width={columns[2].width}>{el.warehouse?.name}</TableItemContainer>
                              </>
                          </TableItem>
                  ))
                  :  <></>
              }
              </Table>
      </Container>
    </Layout>
  )
}

export {UsersPage};