import React, { FC, useState } from "react";
import styles from "./MenuItem.module.scss";
import { MenuItemType } from "../../consts";
import CustomLink from "../CustomLink/CustomLink";
import { useAppSelector } from "app/store/hooks/redux";
import { ArrowIcon } from "shared/ui/icons/icons";
import clsx from "clsx";

type MenuItemProps = {
    menuItem: MenuItemType,
    defaultOpen : boolean
}

const MenuItem: FC<MenuItemProps> = ({menuItem, defaultOpen}) => {
    const [isOpen, setIsOpen] = useState(!defaultOpen);
    const user = useAppSelector(state => state.userReducer);
    
    return (
            <div className={styles.menuItemLink}>
                <span className={styles.menuItem} onClick={() => setIsOpen(!isOpen)}>
                    {
                        menuItem.path 
                        ? <CustomLink to={menuItem.path}>
                            {menuItem.name}
                        </CustomLink> 
                        : <div className={clsx(isOpen ? styles.padding_open : styles.padding_close)}> {menuItem.name} <ArrowIcon inlineStyles={{transform: isOpen ? "rotate(0)" : "rotate(180deg)"}}/> </div>
                    }
                </span>
                {
                    menuItem.subItem?.length != 0 && menuItem.subItem != undefined 
                    ? <div className={isOpen ? styles.subItemWrapper : styles.subItemWrapper_active}>
                            {menuItem.subItem?.map(item => (
                                item.allowedFor?.some(r => user.roles?.includes(r)) ?? true 
                                ? <div key={item.name} className={styles.menuSubItem}>
                                    <CustomLink to={item.path}>
                                        <span>{item.name}</span>
                                    </CustomLink>
                                </div>
                                : null
                            ))} 
                    </div>
                    : null
                }
                
            </div>
    )
};

export default MenuItem;