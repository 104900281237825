import styles from "./RedirectButton.module.scss";
import { Link } from "react-router-dom";

type Props = {
    text: string,
    buttonName: string,
    path: string,
};

const RedirectButton = ({text, buttonName, path}: Props) => {
    return (
        <div className={styles.redirectButton}>
            <span className={styles.redirectButton__text}>{text}</span>
            <Link to={path} className={styles.redirectButton__button}>
                {buttonName}
            </Link>
        </div>
    )
};

export default RedirectButton;