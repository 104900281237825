import { CSSProperties } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";

type Props = {
    text: string,
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean,
    className?: string,
    inlineStyles?: CSSProperties;
};

const Button = ({text, onClick, disabled, className, inlineStyles}: Props) => {
    return (
        <button disabled={disabled} onClick={e => {
            e.stopPropagation();
            onClick(e);
        }} style={inlineStyles} className={clsx(styles.button, className)}>
            {text}
        </button>
    );
};

export {Button};