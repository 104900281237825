import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProductsDataForPushState = {
    pageName: string;
};

const initialState: ProductsDataForPushState = {
    pageName: "Главная"
};

export const pageNameSlice = createSlice({
    name: 'pageName',
    initialState,
    reducers: {
        setPageName(state, action: PayloadAction<string>) {
            return {...state, pageName: action.payload}
        }
    }
});

export default pageNameSlice.reducer;