import { CSSProperties } from "react"
import styles from "./TableSelect.module.scss"
import clsx from "clsx";

type Props = {
    isTitleVisible?: boolean;
    title?: string,
    options : Array<TableSelectOption>
    classNames?: string;
    inlineStyles?: CSSProperties;
}

export type TableSelectOption = {
    onClick : () => void,
    name: string;
}

const TableSelect =  ({title, options, classNames, inlineStyles,isTitleVisible}: Props) => {

    return <div className={clsx(styles.select,classNames)} style={inlineStyles}>
        {isTitleVisible && 
            <div className={styles.title}>
                {title}
            </div>
        }
        <div className={styles.options}>
            {
                options.map(option => 
                    <div className={styles.option} onClick={option.onClick}>
                        {option.name}
                    </div>)
            }
        </div>

    </div>
}

export default TableSelect;