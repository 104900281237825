import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import stylesm from "./index_mobile.module.scss";
import { BurgerIcon, CloseIcon, Logo, LogoIcon } from "shared/ui/icons/icons";
import { BASE_URL } from "app/store/consts";

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

type IPosition = {
    x: number;
    y: number;
}

let mousePositions: IPosition[] = []

// setInterval(()=> {
//     console.log(mousePositions);
// },2000);

const InformationPage = () => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [tg, setTg] = useState("");
    
    const [openMenu, setOpenMenu] = useState(false);
    
    const [sessionId] = useState(generateUUID());

    const scollToRefCompany = useRef<HTMLDivElement>(null);
    const scollToRefOpportunities = useRef<HTMLDivElement>(null);
    const scollToRefPresentation = useRef<HTMLDivElement>(null);

    useEffect(()=> {
        let userId = localStorage.getItem("userId");

        if (userId == null) 
        {
            let newUserId = generateUUID();
            localStorage.setItem("userId", newUserId);
            // fetch(BASE_URL + "/analytics/addVisit?userId=" + newUserId
            //     + "&sessionId=" + sessionId
            // );

            fetch(BASE_URL + "/analytics/addVisit", {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: newUserId,
                    sessionId,
                    positions: mousePositions
                })
            })

            setInterval(() => {
                // fetch(BASE_URL + "/analytics/addVisit?userId=" + newUserId
                //     + "&sessionId=" + sessionId
                // );

                fetch(BASE_URL + "/analytics/addVisit", {
                    method: "POST",
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: newUserId,
                        sessionId,
                        positions: mousePositions
                    })
                })

                mousePositions = [];
            }, 5000)
        }
        else 
        {
            // fetch(BASE_URL + "/analytics/addVisit?userId=" + userId
            //     + "&sessionId=" + sessionId
            // );

            fetch(BASE_URL + "/analytics/addVisit", {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId,
                    sessionId,
                    positions: mousePositions
                })
            })

            setInterval(() => {
                fetch(BASE_URL + "/analytics/addVisit", {
                    method: "POST",
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId,
                        sessionId,
                        positions: mousePositions
                    })
                })

                mousePositions = [];
            }, 5000)
        }
    },[]);

    const sendFormData = () => {
        let userId = localStorage.getItem("userId");

        if (number == "" && email == "" && tg == "") 
        {
            alert("Заполните хотя бы одно из полей для связи");
        }

        fetch(BASE_URL + "/analytics/addRecallRequest?" 
            + "userId=" + userId
            + "&sessionId=" + sessionId
            + "&name=" + name
            + "&number=" + number
            + "&email=" + email
            + "&telegram=" + tg
        )
    };

    const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        mousePositions.push({x: e.clientX, y: e.clientY});
        console.log(mousePositions);
    };

    return (
        <>
        <div className={styles.pc} onMouseMove={mouseMove}>
            <div className={styles.header}>
                <Logo/>
                <div style={{display: 'flex'}} className={styles.color_white}>
                    <div style={{marginRight: 20}}>
                        <a href="#company" style={{color: "#fff"}}>
                            О компании
                        </a>
                    </div>
                    <div style={{marginRight: 20}}>
                        <a href="#opportunities" style={{color: "#fff"}}>
                            Возможности
                        </a>
                    </div>
                    <div>
                        <a href="#presentation" style={{color: "#fff"}}>
                            Презентация
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.contactsContainer}>
                <div style={{marginRight: 20}}>
                    <a style={{color: "#322C36"}} href="tel:+79196165333">+7 919 616 53 33</a>
                </div>
                <div style={{marginRight: 20}}>
                    <a style={{color: "#322C36"}} href="https://t.me/difwms">@difwms</a>
                </div>
                {/* <div style={{color: "#322C36"}}>
                    diffwms@gmail.com
                </div> */}
            </div>
            <div className={styles.form}>
                <div style={{marginLeft: 40}}>
                    <div className={styles.h2}>
                        Оставьте заявку и мы с вами свяжемся
                    </div>
                    <div className={styles.container}>
                        <input type="text" placeholder="Имя" className={styles.input} value={name} onChange={(e) => setName(e.target.value)} name="" id="" />
                        <input type="text" placeholder="Телефон" className={styles.input} value={number} onChange={(e) => setNumber(e.target.value)} name="" id="" />
                        <input type="text" placeholder="Почта" className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} name="" id="" />
                        <input type="text" placeholder="Телеграм" className={styles.input} value={tg} onChange={(e) => setTg(e.target.value)} name="" id="" />
                        <button onClick={sendFormData} className={styles.button}>Оставить заявку</button>
                    </div>
                </div>
                <img className={styles.img} src="assets/images/women.png" alt="" />
            </div>
            <div style={{margin: "0 40px"}}>
                {/* <div style={{marginTop: 40}}>
                    <div className={styles.h3} id="opportunities">
                        Возможности
                    </div>
                    <div className={styles.text}>
                        <li>Работа над одним процессом параллельно несколькими людьми.</li>
                        <br /><li>Частичное выполнение процесса.</li>
                        <br /><li>Отслеживание информации о процессах текущих и прошлых.</li>
                        <br /><li>Счёт расхода расходников.</li>
                        <br /><li>Гибкий конфигуратор скалада с ярусами, этажами.</li>
                        <br /><li>Карта ячеек по складу и местоположение сотрудника на складе.</li>
                        <br /><li>Выставление счетов и оплата.</li>
                        <br /><li>Выставление графиков работы.</li>
                        <br /><li>Просмотр товаров по клиентам.</li>
                        <br /><li>Просмотр товаров по ячейкам.</li>
                        <br /><li>Периодическое формирование отчётов.</li>
                        <br />
                        <br />
                        И много чего другого, чтобы узнать побольше и подробнее о функионале, позвоните/напишите нам или оставьте заявку и мы с вами свяжемся !
                    </div>
                </div> */}
                <div style={{marginTop: 40}}>
                    <div className={styles.h3} id="company">
                        О компании
                    </div>
                    <div className={styles.text}>
                        DIF - это высокоэффективная система управления складскими процессами, специально разработанная для оптимизации работы склада и увеличения эффективности операций. С помощью DIF компания может автоматизировать учет товаров, обеспечить точное планирование и контроль поступлений и отгрузок товаров, а также оптимизировать процессы отбора и укладки товаров на складе. Система DIF обладает широким набором функций, включая управление инвентаризацией, отслеживание движения товаров, генерацию отчетов и аналитику данных. Благодаря DIF компания может существенно улучшить свою логистику, уменьшить затраты и повысить уровень обслуживания клиентов.
                    </div>
                </div>
            </div>
            {/* <div className={styles.h3} style={{margin: "40px 0 20px 40px"}} id="presentation">
                Презентация
            </div>
            <div style={{margin: "0 30px"}}>
                <img className={styles.presentationImg} src="assets/images/home.png" style={{marginBottom: 20}} alt="" />
                <img className={styles.presentationImg} src="assets/images/products.png" alt="" />
            </div> */}
        </div>

        <div className={styles.mobile} onMouseMove={mouseMove}>
            {openMenu 
                && 
                <div className={stylesm.modal}>
                    <div>
                        <CloseIcon fill="#fff" onClick={() => setOpenMenu(false)} inlineStyles={{marginBottom: "34px"}}/>
                        <div className={stylesm.modalInfo}>
                            <div>
                                <a href="#company" onClick={() => 
                                    {
                                        setOpenMenu(false);
                                        scollToRefCompany.current?.scrollIntoView()
                                    }} style={{color: "#fff"}}>
                                    О компании
                                </a>
                            </div>
                            <div>
                                <a href="#opportunities" onClick={() => 
                                    {
                                        setOpenMenu(false);
                                        scollToRefOpportunities.current?.scrollIntoView()
                                    }} style={{color: "#fff"}}>
                                    Возможности
                                </a>
                            </div>
                            <div>
                                <a href="#presentation" onClick={() => 
                                    {
                                        setOpenMenu(false);
                                        scollToRefPresentation.current?.scrollIntoView()
                                    }} style={{color: "#fff"}}>
                                    Презентация
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={stylesm.modalContactsContainer}>
                        <div style={{marginBottom: 10}}>
                            <a style={{color: "#fff"}} href="tel:+79196165333">+7 919 616 53 33</a>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <a style={{color: "#fff"}} href="https://t.me/difwms">@difwms</a>
                        </div>
                        {/* <div>
                            diffwms@gmail.com
                        </div> */}
                    </div>
                </div>
                }
            <Logo inlineClass={stylesm.logo}/>
            <div className={stylesm.header}>
                <BurgerIcon onClick={() => setOpenMenu(true)} />
            </div>
            <div className={stylesm.contactsContainer}>
                <div style={{marginBottom: 10}}>
                    <a style={{color: "#322C36"}} href="tel:+79196165333">+7 919 616 53 33</a>
                </div>
                <div style={{marginBottom: 10}}>
                    <a style={{color: "#322C36"}} href="https://t.me/difwms">@difwms</a>
                </div>
                <div style={{color: "#322C36"}}>
                    diffwms@gmail.com
                </div>
            </div>
            <div className={stylesm.form}>
                <div className={stylesm.h2}>
                    Оставьте заявку и мы с вами свяжемся
                </div>
                <div className={stylesm.container}>
                    <input type="text" placeholder="Имя" className={stylesm.input} value={name} onChange={(e) => setName(e.target.value)} name="" id="" />
                    <input type="text" placeholder="Телефон" className={stylesm.input} value={number} onChange={(e) => setNumber(e.target.value)} name="" id="" />
                    <input type="text" placeholder="Почта" className={stylesm.input} value={email} onChange={(e) => setEmail(e.target.value)} name="" id="" />
                    <input type="text" placeholder="Телеграм" className={stylesm.input} value={tg} onChange={(e) => setTg(e.target.value)} name="" id="" />
                    <button onClick={sendFormData} className={stylesm.button}>Оставить заявку</button>
                </div>
            </div>
            <div style={{margin: "0 10px"}}>
                {/* <div>
                    <div className={stylesm.h3} id="opportunities" ref={scollToRefOpportunities}>
                        Возможности
                    </div>
                    <div className={stylesm.text}>
                        <br /><li>Работа над одним процессом параллельно несколькими людьми.</li>
                        <br /><li>Частичное выполнение процесса.</li>
                        <br /><li>Отслеживание информации о процессах текущих и прошлых.</li>
                        <br /><li>Счёт расхода расходников.</li>
                        <br /><li>Гибкий конфигуратор скалада с ярусами, этажами.</li>
                        <br /><li>Карта ячеек по складу и местоположение сотрудника на складе.</li>
                        <br /><li>Выставление счетов и оплата.</li>
                        <br /><li>Выставление графиков работы.</li>
                        <br /><li>Просмотр товаров по клиентам.</li>
                        <br /><li>Просмотр товаров по ячейкам.</li>
                        <br /><li>Периодическое формирование отчётов.</li>
                        <br />
                        <br />
                        И много чего другого, чтобы узнать побольше и подробнее о функионале, позвоните/напишите нам или оставьте заявку и мы с вами свяжемся !
                    </div>
                </div> */}
                <div style={{marginTop: 40}}>
                    <div className={stylesm.h3} ref={scollToRefCompany}>
                        О компании
                    </div>
                    <div className={stylesm.text}>
                        DIF - это высокоэффективная система управления складскими процессами, специально разработанная для оптимизации работы склада и увеличения эффективности операций. С помощью DIF компания может автоматизировать учет товаров, обеспечить точное планирование и контроль поступлений и отгрузок товаров, а также оптимизировать процессы отбора и укладки товаров на складе. Система DIF обладает широким набором функций, включая управление инвентаризацией, отслеживание движения товаров, генерацию отчетов и аналитику данных. Благодаря DIF компания может существенно улучшить свою логистику, уменьшить затраты и повысить уровень обслуживания клиентов.
                    </div>
                </div>
            </div>
            {/* <div className={stylesm.h3} style={{margin: "40px 0 20px 10px"}} ref={scollToRefPresentation}>
                Презентация
            </div> */}
            {/* <div style={{margin: "0 10px"}}>
                <img className={stylesm.presentationImg} src="assets/images/home.png" style={{marginBottom: 20}} alt="" />
                <img className={stylesm.presentationImg} src="assets/images/products.png" alt="" />
            </div> */}
        </div>
        </>
    );
};

export {
    InformationPage
};