import React, { useState } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import stylesm from "./index_mobile.module.scss";
import { BaseSharedComponentType } from "types";
import { OptionsIcon } from "../icons/icons";

type Props = {
    options: string[];
    selectedOptions: string[] | undefined;
    setSelectedOptions: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    text: string;
} & BaseSharedComponentType

const MultipleSelect = ({text, options, selectedOptions, setSelectedOptions, inlineClasses, inlineStyles}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className={clsx(styles.container, inlineClasses, styles.pc)} style={inlineStyles}>
                <div
                    onClick={()=> setIsOpen(!isOpen)} 
                    className={styles.top}
                    >
                    {text}
                </div>
                {
                    isOpen
                        && (
                            <div className={styles.bottom}>
                                {options.map(el => {
                                    return (
                                        <div
                                            onClick={() => {
                                                if (selectedOptions == undefined)
                                                {
                                                    setSelectedOptions([el]);
                                                }
                                                else 
                                                {
                                                    selectedOptions?.includes(el)
                                                        ? setSelectedOptions(selectedOptions.filter(x => x != el))
                                                        : setSelectedOptions([...selectedOptions, el])
                                                    
                                                }
                                            }} 
                                            className={clsx(styles.option, selectedOptions?.includes(el) && styles.selectedOption)}
                                            >
                                            {el}
                                        </div>
                                    )
                                })}
                            </div>
                            )
                }
            </div>

            <div className={clsx(stylesm.container, inlineClasses, styles.mobile)}>
                <div
                    onClick={()=> setIsOpen(!isOpen)} 
                    className={stylesm.select}
                    >
                        <OptionsIcon/>
                </div>
                {
                        isOpen
                            && (
                                <div className={styles.bottom}>
                                    {options.map(el => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (selectedOptions == undefined)
                                                    {
                                                        setSelectedOptions([el]);
                                                    }
                                                    else 
                                                    {
                                                        selectedOptions?.includes(el)
                                                            ? setSelectedOptions(selectedOptions.filter(x => x != el))
                                                            : setSelectedOptions([...selectedOptions, el])
                                                        
                                                    }
                                                }} 
                                                className={clsx(styles.option, selectedOptions?.includes(el) && styles.selectedOption)}
                                                >
                                                {el}
                                            </div>
                                        )
                                    })}
                                </div>
                                )
                    }
            </div>
        </>
    );
}

export {
    MultipleSelect
};