import styles from "./Terminal.module.scss";
import { itemsTerminalCard } from "../consts";
import ItemCard from "../components/ItemTerminal/ItemTerminal";
import Layout from "widgets/Layout/Layout";
import Container from "widgets/Container/ui";

const TerminalPage = () => {
    return (
        <Layout>
            <Container>
                <div className={styles.page}>
                    <div className={styles.card}>
                        <div className={styles.content}>
                            {itemsTerminalCard.map(item => (
                                <ItemCard key={item.title} item={item}/>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
};

export {TerminalPage};