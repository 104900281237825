import React, { useState } from "react";
import styles from './index.module.scss';
import { MultipleSelect } from "shared/ui/MultipleSelect";
import clsx from "clsx";
import { SearchInput } from "shared/ui/SearchInput";
import { Sort } from "shared/ui/Sort";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useGetProductsReceivedCellQuery } from "api";
import { Container } from "shared/ui/Container";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import boxImg from "shared/assets/images/box.png";
import { Button } from "shared/ui/Button";

type Props = {
    id: number;
    closeModal: () => void;
};

const ProductsStorageModal = ({id, closeModal}: Props) => {
    const {data: receivedProductsByCell} = useGetProductsReceivedCellQuery({skip: 0, take: 100, cellId: id});

    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Наименование"]);
    const [searchText, setSearchText] = useState("");

    const options = ["Наименование", "Артикул", "Заводской ШТ", "Внешний ШТ", "Остаток на складе", "Заполненность ед", "Заполненность общ"];

    const filteredData = receivedProductsByCell?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.receivedProduct?.product?.name, option: "Наименование"},
        {value: el.receivedProduct?.product?.article, option: "Артикул"},
        {value: el.receivedProduct?.barcode, option: "Заводской ШТ"},
        {value: el.receivedProduct?.product?.factoryBarcode, option: "Внешний ШТ"},
        {value: el.quantity, option: "Остаток на складе"},
        {value: el.occupancyPercentByUnit, option: "Заполненность ед"},
        {value: el.occupancyPercent, option: "Заполненность общ"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("Наименование");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.receivedProduct?.product?.name, value1: el1.receivedProduct?.product?.name, option: "Наименование"},
        {value2: el2.receivedProduct?.product?.article,  value1: el1.receivedProduct?.product?.article, option: "Артикул"},
        {value2: el2.receivedProduct?.barcode, value1: el1.receivedProduct?.barcode, option: "Заводской ШТ"},
        {value2: el2.receivedProduct?.product?.factoryBarcode, value1: el1.receivedProduct?.product?.factoryBarcode, option: "Внешний ШТ"},
        {value2: el2.quantity, value1: el1.quantity, option: "Остаток на складе"},
        {value2: el2.occupancyPercentByUnit, value1: el1.occupancyPercentByUnit, option: "Заполненность ед"},
        {value2: el2.occupancyPercent, value1: el1.occupancyPercent, option: "Заполненность общ"},
    ], selectedOption, isDesc));

    return (
        <>
            <TitleForm title="Товары в этой ячейке"/>
            <div className={styles.header}>
                <SearchInput
                    classNames={styles.searchInput}
                    value={searchText}
                    onChange={setSearchText}
                    inlineStyles={{marginRight: 10, width: "52%"}}
                    />
                <MultipleSelect 
                    text="Параметры"
                    options={options} 
                    selectedOptions={selectedOptions} 
                    setSelectedOptions={setSelectedOptions}
                    inlineStyles={{marginRight: 10, width: 135}}
                    />
                <Sort 
                    options={options}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setIsDesc={setIsDesc}
                    isDesc={isDesc}
                    selectClassNames={styles.sort}
                    />
            </div>
            <div className={styles.products}>
                {sortedData?.map(el => {
                    const product = el.receivedProduct!.product!;
                    return (
                        <Container inlineClasses={styles.container}>
                            <>
                                <img
                                    className={styles.img} 
                                    src={product.images 
                                        ? product.images[0] 
                                        : boxImg} alt={product.name ?? ""} 
                                    />
                                <div className={styles.textWrapper}>
                                    <div className={styles.text}>Наименование: {product.name}</div>
                                    <div className={styles.text}>Артикул: {product.article}</div>
                                    <div className={styles.text}>
                                        Заводской ШТ: {product.barcode}
                                    </div>
                                    <div className={styles.text}>Внешний ШТ: {product.factoryBarcode}</div>
                                    <div className={clsx(styles.quantity, styles.text)}>
                                        Остаток на складе: {el.quantity}
                                    </div>
                                    <div className={styles.text}>
                                        Заполненность
                                        <div>
                                            Ед: {el.occupancyPercentByUnit}%
                                        </div>
                                        <div>
                                            Общ: {el.occupancyPercent}%
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Container>
                    )
                })}
                {sortedData?.length == 0 && <div style={{textAlign: "center", margin: "120px 0 180px 0"}}>Нет элементов</div>}
            </div>
            <Button text="Закрыть" onClick={closeModal} inlineStyles={{marginTop: 10}}/>
        </>
    )
};

export default ProductsStorageModal;