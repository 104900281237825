import React from "react";
import styles from "./index.module.scss";
import Layout from "widgets/Layout/Layout";
import {Container as SharedContainer} from "shared/ui/Container";
import Container from "widgets/Container/ui";
import { Button } from "shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetBillsPlacementQuery, usePostProcessesPlacementAddProcessMutation } from "api";

const PlacementPage = () => {
    const navigate = useNavigate();

    const {data: packageBills} = useGetBillsPlacementQuery();

    const [createProcess] = usePostProcessesPlacementAddProcessMutation();
    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
            }).unwrap();

            navigate({pathname: LinkPagesEnum.PlacementProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Layout>
                <Container>
                    <div className={styles.container}>
                        <Button
                            text="На главную"
                            onClick={() => navigate(LinkPagesEnum.Home)}
                            />
                        <div className={styles.title}>
                            Выберите накладную
                        </div>
                        <div className={styles.billsContainer}>
                            {
                                packageBills?.map(pBill => 
                                    <SharedContainer inlineClasses={styles.listContainer} onClick={() => {createProcesshandler(pBill.id!)}}>
                                        Накладная с Id = {pBill.id}
                                    </SharedContainer>
                                )
                            }
                        </div>
                    </div>
                </Container>
            </Layout>
        </>
    )
};

export {PlacementPage};