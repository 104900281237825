import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { BaseSharedComponentType } from "types";

type PropsType = {
  isFocus: boolean;
  onChange: (value: string) => void;
} & BaseSharedComponentType;

const TerminalScan = ({isFocus, onChange, inlineClasses, inlineStyles}: PropsType) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [isFirstChange, setIsFirstChange] = useState(true);

  const [isSend, setIsSend] = useState(false);

  useEffect(()=> {
    if (isSend) {
      setIsSend(false);
      setIsFirstChange(true);
      onChange(value);
      setValue("");
    }
  },[isSend])

  useEffect(() => {
    if (value != "" && isFirstChange) {
      setIsFirstChange(false);
      setTimeout(() => {
        setIsSend(true);
      }, 90);
    }
  }, [value]);

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isFocus]);

  return (
    <input 
      className={clsx(inlineClasses)}
      style={inlineStyles}
      type="text" 
      ref={inputRef} 
      value={value}
      onBlur={() => isFocus ? inputRef.current?.focus() : null}
      onChange={e => setValue(e.target.value)}
      />
  );
};

export {
  TerminalScan
}