import React, { useState } from "react";
import styles from "./index.module.scss";
import Layout from "widgets/Layout/Layout";
import { DocumentTypeEnum, useGetDocumentsQuery } from "api";
import Container from "widgets/Container/ui";
import {Container as SharedContainer} from "shared/ui/Container";
import { Select } from "shared/ui/Select";
import { BASE_URL_FILES } from "app/store/consts";
import { getDocumentTypeName } from "functions/documentType";

type PropsType = {

}

type DocumentType = {
    name: string;
    type: DocumentTypeEnum | null;
}

const DocumentsPage = ({}: PropsType) => {
    const {data: documents } = useGetDocumentsQuery();

    const options: DocumentType[] = [{name: "Все", type: null}, {name: "Накладные приходные", type: 0}, {name: "Накладные отходные", type: 1}];
    const [selectedOption, setSelectedOption] = useState<DocumentType>({name: "Все", type: null});
    const filteredDocuments = documents?.filter(document => selectedOption?.type != null ? document.documentType == selectedOption?.type : true);

    return (
        <Layout>
            <Container>
                <>
                    <div className={styles.header}>
                        <Select
                            placeholder="Тип"
                            options={options.map(option => option.name)}
                            selectedOption={selectedOption?.name}
                            onChange={val => setSelectedOption(options.find(option => option.name == val)!)}
                            classNames={styles.select}
                            />
                    </div>
                    <div className={styles.container}>
                        {filteredDocuments?.map( item => 
                            <SharedContainer inlineClasses={styles.document}>
                                <>
                                    <div>
                                        <div className={styles.document__name}>
                                            {getDocumentTypeName(item.documentType!) + " №" + item.id}
                                        </div>
                                        <div className={styles.document__date}>
                                            {(new Date(item.objectCreateDate!)).toLocaleString()}
                                        </div>
                                    </div>
                                    <div onClick={()=> {
                                        if (item.file && item.file.fileName) {
                                            window.open(BASE_URL_FILES + item.file.fileName)!.focus();
                                        }
                                    }}>
                                        {/* <img src="assets/svg/pdf_icon.svg"/> */}
                                        {item.documentExtension == 0
                                            ? <img src="assets/svg/docx_icon.svg"/>
                                            : <img className={styles.document__file} src="assets/svg/xlsx_icon.svg"/>
                                            }
                                    </div>
                                </>
                            </SharedContainer>
                            )} 
                    </div>
                </>
            </Container>
        </Layout>
    )
};

export { DocumentsPage };