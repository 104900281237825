import Container from "widgets/Container/ui";
import {Container as SharedContainer} from "shared/ui/Container";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { CreatePackageBillModel, ProductOutputModel, useGetConsumablesQuery, useGetProductsQuery, useGetUsersUserOrganizationsQuery, usePostBillsPackageBillMutation } from "api";
import React, { useEffect, useState } from "react";
import { Input } from "shared/ui/Input";
import { useAppSelector } from "app/store/hooks/redux";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import Modal from "shared/ui/Modal/Modal";
import { SearchInput } from "shared/ui/SearchInput";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import clsx from "clsx";
import img from "shared/assets/images/box.png";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TextArea } from "shared/ui/TextArea";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Наименование', width: '20%'},
    {name: 'Остаток', width: '10%'},
    {name: "Цвет", width: "10%" },
    {name: 'Количество', width: '10%'},
    {name: 'Расходник/Кол-во', width: '15%'},
];

const AddingPackageBillPage = () => {
    const {id: userId} = useAppSelector(state => state.userReducer);
    const {data: consumables} = useGetConsumablesQuery(undefined);
    const {data: productsByUser} = useGetProductsQuery({clientId: userId});

    const [createdProduct, setCreatedProduct] = useState<CreatePackageBillModel>({
        realizeDate:  (new Date()).toISOString(),
    });
    const {data: organizations} = useGetUsersUserOrganizationsQuery({userId: userId});

    useEffect(()=> {
        if (organizations != null && organizations.length == 1) 
        {
            setCreatedProduct({...createdProduct, organizationId: organizations[0].id!});
        }
    }, [organizations]);

    const [addPackageBill] = usePostBillsPackageBillMutation();

    const addPackageBillHandler = async () => {
        try {
            await addPackageBill({
                createPackageBillModel: {...createdProduct,
                    products: addedProducts.map(x => ({
                        id: x.id, 
                        quantity: x.quantity, 
                        consumables: consumablesForAddedproducts
                            ?.filter(x2 => x2.pId == x.id)
                            .map(c => ({consumableId: c.selectedConsumableId, quantity: c.count}))
                    })),
                }
            }).unwrap();

            setCreatedProduct({});
            navigate(LinkPagesEnum.PackageBills);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<ProductOutputModel[]>([]);
    const [modalSearch, setModalSearch] = useState("");
    const [consumablesForAddedproducts, setConsumablesForAddedproducts] = useState<{pId: number, idx: Date, selectedConsumableId: number, name: string, count: number}[]>([]);
    const navigate = useNavigate();

    return (
        <Layout>
            <Container>
                <>
                    <div style={{display: "flex"}}>
                        <Button text="Назад" inlineStyles={{marginRight: 10}} className={styles.headerButtons} onClick={() => navigate(LinkPagesEnum.Products)}/>
                        <Button text="Добавить накладную" className={styles.headerButtons} onClick={addPackageBillHandler}/>
                    </div>
                    <SharedContainer inlineClasses={styles.modal__fieldsContainer}>
                        <>
                            <div className={styles.modal__leftContainer}>
                                <Select inlineStyles={{marginBottom: 10}} placeholder="Организация" options={organizations?.map(el => el.name!) ?? []} selectedOption={organizations?.find(el => el.id == createdProduct.organizationId)?.name} onChange={val => setCreatedProduct({...createdProduct, organizationId: organizations?.find(el => el.name == val)?.id})} classNames={styles.modal__field}/>
                                <Input placeholder="Внешний номер накладной" value={createdProduct.externalBillId} onChange={e => setCreatedProduct({...createdProduct, externalBillId: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input 
                                    classNamesContainer={styles.field}
                                    placeholder="Ожидаемая дата реализациим"
                                    type="date"
                                    value={createdProduct.realizeDate?.split('T')[0]}
                                    onChange={e => setCreatedProduct({...createdProduct, realizeDate: (new Date(e.target.value).toISOString())})}
                                    />
                            </div>
                            <TextArea
                                classNamesContainer={styles.note}
                                placeholder="Коментарий"
                                value={createdProduct.note}
                                onChange={e => setCreatedProduct({...createdProduct, note: e.target.value})}
                                />
                        </>
                    </SharedContainer>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            addedProducts?.map(el => (
                                <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                    <>
                                        <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={el.images != null && el.images.length != 0 ? el.images![0] : img} alt={el.name!} /> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.barcode} </div> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[2].width}>{el.name} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[3].width}>{el.color} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[4].width}>0</TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[5].width}><Input type="number" inlineStyles={{width: 63}} onChange={val => setAddedProducts(prevState => prevState.map(x => x.id == el.id ? {...x, quantity: Number(val.target.value)} : x))} value={el.quantity?.toString()}/></TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[6].width}>
                                            <div>
                                                {consumablesForAddedproducts.filter(x => x.pId == el.id && x.name != "").map(f => <div>{f.name}/{f.count} ед.</div>)}
                                            </div>
                                        </TableItemContainer> 
                                    </>
                                </TableItem>
                            ))
                        }
                    </Table>
                    <Modal isCloseCloseIcon isOpen={addProductModalVisible} classNames={styles.modalContainer} close={() => setAddProductModalVisible(prevState => !prevState)}>
                        <div style={{width: "100%"}}>
                            <TitleForm title="Выберите товары"/>
                            <div className={styles.searchContainer}>
                                <SearchInput
                                    value={modalSearch}
                                    onChange={e => setModalSearch(e)}
                                    classNames={styles.searchInput}
                                    />
                            </div>
                            <div style={{marginTop: 40}}>
                                {productsByUser?.map((product) => 
                                    <TableItem 
                                        onClick={() => {
                                            if (addedProducts.filter(product2 => product2.id == product.id).length > 0) {
                                                setAddedProducts(prevState => prevState.filter(el => el.id != product.id));
                                            }
                                            else {
                                                setAddedProducts(prevState => [...prevState, {...product, quantity: 0}]);
                                            }
                                        }}   
                                        classNames={clsx(addedProducts.filter(el => el.id == product.id).length != 0 ? styles.itemSelected : "", styles.hover)} 
                                        inlineStyles={{marginBottom: 10, width: 690, padding: 20}}
                                        >
                                        <div style={{width: "100%"}}>
                                            <div style={{display: "flex", marginBottom: 20}}>
                                                <img src={product.images?.length != 0 ? product.images![0] : img} alt={product.name!} className={styles.modalImg}/>
                                                <div>
                                                    <div className={styles.modalText}>Название: {product.name}</div>
                                                    <div className={styles.modalText}>Артикул: {product.article}</div>
                                                    <div className={styles.modalText}>Штрих-код: {product.barcode}</div>
                                                    <div className={styles.modalText}>Внешний штрих-код: {product.factoryBarcode}</div>
                                                    <div className={styles.modalText}>Остаток на складе: {product.quantity}</div>
                                                    <div>
                                                        Расходники:
                                                        {consumablesForAddedproducts.filter(x => x.pId == product.id).map(f => <div>{f.name}/{f.count} ед.</div>)}
                                                    </div>
                                                </div>
                                            </div>
                                            {consumables != null && consumables!.length > 0 ?
                                            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                                <div>
                                                    {consumablesForAddedproducts?.filter(c => c.pId == product.id)?.map((el, idx) => 
                                                        <div className={styles.kitConsumable}>
                                                            <Select classNames={styles.kitConsumableSelect} selectedOption={el.name} options={consumables!.map(x => x.name!)} onChange={(val) => {
                                                                const selectedSelect = consumablesForAddedproducts.find(c => c.pId == product.id && c.idx == el.idx)!;
                                                                const selectedCon = consumables?.find(a => a.name == val)!;
                                                                
                                                                setConsumablesForAddedproducts(prevState2 => prevState2.map(c => c!.pId == selectedSelect!.pId && c!.idx == selectedSelect!.idx ? ({...selectedSelect, selectedConsumableId: selectedCon!.id!, name: selectedCon!.name!}) : c));
                                                            }} placeholder={"Расходник " + (idx + 1)}/>
                                                            <Input classNamesContainer={styles.kitConsumableInput} placeholder="Кол-во" type="number" value={el.count.toString()}  onChange={(val) => {
                                                                const selectedSelect = consumablesForAddedproducts.find(c => c.pId == product.id && c.idx == el.idx)!;
                                                                
                                                                setConsumablesForAddedproducts(prevState2 => prevState2.map(c => c!.pId == selectedSelect!.pId && c!.idx == selectedSelect!.idx ? ({...selectedSelect, count: Number(val.target.value)}) : c));
                                                            }}/>
                                                        </div>)}
                                                </div>
                                                <Button text="Добавить расходник" onClick={() => setConsumablesForAddedproducts(prevState => [...prevState, {idx: (new Date()), pId: product!.id!, selectedConsumableId: 0, count: 0, name: ""}])}/>
                                            </div>: null}
                                        </div>
                                    </TableItem>
                                )}
                            </div>
                            <Button
                                className={styles.button}
                                text="Закрыть"
                                onClick={e => setAddProductModalVisible(false)}
                                />
                        </div>
                    </Modal>
                </>
            </Container>
        </Layout>
    );
};

export {
    AddingPackageBillPage,
};