import { ColumnHeaderType } from "widgets/Table";

export const tableHeadName: ColumnHeaderType[] = [
    {width: "15%", name: 'ID'},
    {width: "10%", name: 'Статус'},
    {width: "15%", name: 'Юр лицо'},
    {width: "15%", name: 'Склад'},
    {width: "10%", name: 'Создано'},
    {width: "25%", name: 'Комментарий'},
    {width: "10%", name: 'Скачать'},
];