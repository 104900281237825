import React, { useState } from "react";
import ProductsStorageModal from "pages/Modals/ProductsStorageModal";
import Container from "widgets/Container/ui";
import Modal from "shared/ui/Modal/Modal";
import styles from "./index.module.scss";
// import stylesm from "./index.m.module.scss";
import {Button} from "shared/ui/Button";
// import { Search } from "shared/ui/Search";
// import { BASE_URL } from "app/store/consts";
// import clsx from "clsx";
// import { ArModel, useGetProcessesQuery } from "../api/processesAPI";
import { tableHeadName } from "../consts";
import Layout from "widgets/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { getPercentColor } from "functions/color";
import { useGetProcessesGetProcessesBillQuery } from "api";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import Checkbox from "shared/ui/Checkbox/Checkbox";
import { LinkPagesEnum } from "app/store/enums/linkPages";

const options = ["Наименование", "Сотрудники", "ID", "Процент выполнения", "Начало", "Конец"];
const sortOptions = ["Наименование", "ID", "Процент выполнения", "Начало", "Конец"];

const ProcessesPage = () => {
    const [targetCellId, setTargetCellId] = useState<number | null>(null);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");

    const {data: processes, refetch} = useGetProcessesGetProcessesBillQuery();

    const [isOpen, setIsOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Наименование"]);

    const filteredData = processes?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.name, option: "Наименование"},
        {value: el.emps!.map(x => x?.name != null ? x.name : x.email!), option: "Сотрудники"},
        {value: el.id, option: "ID"},
        {value: el.percent, option: "Процент выполнения"},
        {value: el.dateStart, option: "Начало"},
        {value: el.dateEnd, option: "Конец"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("ID");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.name, value1: el1.name, option: "Наименование"},
        {value2: el2.id, value1: el1.id, option: "ID"},
        {value2: el2.percent, value1: el1.percent, option: "Процент выполнения"},
        {value2: el2.dateStart, value1: el1.dateStart, option: "Начало"},
        {value2: el2.dateEnd, value1: el1.dateEnd, option: "Конец"},
    ], selectedOption, isDesc));

    const [checkedCurrentProcesses, setCheckedCurrentProcesses] = useState(false);

    const filteredByCheckedCurrentProcesses = sortedData?.filter(el => checkedCurrentProcesses ? el.dateEnd == null : true);

    return (
        <Layout>
            <Container>
                <>
                    <div className={styles.header}>
                        <div style={{display: "flex"}}>
                            <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Checkbox 
                                    checked={checkedCurrentProcesses}
                                    handleChange={() => setCheckedCurrentProcesses(!checkedCurrentProcesses)}
                                    />
                                <div className={styles.checkboxText}>
                                    Текущие
                                </div>
                            </div>
                            <SearchInput 
                                value={searchText} 
                                onChange={setSearchText}
                                inlineStyles={{marginRight: 10}}
                                classNames={styles.searchInput}
                                />
                            <MultipleSelect 
                                text="Параметры"
                                options={options} 
                                selectedOptions={selectedOptions} 
                                setSelectedOptions={setSelectedOptions}
                                inlineStyles={{marginRight: 10}}
                                inlineClasses={styles.select}
                                />
                            <Sort 
                                options={sortOptions}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                setIsDesc={setIsDesc}
                                isDesc={isDesc}
                                selectClassNames={styles.select}
                                />
                        </div>
                    </div>
                    <Table columns={tableHeadName}>
                        {
                            filteredByCheckedCurrentProcesses?.map(el => (
                                <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => 
                                            {
                                                if (el.dateEnd == null) {
                                                    if ("Приёмка" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.InspectionProcess, search: "processId=" + el.id});
                                                    }
                                                    else if ("Отгрузка" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.ShipProcess, search: "processId=" + el.id});
                                                    }
                                                    else if ("Упаковка" == el.name) {
                                                        navigate({pathname: LinkPagesEnum.PackageProcess, search: "processId=" + el.id});
                                                    }
                                                }
                                            }
                                        }
                                    >
                                        <TableItemContainer width={tableHeadName[0].width}>{el.id}</TableItemContainer>
                                        <TableItemContainer width={tableHeadName[1].width}>{el.name}</TableItemContainer>
                                        <TableItemContainer width={tableHeadName[2].width}>
                                            <div>
                                                {el.emps?.map(x => <div className={styles.emp}>{x.email}</div>)}
                                            </div>
                                        </TableItemContainer>
                                        <TableItemContainer width={tableHeadName[3].width}><span className={styles.percentContainer} style={{backgroundColor: getPercentColor(el.percent!) }}>{el.percent}%</span></TableItemContainer>
                                        <TableItemContainer width={tableHeadName[4].width}>{new Date(el.dateStart!).toISOString().split('T')[0]}</TableItemContainer>
                                        <TableItemContainer width={tableHeadName[5].width}>{el.dateEnd && new Date(el.dateEnd).toISOString().split('T')[0]}</TableItemContainer>
                                </TableItem>
                            ))
                        }
                    </Table>
                    {/* <div className={clsx(styles.header, styles.pc)}>
                        {tableHeadName.map(el => (
                            <div className={styles.container} style={{width: el.width}}>
                                {el.title}
                            </div>
                        ))}
                    </div>
                    
                    <div className={clsx(styles.list, styles.pc)}>
                        {
                            data.map(el => (
                                <div className={styles.item}
                                    onClick={() => {
                                        if (el.dateEnd == null) {
                                            const url = el.name == "Приёмка" ? "/inspectionProcess?processId=" : "/shipProcess?processId=";
                                            navigate(url + el.id);
                                        }
                                    }}>
                                    <div style={{width: "15%"}}>
                                        {el.id}
                                    </div>
                                    <div style={{width: "15%"}}>
                                        {el.name}
                                    </div>
                                    <div style={{width: "20%"}}>
                                        {
                                            el.emps.map(x => <div>{x.employee.user.email}</div>)
                                        }
                                    </div>
                                    <div style={{width: "25%"}}>
                                        <span className={styles.percentContainer} style={{backgroundColor: getPercentColor(el.percent) }}>
                                            {el.percent}%
                                        </span>
                                    </div>
                                    <div style={{width: "10%"}}>
                                        {new Date(el.dateStart).toISOString().split('T')[0]}
                                    </div>
                                    <div style={{width: "10%"}}>
                                        {el.dateEnd && new Date(el.dateEnd).toISOString().split('T')[0]}
                                    </div>
                                </div>
                            ))
                        }
                    </div> */}

                    {/* <div className={clsx(styles.list, styles.mobile)}>
                        {
                            data.map(el => (
                                <div className={stylesm.item}
                                    onClick={() => {
                                        if (el.dateEnd == null) {
                                            const url = el.name == "Приёмка" ? "/inspectionProcess?processId=" : "/shipProcess?processId=";
                                            navigate(url + el.id);
                                        }
                                    }}>
                                    <div className={stylesm.margin}>
                                        <span>ID: </span>{el.id}
                                    </div>
                                    <div className={stylesm.margin}>
                                        <span>Наименование: </span>{el.name}
                                    </div>
                                    <div className={stylesm.margin}>
                                        <span style={{display: "block"}}>Сотрудники: </span>
                                        {
                                            el.emps.map(x => <div>{x.employee.user.email}</div>)
                                        }
                                    </div>
                                    <div className={stylesm.margin}>
                                        <span>Процент выполнения: </span>
                                        <span className={stylesm.percentContainer} style={{backgroundColor: getPercentColor(el.percent) }}>
                                            {el.percent}%
                                        </span>
                                    </div>
                                    <div className={stylesm.margin}>
                                        <span>Начало: </span>{new Date(el.dateStart).toISOString().split('T')[0]}
                                    </div>
                                    <div className={stylesm.margin}>
                                        <span>Конец: </span>{el.dateEnd && new Date(el.dateEnd).toISOString().split('T')[0]}
                                    </div>
                                </div>
                            ))
                        }
                    </div> */}
                    {targetCellId != null &&
                        <Modal 
                            setIsOpen={setIsOpen} 
                            isOpen={isOpen} 
                            toClose={'/addInvoices'}
                        >
                            <ProductsStorageModal closeModal={() => setIsOpen(false)} id={targetCellId} />
                        </Modal>}
                </>
            </Container>
        </Layout>
    )
};

export {
    ProcessesPage
};