import React, {ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch } from "../store/hooks/redux";
import { userSlice } from "../store/reducers/UserSlice";
import { useGetUsersMeQuery } from "api";

type Props = {
  children: ReactNode
}

const UserProvider = ({children}: Props) =>  {
    const [loader, setLoader] = useState<boolean>(true)
    const dispatch = useAppDispatch();
    const {setMe} = userSlice.actions
    const {data = null, isSuccess, isError} = useGetUsersMeQuery();

    useLayoutEffect(()=> {
      if (data != null){
        dispatch(setMe(data));
        setLoader(false);
      }
    },[data])

    useEffect(() =>{
        setLoader(!(isSuccess || isError))
    },[isSuccess, isError])

  return (
    <>
    {loader ? null : children}
    </>
  )
}

export default UserProvider;