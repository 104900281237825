import React from "react";
import styles from "./Title.module.scss";
import clsx from "clsx";
import { BaseSharedComponentType } from "types";

type Props = {
    children: React.ReactNode,
} & BaseSharedComponentType;

const Title = ({children, inlineClasses, inlineStyles}: Props) => (
    <h5 className={clsx(styles.title, inlineClasses)} style={inlineStyles}>{children}</h5>
);

export default Title;