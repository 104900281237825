import { FC } from "react";
import styles from "./TeminalCounterButton.module.scss"

type TeminalCounterButtonProps = {
    onClick : () => void,
    placeHolder: string
}

const TeminalCounterButton: FC<TeminalCounterButtonProps> =  ({onClick, placeHolder}) => {

    return <div onClick={onClick} className={styles.button}>
            {placeHolder}
    </div>
}

export {TeminalCounterButton};