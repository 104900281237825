export type FilterType = {
    option: string;
    value: string | undefined | null | number | string[];
}

export const filterDataEl = (searchText: string, selectedOptions: string[] | undefined | null, options: FilterType[]) => {
    if (searchText == "")
    {
        return true;
    }
    if (selectedOptions?.length == 0 || selectedOptions == null || selectedOptions == undefined)
    {
        return true;
    }

    for (let index = 0; index < selectedOptions.length; index++) {
        var optionValue = options.find(el => el.option == selectedOptions[index]);

        if (typeof optionValue?.value == "number") {
            if (optionValue != null && optionValue.value?.toString().startsWith(searchText))
            {
                return true;
            }
        }
        else if(Array.isArray(optionValue?.value)) {
            for (let index = 0; index < optionValue!.value.length; index++) {
                if (optionValue?.value[index].toLowerCase().startsWith(searchText)) 
                {
                    return true;
                }
            }
        }
        else if (optionValue != null && optionValue.value?.toLowerCase().startsWith(searchText))
        {
            return true;
        }
        
    }

    return false;
}
