import React, { useState } from "react";
import styles from './index.module.scss';
// import stylesm from './index_mobile.module.scss';
// import Title from "shared/ui/Title/Title";
// import ProductsForInvoiceCard from "widgets/ProductsForInvoiceCard";
// import { ReceivedProductCellType } from "../../../Placement/model";
import clsx from "clsx";
// import { SearchInputMobile } from "shared/ui/SearchInputMobile";
// import { Sort as MobileSort } from "shared/ui-mobile/Sort";
import { MultipleSelect } from "shared/ui/MultipleSelect";
// import { SelectedOptions } from "shared/ui-mobile/SelectedOptions";
// import { ItemMobile } from "./ItemMobile";
import { SearchInput } from "shared/ui/SearchInput";
import { Sort } from "shared/ui/Sort";
// import { Options } from "shared/ui/Options";
// import { CellProducts } from "./CellProducts";
import { useGetProductsReceivedQuery } from "api";
import { sortDataEl } from "functions/sort";
import { filterDataEl } from "functions/filter";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import { Container } from "shared/ui/Container";
import boxImg from "shared/assets/images/box.png";
import { Button } from "shared/ui/Button";

type PropsType = {
    clientId: number;
    closeModal: () => void;
};

const InventoryProductsModal = ({clientId, closeModal}: PropsType) => {
    const {data: productsByUser} = useGetProductsReceivedQuery();
    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Название"]);
    const [searchText, setSearchText] = useState("");
    const options = ["Название", "Артикул", "Заводской ШТ", "Штрихкод", "Остаток на складе"];

    const filteredData = productsByUser?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.product?.name, option: "Название"},
        {value: el.product?.article, option: "Артикул"},
        {value: el.product?.factoryBarcode, option: "Заводской ШТ"},
        {value: el.product?.barcode, option: "Штрихкод"},
        {value: el.allQuantitiesSum, option: "Остаток на складе"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("Количество");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.product?.name, value1: el1.product?.name, option: "Название"},
        {value2: el2.product?.article,  value1: el1.product?.article, option: "Артикул"},
        {value2: el2.product?.factoryBarcode, value1: el1.product?.factoryBarcode, option: "Заводской ШТ"},
        {value2: el2.product?.barcode, value1: el1.product?.barcode, option: "Штрихкод"},
        {value2: el2.allQuantitiesSum, value1: el1.allQuantitiesSum, option: "Остаток на складе"},
    ], selectedOption, isDesc));

    return (
        <>
            <div className={styles.container}>
                <TitleForm title="Товары клиента"/>
                <div className={styles.header}>
                    <SearchInput
                        classNames={styles.searchInput}
                        value={searchText}
                        onChange={setSearchText}
                        inlineStyles={{marginRight: 10, width: "52%"}}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptions={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10, width: 135}}
                        />
                    <Sort 
                        options={options}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setIsDesc={setIsDesc}
                        isDesc={isDesc}
                        selectClassNames={styles.sort}
                        />
                </div>
                <div>
                    {sortedData?.map(product => (
                        <Container inlineClasses={styles.product__container}>
                            <>
                                <img
                                    className={styles.img} 
                                    src={product.product?.imageLinks != null
                                        ? product.product!.imageLinks[0].image!.name!
                                        : boxImg} alt={product.product?.name ?? ""} 
                                    />
                                <div className={styles.textWrapper}>
                                    <div className={styles.text}>Наименование: {product.product?.name}</div>
                                    <div className={styles.text}>Артикул: {product.product?.article}</div>
                                    <div className={styles.text}>
                                        Заводской ШТ: {product.product?.barcode}
                                    </div>
                                    <div className={styles.text}>Внешний ШТ: {product.product?.factoryBarcode}</div>
                                    <div className={clsx(styles.quantity, styles.text)}>
                                        Остаток на складе: {product.allQuantitiesSum}
                                    </div>
                                </div>
                            </>
                        </Container>
                    ))}
                    {sortedData?.length == 0 && <div style={{textAlign: "center", margin: "120px 0 180px 0"}}>Нету элементов</div>}
                </div>
            </div>
            <Button text="Закрыть" onClick={closeModal} inlineStyles={{marginTop: 10}}/>
{/* 
        <div className={clsx(styles.productModal, styles.pc)}>
            <div className={styles.wrapperTitle}>
                <Title>Товары</Title>
            </div>
            <div style={{width: "100%"}} className={clsx(styles.search_storage)}>
                <div style={{display: "flex", marginBottom: "10px"}}>
                    <SearchInput
                        value={searchText} 
                        onChange={setSearchText}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptions={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10}}
                        />
                    <Sort 
                        options={options}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setIsDesc={setIsDesc}
                        isDesc={isDesc}
                        />
                </div>
                <Options 
                    selectedOptions={selectedOptions} 
                    setSelectedOptions={setSelectedOptions}
                    />
            </div>
            <div className={styles.wrapperCard}>
                <ProductsForInvoiceCard>
                    {sortedData.map(item => (
                        <CellProducts key={`${item.ownerId}-${item.barcode}`} item={item}/>
                    ))}
                </ProductsForInvoiceCard>
            </div>
        </div> */}
        </>
    )
};

export default InventoryProductsModal;