type Props = {
    checked: boolean,
    handleChange: () => void
}

const Checkbox = ({handleChange, checked}: Props) =>  (
    <input 
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        />
);

export default Checkbox;