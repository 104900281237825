import React from 'react';
import { useAppSelector } from '../../../app/store/hooks/redux';
import { ReactNode } from 'react';
import styles from './index.module.scss'
import { AlertType } from '../../../app/store/reducers/alertsSlice';
import clsx from 'clsx';

export type IAlertProps = {
    children: ReactNode
};

export const AlertModal = ({children}: IAlertProps) => {
    const errors = useAppSelector(state => state.alertsReducer);
    const alertType = (alertType : AlertType) => {
        switch(alertType) {
            case AlertType.Error:  
              return styles.error

              case AlertType.Ok:  
              return styles.ok

              case AlertType.Warning:  
              return styles.warning

            default:
                return styles.error
          }
    }

    return (
    <>
        {
            errors.length > 0 
            ? <div className={styles.container}>
                {
                    errors.map((el,index) => 
                        <div key={"alert" + index} className={clsx(styles.alertWindow, alertType(el.type))}>
                            {el.message}
                        </div>) 
                }
            </div>
            : null
        }
        {children}    
    </> 
  );
}
