import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserRoles } from "../consts";
import { UserModel } from "api";

export type UserState = {
    id: number,
    email: string,
    name: string,
    image: string,
    roles?: Array<string>,
    adminId: string | null,
    employeeId:  string | null,
    token: string | null
}

const initialState: UserState = {
    id: 0,
    email: '',
    name: '',
    image: '',
    roles: [],
    adminId: null,
    employeeId: null,
    token: localStorage.getItem("authToken"),
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setMe(state, action:PayloadAction<UserModel>){
            let me = {...action.payload} as UserState;
            me.roles = [];
            if(me.adminId != null){
                me.roles.push(UserRoles.adminRole)
            }
            if(me.employeeId != null){
                me.roles.push(UserRoles.employeeRole)
            }
            if(me.employeeId == null && me.adminId == null){
                me.roles.push(UserRoles.userRole)
            }
            me.token = state.token
            return {...me, } as UserState;
        },
        setToken(state, action:PayloadAction<string | null>){
            return {...state, token: action.payload} as UserState;
        },
    }
});

export default userSlice.reducer;