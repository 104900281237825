import styles from "./Settings.module.scss";
import Layout from "widgets/Layout/Layout";
import {Button} from "shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/store/hooks/redux";
import { userSlice } from "app/store/reducers/UserSlice";

const SettingsPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {setToken} = userSlice.actions
    
    const deleteToken = () => {
        localStorage.removeItem("authToken");
        dispatch(setToken(null))
        navigate('/authorization');
    };

    return (
        <Layout>
            <div className={styles.settings}>
                <div className={styles.exite}>
                    <Button onClick={() => deleteToken()} text={"Выход"}/>
                </div>
            </div>
        </Layout>
    )
};

export {SettingsPage};