import { useState } from "react";
import styles from "./UserProfile.module.scss";
import Layout from "widgets/Layout/Layout";
import { useAppSelector } from "app/store/hooks/redux";
import Title from "shared/ui/Title/Title";
import { UpdateUserProfile } from "./models";
import {Button} from "shared/ui/Button";
// import { useUpdateProfileMutation } from "./api/userProfileApi";
import { Input } from "shared/ui/Input";

const UserProfilePage = () => {
    const user = useAppSelector(state => state.userReducer)

    const [updateModel, setModel] = useState<UpdateUserProfile>({...user, password: null, image: null});

    // const handleSubmit = () => {
    //     var formData = new FormData();

    //     formData.append("email",updateModel.email!);
    //     formData.append("name",updateModel.name!);
    //     formData.append("password",updateModel.password!);

    //     updateProfile(formData)
    // }

    return (
        <Layout>
            <div className={styles.profilePage}>           
                <div className={styles.data}>
                    <Title>Профиль пользователя</Title>
                    <div className={styles.wrapper}>
                        <div className={styles.userCard}>
                            <div className={styles.imageWrapper}>
                                <img className={styles.userImage} src={user.image}/>
                            </div>
                            <div className={styles.formWrapper}>
                                <div className={styles.inputWrapper}>
                                    <Input onChange={e => setModel({...updateModel, email:e.target.value})} value={updateModel.email ?? ""} classNames={styles.info} placeholder={user.email}/>
                                    <label>Email</label>
                                </div>
                                <div className={styles.inputWrapper}>
                                    <Input onChange={e => setModel({...updateModel, name:e.target.value})} value={updateModel.name ?? ""} classNames={styles.info} placeholder={user.name}/>
                                    <label>Имя</label>
                                </div>
                                <div className={styles.inputWrapper}>
                                    <Input onChange={e => setModel({...updateModel, password:e.target.value})} value={updateModel.password ?? ""} classNames={styles.info} type="password" placeholder="Пароль"/>
                                    <label >Сменить пароль</label>
                                </div>
                            </div>
                        </div>
                        <Button onClick={() => {}} text={"Отправить"} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export { UserProfilePage };