import { ReactNode } from "react";
import styles from "./index.module.scss";

type Props = {
    children: ReactNode,
};

const Container = ({children}: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {children}
            </div>
        </div>
    )
}; 

export default Container;