import { createBrowserRouter, redirect, RouteObject, RouterProvider } from 'react-router-dom';
import { RegistrationPage } from './Registration/ui';
import { AuthorizationPage } from './Authorization/ui';
import { HomePage } from './Home/ui/Home';
import { DocumentsPage } from './Documents/ui';
import { AcceptanceBillsPage } from './AcceptanceBills/ui';
import { AddingAcceptanceBillPage } from './AddingAcceptanceBill/ui';
import { ProductsPage } from './Products/ui';
import { InventoryPage } from './Inventory/ui';
import { StoragePage } from './Storage/ui';
import { TerminalPage } from './Terminal/ui/Terminal';
import { InspectionPage } from './Inspection/ui/Inspection';
import { ShipmentBillsPage } from './ShipmentBills';
import { SettingsPage } from './Settings/ui/Settings';
import { useAppSelector } from '../app/store/hooks/redux';
import { UserProfilePage } from './Profile/UserProfile';
import { AddingShipmentBillPage } from './AddingShipmentBill';
import { PackagePage } from './Package';
import { ShipPage } from './Ship/Ship';
import { EmployeesPage } from './Employees';
import { UsersPage } from './Users';
import { SoonPage } from './Soon';
import { InformationPage } from './Information';
import { ProcessesPage } from './Processes/ui';
import { InspectionProcessPage } from './Inspection/pages/Process';
import { ShipProcessPage } from './Ship/pages/Process';
import { LinkPagesEnum } from 'app/store/enums/linkPages';
import { RedactAcceptanceBillPage } from './RedactAcceptanceBill/ui';
import { RedactShipmentBillPage } from './RedactShipmentBill/ui';
import { PaymentBillsPage } from './PaymentBills';
import { PaymentBillPage } from './PaymentBill';
import { AddingKitPage } from './AddingKit';
import { AddingPackageBillPage } from './AddingPackageBill';
import { PackageBillsPage } from './PackageBills';
import { AddingPlacementBillPage } from './AddingPlacementBill';
import { PlacementBillsPage } from './PlacementBills';
import { PackageProcessPage } from './Package/pages/Process';
import { PlacementPage } from './Placement';
import { PlacementProcessPage } from './Placement/pages/Process';

type RouteType = {
  pageName: string;
  routeObject: RouteObject
};

export const routes: RouteType[] = [
  {
    pageName: "Транзитные накладные",
    routeObject: {
      path: LinkPagesEnum.PlacementBills,
      element: <PlacementBillsPage />,
    }
  },
  {
    pageName: "Создание накладной перемещения",
    routeObject: {
      path: LinkPagesEnum.AddingPlacementBill,
      element: <AddingPlacementBillPage />,
    }
  },
  {
    pageName: "Упаковочные накладные",
    routeObject: {
      path: LinkPagesEnum.PackageBills,
      element: <PackageBillsPage />,
    }
  },
  {
    pageName: "Создание накладной упаковки",
    routeObject: {
      path: LinkPagesEnum.AddingPackageBill,
      element: <AddingPackageBillPage />,
    }
  },
  {
    pageName: "Создание комплекта",
    routeObject: {
      path: LinkPagesEnum.AddingKit,
      element: <AddingKitPage />,
    }
  },
  {
    pageName: "Счет",
    routeObject: {
      path: LinkPagesEnum.PaymentBill,
      element: <PaymentBillPage />,
    }
  },
  {
    pageName: "Счета",
    routeObject: {
      path: LinkPagesEnum.PaymentBills,
      element: <PaymentBillsPage />,
    }
  },
  {
    pageName: "Процесс отгрузки",
    routeObject: {
      path: LinkPagesEnum.ShipProcess,
      element: <ShipProcessPage />,
    }
  },
  {
    pageName: "Процесс приёмки",
    routeObject: {
      path: LinkPagesEnum.InspectionProcess,
      element: <InspectionProcessPage />,
    }
  },
  {
      pageName: "Регистрация",
      routeObject: {
        path: LinkPagesEnum.Registration,
        element: <RegistrationPage />,
      }
    },
    {
      pageName: "Информация",
      routeObject: {
        path: LinkPagesEnum.Information,
        element: <InformationPage />,
      }
    },
    {
      pageName: "Авторизация",
      routeObject: {
        path: LinkPagesEnum.Authorization,
        element: <AuthorizationPage />,
      }
    },
    {
      pageName: "Профиль",
      routeObject: {
        path: LinkPagesEnum.UserProfile,
        element:<UserProfilePage/>
      }
    },
    {
      pageName: "Главная",
      routeObject: {
        path: LinkPagesEnum.Home,
        element: <HomePage />,
      }
    },
    {
      pageName: "Отчёты",
      routeObject: {
        path: LinkPagesEnum.Documents,
        element: <DocumentsPage />,
      }
    },
    {
      pageName: "Отгрузка",
      routeObject: {
        path: LinkPagesEnum.Ship,
        element: <ShipPage />,
      }
    },
    {
      pageName: "Накладные на приход",
      routeObject: {
        path: LinkPagesEnum.AcceptanceBills,
        element: <AcceptanceBillsPage />,
      }
    },
    {
      pageName: "Накладные на отход",
      routeObject: {
        path: LinkPagesEnum.ShipmentBills,
        element: <ShipmentBillsPage />,
      }
    },
    {
      pageName: "Накладная на отход",
      routeObject: {
        path: LinkPagesEnum.RedactShipmentBill,
        element: <RedactShipmentBillPage />,
      }
    },
    {
      pageName: "Накладная на приход",
      routeObject: {
        path: LinkPagesEnum.RedactAcceptanceBill,
        element: <RedactAcceptanceBillPage />,
      }
    },
    {
      pageName: "Накладная на приход",
      routeObject: {
        path: LinkPagesEnum.AddingAcceptanceBill,
        element: <AddingAcceptanceBillPage />,
      }
    },
    {
      pageName: "Накладная на отход",
      routeObject: {
        path: LinkPagesEnum.AddingShipmentBill,
        element: <AddingShipmentBillPage />,
      }
    },
    {
      pageName: "Товары",
      routeObject: {
        path: LinkPagesEnum.Products,
        element: <ProductsPage />
      }
    },
    {
      pageName: "Инветаризация",
      routeObject: {
        path: LinkPagesEnum.Inventory,
        element: <InventoryPage />,
      }
    },
    {
      pageName: "Места хранения",
      routeObject: {
        path: LinkPagesEnum.Storage,
        element: <StoragePage />,
      }
    },
    {
      pageName: "Дашбоард",
      routeObject: {
        path: LinkPagesEnum.Terminal,
        element: <TerminalPage />,
      }
    },
    {
      pageName: "Приёмка",
      routeObject: {
        path: LinkPagesEnum.Inspection,
        element: <InspectionPage />,
      }
    },
    {
      pageName: "Настройки",
      routeObject: {
        path: LinkPagesEnum.Settings,
        element: <SettingsPage />,
      }
    },
    {
      pageName: "Перемещение",
      routeObject: {
        path: LinkPagesEnum.PlacementProcess,
        element: <PlacementProcessPage />,
      }
    },
    {
      pageName: "Перемещение",
      routeObject: {
        path: LinkPagesEnum.Placement,
        element: <PlacementPage />,
      }
    },
    {
      pageName: "Упаковка",
      routeObject: {
        path: LinkPagesEnum.Package,
        element: <PackagePage />,
      }
    },
    {
      pageName: "Упаковка",
      routeObject: {
        path: LinkPagesEnum.PackageProcess,
        element: <PackageProcessPage />,
      }
    },
    {
      pageName: "Сотрудники",
      routeObject: {
        path: LinkPagesEnum.Employees,
        element: <EmployeesPage />,
      }
    },
    {
      pageName: "Пользователи",
      routeObject: {
        path: LinkPagesEnum.Users,
        element: <UsersPage/>,
      }
    },
    {
      pageName: "Скоро",
      routeObject: {
        path: LinkPagesEnum.Soon,
        element: <SoonPage/>,
      }
    },
    {
      pageName: "Процессы",
      routeObject: {
        path: LinkPagesEnum.Processes,
        element: <ProcessesPage/>,
      }
    }
];

const Pages = () => {
  const user = useAppSelector(state => state.userReducer)

  const browserRouterUnAuthLoader = (to:string) => {
    const userAuthorized = user.token;
      if(!userAuthorized){
        return redirect("/information")
      }
      return {}
  }

  const browserRouterAuthLoader = () =>{
    const userAuthorized = user.token;
    console.log(user)
    if(userAuthorized){
      return redirect("/")
    }
    return {}
  }

  const router = createBrowserRouter(routes.map( el => 
    {
      if(el.routeObject.path != "/authorization" && el.routeObject.path != "/registration" && el.routeObject.path != "/information"){
        el.routeObject.loader = () => browserRouterUnAuthLoader(el.routeObject.path!)
      }
      else{
        el.routeObject.loader = () => browserRouterAuthLoader()
      }
  
      return el.routeObject
    }));

  return <RouterProvider router={router} />;
};

export default Pages; 