import clsx from "clsx";
import { CSSProperties, useRef, useState } from "react";
import styles from "./index.module.scss";

type PropsType = {
    classNames?: string;
    classNamesContainer?: string;
    inlineStyles?: CSSProperties;
    inlineStylesContainer?: CSSProperties;
    value: string | null | undefined;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
}

const TextArea = ({classNames, onChange, value, inlineStyles, placeholder, classNamesContainer, inlineStylesContainer}: PropsType) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const [isFocus, setIsFocus] = useState(false);
    
    return (
        <div className={clsx(styles.container, isFocus && styles.inputFocus, isFocus || value ? styles.inputValue : "", classNamesContainer)} style={inlineStylesContainer}>
            {value != null && value?.length != 0 || isFocus ? <div className={clsx(styles.placeholder)}>{placeholder}</div> : ""}
            <textarea 
                className={clsx(styles.input, classNames)} 
                style={inlineStyles} 
                value={value ? value : ""}
                onChange={onChange}
                placeholder={!isFocus ? placeholder : ""}
                ref={ref}
                onFocus={e => setIsFocus(true)}
                onBlur={e => setIsFocus(false)}
                />
        </div>
    );
};

export {
    TextArea,
};