import { DifNotification } from "api";
import { Close2Icon } from "shared/ui/icons/icons";
import styles from "./index.module.scss";
import { BaseSharedComponentType } from "types";
import clsx from "clsx";
import { getMinutesBetweenDates } from "functions/time";

type PropsType = {
    notifications: DifNotification[];
    close: () => void;
} & BaseSharedComponentType;

const Notifications = ({notifications, inlineClasses, inlineStyles, close}: PropsType) => {
    const differentInDates = (minutes: number) => {
        if (minutes < 60) {
            return `${minutes} мин`;
        }
        else if (minutes > 60 && minutes < (24 * 60)) { 
            const hours = Math.floor(minutes / 60);
            return `${hours} ч`;
        }
        else {
            const days = Math.floor(minutes / (24 * 60));
            return `${days} д`;
        }
    }
    return (
        <div className={clsx(styles.notifications, inlineClasses)} style={inlineStyles}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Уведомления
                </div>
                <Close2Icon inlineStyles={{cursor: "pointer"}} onClick={close}/>
            </div>
            <div className={styles.stick}/>
            <div>
                {notifications?.map(notification =>(
                    <div className={styles.notification}>
                        <div className={styles.text}>
                            {notification.text}
                        </div>
                        <div>
                            <div className={styles.time}>
                                {differentInDates(getMinutesBetweenDates(new Date(notification.objectCreateDate!), new Date()))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export {
    Notifications
};