import Container from "widgets/Container/ui";
import Layout from "widgets/Layout/Layout";

const SoonPage = () => (
  <Layout>
    <Container>
      <div style={{display: "flex", width: "100%", height: "calc(100vh - 160px)", justifyContent: "center", alignItems: "center"}}>
        Скоро будет добавлено !
      </div>
    </Container>
  </Layout>
);

export { SoonPage };