import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum AlertType{
    Error,
    Ok,
    Warning
}

export type AlertMessage = {
    title: string,
    message: string | null,
    type: AlertType,
    identifier : string,
}

const initialState: Array<AlertMessage> = [];

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlert(state, action: PayloadAction<AlertMessage>) {
           return [...state, action.payload];
        },
        removeAlert(state, action: PayloadAction<string>) {
            return [...state].filter(el => el.identifier != action.payload);
        }
    }
});

export default alertsSlice.reducer;