import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/UserSlice";
import pageNameReducer from "processes/pageName";
import alertsReducer from "./reducers/alertsSlice"
import { rtkQueryAlertMiddleware } from "../api/alertMiddleware";
import { enhanceEndpointsBaseApi } from "api/enhanceEndpoints";

const rootReducer = combineReducers({
    userReducer,
    pageNameReducer,
    alertsReducer,
    [enhanceEndpointsBaseApi.reducerPath]: enhanceEndpointsBaseApi.reducer,
});

export const setupStore = () => {
    return configureStore ({
        reducer: rootReducer,
        middleware: (getDefaultMiddlware) => getDefaultMiddlware()
        .concat(
            enhanceEndpointsBaseApi.middleware,
        )
        .concat(rtkQueryAlertMiddleware),
    })
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];