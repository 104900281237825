import { Link } from "react-router-dom";
import { Button } from "../Button";
import { CSSProperties } from "react";

type Props = {
    text: string,
    to?: string,
    inlineStyles?: CSSProperties;
    classNames?: string;
};

const ButtonNav = ({text, to = "", inlineStyles, classNames}: Props) => {

    return (
        <Link to={to}>
            <Button inlineStyles={inlineStyles} className={classNames} onClick={() => {}} text={text}/>
        </Link>
    );
};

export default ButtonNav;