import { CSSProperties, ReactNode, useEffect } from "react";
import styles from "./Modal.module.scss";
import { CloseIcon } from "../icons/icons";
import clsx from "clsx";

type Props = {
    children: ReactNode,
    title?: string | null,
    toClose?: string,
    isOpen?: boolean,
    setIsOpen?: (isOpen: boolean) => void,
    close?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isCloseCloseIcon?: boolean,
    inlineStyles?: CSSProperties;
    classNames?: string;
};

const Modal = ({setIsOpen, classNames, inlineStyles, children, close, isOpen, isCloseCloseIcon} : Props) => {

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") 
            {
                setIsOpen!(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handlerClose = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsOpen!(false);
    };

    // todo 
    return (
        <div className={isOpen ? styles.modalWrapper : styles.modalClose} onClick={close}>
            <div onClick={e => e.stopPropagation()} className={clsx(styles.modal, styles.scroll, classNames)} style={inlineStyles}>
                {!isCloseCloseIcon &&
                <div className={styles.header}>
                    <div className={styles.close} onClick={(event) => handlerClose(event)}>
                        <CloseIcon/>
                    </div>
                </div>
                }
                {children}
            </div>
        </div>
    );
};

export default Modal;