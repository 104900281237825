import React, { useState } from "react";
import styles from "./index.module.scss";
import { TerminalScan } from "widgets/TerminalScan";
import Layout from "widgets/Layout/Layout";
import {Container as SharedContainer} from "shared/ui/Container";
import Container from "widgets/Container/ui";
import { Button } from "shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetBillsPackageQuery, useGetStoragePlacesQuery, usePostProcessesPackageAddProcessMutation } from "api";

enum StageEnum {
    Zero = 0, 
    First = 1,
    Second = 2,
}

const PackagePage = () => {
    const [isFocus, setIsFocus] = useState(true);
    const onChange = (val: string) => {
        console.log('onChange' + val);
    }

    const [stage, setStage] = useState(StageEnum.Zero);
    const navigate = useNavigate();

    const {data: packageBills} = useGetBillsPackageQuery();
    const {data: packageCells} = useGetStoragePlacesQuery({warehouseId: 1, cellsType: 2});


    const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
    
    const [createProcess] = usePostProcessesPackageAddProcessMutation();
    const createProcesshandler = async (selectedCellId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
                cellId: selectedCellId
            }).unwrap();

            navigate({pathname: LinkPagesEnum.PackageProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Layout>
                <Container>
                    {stage == StageEnum.Zero &&
                    (
                        <div className={styles.container}>
                            <Button
                                text="На главную"
                                onClick={() => navigate(LinkPagesEnum.Home)}
                                />
                            <div className={styles.title}>
                                Выберите накладную
                            </div>
                            <div className={styles.billsContainer}>
                                {
                                    packageBills?.map(pBill => 
                                        <SharedContainer inlineClasses={styles.listContainer} onClick={() => {setStage(StageEnum.First); setSelectedBillId(pBill.id!)}}>
                                            Накладная с Id = {pBill.id}
                                        </SharedContainer>
                                    )
                                }
                            </div>
                        </div>
                    )
                    }
                    {stage == StageEnum.First &&
                    (
                        <div className={styles.container}>
                            <Button
                                text="На главную"
                                onClick={() => navigate(LinkPagesEnum.Home)}
                                />
                            <div className={styles.title}>
                                Выберите ячейку упаковки
                            </div>
                            <div className={styles.billsContainer}>
                                {
                                    packageCells?.map(packageCell => 
                                        <SharedContainer inlineClasses={styles.listContainer} onClick={() => {
                                            createProcesshandler(packageCell.id!);
                                        }}>
                                            Ячейка {packageCell.id}
                                        </SharedContainer>
                                    )
                                }
                            </div>
                        </div>
                    )
                    }
                </Container>
            </Layout>
            <TerminalScan
                inlineClasses={styles.scanInput}
                isFocus={isFocus}
                onChange={onChange}
                />
        </>
    )
};

export {PackagePage};