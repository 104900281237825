import { UserRoles } from "app/store/consts";
import { LinkPagesEnum } from "app/store/enums/linkPages";

export type MenuItemType = {
    name: string, 
    path: string, 
    subItem?: Array<MenuItemType>,
    iconPath?: string,
    allowedFor?: Array<string>
};
export type MenuItemsType = Array<MenuItemType>;
export const menuItems: MenuItemsType = [
    {name: "Главная", path: LinkPagesEnum.Home, iconPath:"assets/icons/menu_home.svg"},
    {name: "Дашборд", path: LinkPagesEnum.Terminal, iconPath:"assets/icons/menu_dashboard.png", allowedFor: [UserRoles.employeeRole]},
    {name: "Накладные", path: '', subItem: [
        {name: "Приходные накладные", path: LinkPagesEnum.AcceptanceBills},
        {name: "Накладные на отгрузку", path: LinkPagesEnum.ShipmentBills},
        {name: "Накладные на упаковку", path: LinkPagesEnum.PackageBills},
        {name: "Накладные на перемещение", path: LinkPagesEnum.PlacementBills, allowedFor : [UserRoles.employeeRole]},
    ], iconPath:"assets/icons/menu_bills.svg"},
    {name: "Инвентаризация", path: '', subItem: [
        {name: "Товар", path: LinkPagesEnum.Inventory},
        {name: "Расходники", path: LinkPagesEnum.Soon}
    ], iconPath:"assets/icons/menu_inventory.png",
    allowedFor : [UserRoles.employeeRole]},
    {name: "Товары", path: LinkPagesEnum.Products, iconPath:"assets/icons/menu_goods.svg"},
    {name: "Места хранения", path: LinkPagesEnum.Storage, iconPath:"assets/icons/menu_storage.png", allowedFor : [UserRoles.employeeRole]},
    {name: "Процессы", path: LinkPagesEnum.Processes, iconPath:"assets/icons/menu_storage.png", allowedFor : [UserRoles.employeeRole]},
    {name: "Документы", path: '', subItem: [
        {name: "Счета", path: LinkPagesEnum.Soon},
        {name: "Отчеты", path: LinkPagesEnum.Documents}
    ], iconPath:"assets/icons/menu_documents.svg"},
    {name: "Интеграции", path: '', subItem: [
        {name: "Маркетплейсы", path: LinkPagesEnum.Soon},
        {name: "Транспортные компании", path: LinkPagesEnum.Soon},
        {name: "Системы учета", path: LinkPagesEnum.Soon}
    ], iconPath:"assets/icons/menu_integration.svg"},
    {name: "Сотрудники", path: '', iconPath:"assets/icons/employees.svg", subItem: [
        {name: "Аккаунты", path: LinkPagesEnum.Employees},
        {name: "График", path: LinkPagesEnum.Soon},
        {name: "Оплата", path: LinkPagesEnum.Soon},
    ],   allowedFor : [UserRoles.adminRole]},
    {name: "Счета", path: LinkPagesEnum.PaymentBills },
    {name: "Пользователи", path: LinkPagesEnum.Users, iconPath:"assets/icons/users.svg", allowedFor : [UserRoles.adminRole, UserRoles.employeeRole]},
    {name: "Настройки", path: LinkPagesEnum.Settings, iconPath:"assets/icons/menu_settings.svg"},
];