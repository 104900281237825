import React, { useEffect, useState, useRef } from "react";
import { tableHeadName } from "pages/Products/consts";
import { useAppSelector } from "app/store/hooks/redux";
import Modal from "shared/ui/Modal/Modal";
import { IS_PC } from "app/store/consts";
import styles from "./index.module.scss";
import boxImg from "shared/assets/images/box.png";
import { Sort } from "shared/ui/Sort";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { SearchInput } from "shared/ui/SearchInput";
import {Button} from "shared/ui/Button";
import Container from "widgets/Container/ui";
import Layout from "widgets/Layout/Layout";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { Input } from "shared/ui/Input";
import { ProductCreateModel, ProductOutputModel, useDeleteProductsImageMutation, useGetProductsQuery, useGetUsersUserOrganizationsQuery, usePostProductsImageMutation, usePostProductsMutation, usePostProductsRedactMutation } from "api";
import { Select } from "shared/ui/Select";
import fileUploadImg from "shared/assets/images/file-upload.png";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import ImportButton from "features/ImportButton/ImportButton";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { ImageEditIcon, ImageRemoveIcon } from "shared/ui/icons/icons";

type Props = {
};

const ProductsPage = ({}:Props) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Наименование"]);
    const [selectedProductForRedact, setSelectedProductForRedact] = useState<ProductOutputModel>({});
    const {id: userId} = useAppSelector(state => state.userReducer);
    
    const {data: products, refetch} = useGetProductsQuery({});
    const [addProduct] = usePostProductsMutation();
    const [isRedactModalOpen, setIsRedactModalOpen] = useState(false);

    const options = ["Штрихкод", "Наименование", "Цвет", "Размер", "Цена услуги", "Количество", "Юр лицо"];

    const filteredData = products?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.barcode, option: "Штрихкод"},
        {value: el.name, option: "Наименование"},
        {value: el.color, option: "Цвет"},
        {value: el.color, option: "Размер"},
        {value: el.serviceProductPrice, option: "Цена услуги"},
        {value: el.quantity, option: "Количество"},
        {value: el.organizationName, option: "Юр лицо"}
    ]));
    
    const [selectedOption, setSelectedOption] = useState("Количество");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.barcode, value1: el1.barcode, option: "Штрихкод"},
        {value2: el2.name,  value1: el1.name, option: "Наименование"},
        {value2: el2.color, value1: el1.color, option: "Цвет"},
        {value2: el2.color, value1: el1.color, option: "Размер"},
        {value2: el2.serviceProductPrice, value1: el1.serviceProductPrice, option: "Цена услуги"},
        {value2: el2.quantity, value1: el1.quantity, option: "Количество"},
        {value2: el2.organizationName, value1: el1.organizationName, option: "Юр лицо"}
    ], selectedOption, isDesc));
   
    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string>("");
  
    const [isAddProductModalVisible, setIsAddProductModalVisible] = useState(false);
    const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files as FileList;
        setCurrentImage(selectedFiles?.[0]);
        setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
    };

    const [createdProduct, setCreatedProduct] = useState<ProductCreateModel>({});
    const {data: organizations} = useGetUsersUserOrganizationsQuery({userId: userId});

    useEffect(()=> {
        if (organizations != null) 
        {
            setCreatedProduct({...createdProduct, organizationId: organizations[0].id!});
        }
    }, [organizations]);

    const ref = useRef<HTMLInputElement>(null);

    const [createImage] = usePostProductsImageMutation();

    const addProductHandler = async () => {
        try {
            const productId = await addProduct({
                productCreateModel: createdProduct
            }).unwrap();

            await createImage({body: {
                ProductId: productId,
                File: currentImage,
            }})

            setIsAddProductModalVisible(false);
            setCreatedProduct({});
            refetch();
        } catch (err) {
            // Handle error
        }
    };

    const [redactProduct] = usePostProductsRedactMutation();

    const redactProductHandler = async () => {
        try {
            await redactProduct({
                productRedactModel: {
                    id: selectedProductForRedact.id,
                    serviceProductPrice: selectedProductForRedact.serviceProductPrice ?? undefined,
                    organizationId: organizations!.find(x => x.name == selectedProductForRedact.organizationName)!.id,
                    name: selectedProductForRedact.name ?? undefined,
                    factoryBarcode: selectedProductForRedact.factoryBarcode ?? undefined,
                    article: selectedProductForRedact.article ?? undefined,
                    color: selectedProductForRedact.color ?? undefined,
                    barcode: selectedProductForRedact.barcode ?? undefined,
                }
            }).unwrap();

            await createImage({body: {
                ProductId: selectedProductForRedact.id,
                File: currentImage,
            }})

            setIsRedactModalOpen(false);
            setSelectedProductForRedact({});
            refetch();
        } catch (err) {
            // Handle error
        }
    };
    // const [importProducts] = usePostProductsExcelMutation();
    const [deleteImage] = useDeleteProductsImageMutation();

    if (IS_PC) {
        return (
            <Layout>
                <Container>
                    <div style={{display: 'flex', justifyContent:'space-between', marginBottom: 20}}>
                        <div style={{display: "flex"}}>
                            <Button
                                inlineStyles={{marginRight: 10}}
                                text="Обновить"
                                onClick={refetch}
                                />
                            {/* <ImportButton
                                addFile={file => importProducts({body: {File: file}})}
                                text="Импорт"
                                inlineStyles={{marginRight: 10}}
                                /> */}
                            <Button inlineStyles={{marginRight: 10}}  text="Добавить товар" onClick={() => setIsAddProductModalVisible(true)}/>
                            <Button  text="Добавить комплект" onClick={() => navigate(LinkPagesEnum.AddingKit)}/>
                            {/* <Button text="Экспорт" onClick={() => window.open("http://185.211.170.95/api/media/productsTemplate.xlsx","_blank")}/> */}
                            {/* <Button text="Скачать шаблон" onClick={() => window.open("http://185.211.170.95/api/media/productsTemplate.xlsx","_blank")}/> */}
                        </div>
                        <div style={{display: 'flex'}}>
                            <SearchInput 
                                value={searchText} 
                                onChange={setSearchText}
                                inlineStyles={{marginRight: 10}}
                                classNames={styles.searchInput}
                                />
                            <MultipleSelect 
                                text="Параметры"
                                options={options} 
                                selectedOptions={selectedOptions} 
                                setSelectedOptions={setSelectedOptions}
                                inlineStyles={{marginRight: 10, width: 135}}
                                />
                            <Sort 
                                options={options}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                setIsDesc={setIsDesc}
                                isDesc={isDesc}
                                selectClassNames={styles.select}
                                />
                        </div>
                    </div>
                        <Table columns={tableHeadName}>
                            {sortedData?.length != 0 ?
                                sortedData?.map(el => (
                                    <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {setSelectedProductForRedact(el); setIsRedactModalOpen(true)}}>
                                        <>
                                            <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={el.images != null && el.images!.length != 0 ? el.images![0] : boxImg} alt={el.name!} /> </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.barcode} </div> </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[2].width}>{el.name} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[3].width}>{el.color} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[4].width}>0 </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[5].width}>{el.serviceProductPrice ? el.serviceProductPrice : 0} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[6].width}>{el.quantity ? el.quantity : 0} </TableItemContainer> 
                                            <TableItemContainer width={tableHeadName[7].width}>{el.organizationName} </TableItemContainer> 
                                        </>
                                    </TableItem>
                            ))
                            :  <></>
                        }
                        </Table>
                        <Modal isCloseCloseIcon isOpen={isAddProductModalVisible} close={() => setIsAddProductModalVisible(false)}>
                            <div className={styles.modal}>
                                <Button onClick={() => setIsAddProductModalVisible(false)} text="Закрыть"/>
                                <div className={styles.modal__container}>
                                    {previewImage == "" 
                                        ?
                                            <div className={styles.modal__selectImg} onClick={e => ref.current?.click()}>
                                                <img src={fileUploadImg}/>
                                                <input ref={ref} className={styles.modal__fileUploadImg} type="file" accept="image/*" onChange={selectImage} />
                                            </div>
                                        :
                                        <div className={styles.modal__imgContainer}>
                                            <img src={previewImage} alt=""  className={styles.modal__img}/>
                                            <ImageRemoveIcon onClick={async () => {
                                                    setCurrentImage(undefined);
                                                    setPreviewImage("");
                                                }} inlineClass={styles.modal__imgFileIcon}  inlineStyles={{right: "55px"}}/>
                                            <ImageEditIcon onClick={e => ref.current?.click()} inlineClass={styles.modal__imgFileIcon} inlineStyles={{right: "20px"}}/>
                                            <input ref={ref} className={styles.modal__fileUploadImg} type="file" accept="image/*" onChange={selectImage} />
                                        </div>}
                                    <div className={styles.modal__fieldsContainer}>
                                        <Input placeholder="Название" value={createdProduct.name} onChange={e => setCreatedProduct({...createdProduct, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Артикул" value={createdProduct.article} onChange={e => setCreatedProduct({...createdProduct, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Цвет" value={createdProduct.color} onChange={e => setCreatedProduct({...createdProduct, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Внешний баркод" value={createdProduct.factoryBarcode} onChange={e => setCreatedProduct({...createdProduct,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Заводской баркод" value={createdProduct.barcode} onChange={e => setCreatedProduct({...createdProduct, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Цена обслуживания" value={createdProduct.serviceProductPrice?.toString()} onChange={e => setCreatedProduct({...createdProduct, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Select placeholder="Организация" options={organizations?.map(el => el.name!) ?? []} selectedOption={organizations?.find(x => x.id == createdProduct.organizationId)?.name} onChange={val => setCreatedProduct({...createdProduct, organizationId: organizations?.find(x => x.name == val)?.id})} classNames={styles.modal__field}/>
                                    </div>
                                </div>
                                <Button onClick={addProductHandler} text="Добавить товар"/> 
                            </div>
                        </Modal>
                        <Modal isCloseCloseIcon isOpen={isRedactModalOpen} close={() => setIsRedactModalOpen(false)}>
                            <div className={styles.modal}>
                                <Button onClick={() => setIsRedactModalOpen(false)} text="Закрыть"/>
                                <div className={styles.modal__container}>
                                    {(selectedProductForRedact.images == null || selectedProductForRedact.images?.length == 0) && previewImage == ""
                                        ?
                                            <div className={styles.modal__selectImg} onClick={e => ref.current?.click()}>
                                                <img src={fileUploadImg}/>
                                                <input ref={ref} className={styles.modal__fileUploadImg} type="file" accept="image/*" onChange={selectImage} />
                                            </div>
                                        :
                                            <div className={styles.modal__imgContainer}>
                                                <ImageRemoveIcon onClick={async () => {
                                                    await deleteImage({deleteProductImageInput: {productId: selectedProductForRedact.id}});
                                                    setCurrentImage(undefined);
                                                    setPreviewImage("");
                                                }} inlineClass={styles.modal__imgFileIcon}  inlineStyles={{right: "55px"}}/>
                                                <ImageEditIcon onClick={e => ref.current?.click()} inlineClass={styles.modal__imgFileIcon} inlineStyles={{right: "20px"}}/>
                                                <img src={previewImage} alt="" className={styles.modal__img}/>
                                                <input className={styles.modal__fileUploadImg} type="file" accept="image/*" onChange={selectImage} ref={ref}/>
                                            </div>}
                                    <div className={styles.modal__fieldsContainer}>
                                        <Input placeholder="Название" value={selectedProductForRedact.name} onChange={e => setSelectedProductForRedact({...selectedProductForRedact, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Артикул" value={selectedProductForRedact.article} onChange={e => setSelectedProductForRedact({...selectedProductForRedact, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Цвет" value={selectedProductForRedact.color} onChange={e => setSelectedProductForRedact({...selectedProductForRedact, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Внешний баркод" value={selectedProductForRedact.factoryBarcode} onChange={e => setSelectedProductForRedact({...selectedProductForRedact,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Заводской баркод" value={selectedProductForRedact.barcode} onChange={e => setSelectedProductForRedact({...selectedProductForRedact, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Input placeholder="Цена обслуживания" value={selectedProductForRedact.serviceProductPrice?.toString()} onChange={e => setSelectedProductForRedact({...selectedProductForRedact, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                        <Select placeholder="Организация" options={organizations?.map(el => el.name!) ?? []} selectedOption={selectedProductForRedact.organizationName} onChange={val => setSelectedProductForRedact({...selectedProductForRedact, organizationName: val})} classNames={styles.modal__field}/>
                                    </div>
                                </div>
                                <Button onClick={redactProductHandler} text="Редактировать товар"/> 
                            </div>
                        </Modal>
                    {/* <Modal
                        setIsOpen={() => dispatch(changeStatusModal(false))} 
                        isOpen={isOpen} 
                        toClose={'/goods'} 
                        modalWidth={'900px'} 
                        modalHeight={'400px'}
                    >
                        <CreateProductModal targetProduct={targetProduct} setTargetProduct={setTargetProduct} />
                    </Modal>   
                    <Modal 
                        setIsOpen={() => setIsRedactModalOpen(false)} 
                        isOpen={isRedactModalOpen} 
                        toClose={'/goods'} 
                        modalWidth={'900px'} 
                        modalHeight={'400px'}
                    >
                        <RedactProductModal
                            targetProduct={targetProduct}
                            setTargetProduct={setTargetProduct}
                        />
                    </Modal>      */}
                </Container>
            </Layout>
        );
    }
    return (
        <div></div>
    );
    
};

export {ProductsPage};