import Layout from "widgets/Layout/Layout";
import React, { useEffect, useState } from "react";
import { tableHeadName } from "pages/AcceptanceBills/consts";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import {Button} from "shared/ui/Button";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import Container from "widgets/Container/ui";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { Table } from "widgets/Table";
import { getBillStatus } from "functions/billStatus";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useGetBillsShipmentQuery, useGetDocumentsExcelBillsQuery } from "api";
import { FileExportIcon } from "shared/ui/icons/icons";
import { BASE_URL_FILES } from "app/store/consts";

const ShipmentBillsPage = () => {
    
    const navigate = useNavigate();
    const {data: bills, refetch} = useGetBillsShipmentQuery();

    const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
    const {data: document} = useGetDocumentsExcelBillsQuery({billId: selectedBillId!}, {skip: selectedBillId == null});

    useEffect(() => {
        if (document != null)
        {
            window.open(BASE_URL_FILES + document.name, '_blank');
        }
    }, [document]);

    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["ID"]);
    const [searchText, setSearchText] = useState("");

    const options = ["ID", "Статус", "Юр лицо", "Склад", "Создано", "Комментарий"];

    const filteredData = bills?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.id, option: "ID"},
        {value: el.status, option: "Статус"},
        {value: el.organizationName, option: "Юр лицо"},
        {value: el.warehouseName, option: "Склад"},
        {value: el.objectCreateDate, option: "Создано"},
        {value: el.quantity, option: "Комментарий"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("ID");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.id, value1: el1.id, option: "ID"},
        {value2: el2.status,  value1: el1.status, option: "Статус"},
        {value2: el2.organizationName, value1: el1.organizationName, option: "Юр лицо"},
        {value2: el2.warehouseName, value1: el1.warehouseName, option: "Склад"},
        {value2: el2.objectCreateDate, value1: el1.objectCreateDate, option: "Создано"},
        {value2: el2.quantity, value1: el1.quantity, option: "Комментарий"},
    ], selectedOption, isDesc));
    
    return (
        <Layout>
            <>
                <Container>
                    <div className={styles.header}>
                        <div style={{display: "flex"}}>
                            <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                            <Button inlineStyles={{marginRight: 10}} text="Добавить накладную" onClick={() => navigate(LinkPagesEnum.AddingShipmentBill)}/>
                            <Button text="Скачать шаблон" onClick={() => window.open(BASE_URL_FILES + "productsTemplate.xlsx","_blank")}/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <SearchInput 
                                value={searchText} 
                                onChange={setSearchText}
                                inlineStyles={{marginRight: 10}}
                                classNames={styles.searchInput}
                                />
                            <MultipleSelect 
                                text="Параметры"
                                options={options} 
                                selectedOptions={selectedOptions} 
                                setSelectedOptions={setSelectedOptions}
                                inlineStyles={{marginRight: 10}}
                                inlineClasses={styles.select}
                                />
                            <Sort 
                                options={options}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                setIsDesc={setIsDesc}
                                isDesc={isDesc}
                                selectClassNames={styles.select}
                                />
                        </div>
                    </div>
                    <Table columns={tableHeadName}>
                        {
                            sortedData?.map(el => (
                                <TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => navigate(`${LinkPagesEnum.RedactShipmentBill}?billId=${el.id!}`)}>
                                    <TableItemContainer width={tableHeadName[0].width}>{el.id}</TableItemContainer>
                                    <TableItemContainer width={tableHeadName[1].width}>{getBillStatus(el.status!)}</TableItemContainer>
                                    <TableItemContainer width={tableHeadName[2].width}>{el.organizationName}</TableItemContainer>
                                    <TableItemContainer width={tableHeadName[3].width}>{el.warehouseName}</TableItemContainer>
                                    <TableItemContainer width={tableHeadName[4].width}>{new Date(el.objectCreateDate!).toLocaleString()}</TableItemContainer>
                                    <TableItemContainer width={tableHeadName[5].width}>{el.note}</TableItemContainer>
                                    <TableItemContainer width={tableHeadName[6].width}><FileExportIcon onClick={e => {
                                    e.stopPropagation();
                                    setSelectedBillId(el.id!);
                                }}/></TableItemContainer>
                                </TableItem>
                            ))
                        }
                    </Table>
                </Container>
            </>
        </Layout>    
    )
};

export { ShipmentBillsPage };