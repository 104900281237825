import styles from "./InspectionProduct.module.scss"
import {TeminalCounterButton} from "./components/TeminalCounterButton"

type Props = {
    name : string,
    imgSrc: string,
    article: string,
    toCellName: string,
    fromCellName: string,
    factoryBarcode: string,
    barcode: string,
    awaitQuantity : number,
    onIncrement : () => void,
    onDecrement : () => void,
    onAdd?: () => void,
    onSet?: () => void,
    quantity: number
}

const PlacementProduct =  ({
    name,
    article,
    factoryBarcode,
    barcode,
    toCellName,
    fromCellName,
    awaitQuantity,
    onIncrement,
    onDecrement,
    imgSrc,
    quantity,
    onSet,
    onAdd}: Props) => {

    return <div className={styles.card}>
        <div className={styles.header}>
            <img src={imgSrc} className={styles.image}/>
            <div className={styles.info}>
                <div className={styles.nameAndText}>Название: <span className={styles.text}>{name}</span></div>
                <div className={styles.nameAndText}>Артикул: <span className={styles.text}>{article}</span></div>
                <div className={styles.nameAndText}>Штрихкод заводской: <span className={styles.text}>{factoryBarcode}</span></div>
                <div className={styles.nameAndText}>Штрихкод:  <span className={styles.text}>{barcode}</span></div>
            </div>
        </div>
        <div>
            Из: {fromCellName}
        </div>
        <div>
            В: {toCellName}
        </div>
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>Ожид. кол-во</td>
                        <td>Скан. кол-во</td>
                        <td>Ост. кол-во</td>
                    </tr>
                    <tr>
                        <td>{awaitQuantity}</td>
                        <td>{quantity}</td>
                        <td>{awaitQuantity - quantity}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className={styles.footer}>
            <div className={styles.buttons}>
                <TeminalCounterButton placeHolder="-" onClick={onDecrement} />
                <div className={styles.container}>
                    <div className={styles.button} onClick={onAdd}>Добавить</div>
                    <div className={styles.button} onClick={onSet}>Установить</div>
                </div>
                <TeminalCounterButton placeHolder="+" onClick={onIncrement} />
            </div>
        </div>
    </div>
}

export {PlacementProduct};