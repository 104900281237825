import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "widgets/Layout/Layout";
import Container from "widgets/Container/ui";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import ButtonNav from "shared/ui/ButtonNav/ButtonNav";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { TextArea } from "shared/ui/TextArea";
import clsx from "clsx";
import { ProductOutputModel, RedactBillModel, useGetBillsBillQuery, useGetProductsQuery, useGetUsersUserOrganizationsQuery, usePostBillsRedactBillMutation } from "api";
import { useAppSelector } from "app/store/hooks/redux";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import Modal from "shared/ui/Modal/Modal";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import { SearchInput } from "shared/ui/SearchInput";
import img from "shared/assets/images/box.png";

type Props = {
};

const columns: ColumnHeaderType[] = [
    {name: "Изображение", width: "15%"},
    {name: "Штрих-код", width: "10%"},
    {name: "Наименование", width: "20%"},
    {name: "Цвет", width: "5%"},
    {name: "Размер", width: "10%"},
    {name: "Цена услуги", width: "10%"},
    {name: "Количество", width: "10%"},
    {name: "Юр. лицо", width: "20%"},
]

const RedactAcceptanceBillPage = ({}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.AcceptanceBills);
        }
    }, [])

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<ProductOutputModel[]>([]);
    const [isVisibleDescriptions, setIsVisibleDescriptions] = useState<number[]>([]);
    const [quantities, setQuantities] = useState<{id: number, quantity: number}[]>([]);
    const transporters = ["Склад", "Самостоятельно", "Транспортная компания"];
    
    const {id: userId} = useAppSelector(el => el.userReducer);

    const [redactBillQuery] = usePostBillsRedactBillMutation();
    const {data: organizations} = useGetUsersUserOrganizationsQuery({userId: userId});
    const {data: productsByUser} = useGetProductsQuery({clientId: userId});
    const {data: bill} = useGetBillsBillQuery({billId: Number(searchParams.get('billId'))});

    const [redactedBill, setRedactedBill] = useState<RedactBillModel>({});

    useEffect(()=> {
        if (bill != null) {
            setAddedProducts(bill!.productsBills!.map(productBill => productBill.product!));
            setQuantities(bill!.productsBills!.map(productBill => ({id: productBill.product!.id!, quantity: productBill.quantity!})));
            setRedactedBill({
                id: bill?.id,
                realizeDate: (new Date(bill?.realizationDate!)).toISOString(),
                organizationName: bill?.organizationName,
                palletsCount: bill.palletsCount,
                boxexCount  : bill.boxexCount,
                transporter: bill.transporter,
                warehouseName: bill.warehouseName,
                carId: bill.carId,
                note: bill.note,
                deliveryNote: bill.deliveryNote,
                externalBillId: bill.externalBillId,
                sender: bill.sender,
            });
        }
    },[bill])

    const redactAcceptanceBill = () => {
        redactBillQuery({redactBillModel: {...redactedBill, products: addedProducts.map(product => {
            const quantity = quantities.find(quantity => quantity.id == product.id)?.quantity;
            return {...product, quantity: quantity};
        })}});
        navigate(LinkPagesEnum.AcceptanceBills);
    };

    const [modalSearch, setModalSearch] = useState("");
    
    return (
        <Layout>
            <Container>
                <>
                    <div>
                        <div className={styles.header}>
                            <ButtonNav inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.AcceptanceBills}/>
                            <Button text="Сохранить" onClick={redactAcceptanceBill}/>
                        </div>
                        <div className={styles.main}>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    Информация
                                </div>
                                <div className={styles.fieldsContainer}>
                                    <div className={styles.field}>
                                        {organizations?.length != 0 && organizations != null
                                            ?  
                                                <Select 
                                                    placeholder="Наименование организации"
                                                    options={organizations!.map(el => el.name!)}
                                                    onChange={val => setRedactedBill({...redactedBill, organizationName: val})}
                                                    selectedOption={redactedBill.organizationName!}
                                                    inlineStyles={{marginBottom: 10}}
                                                    />
                                            : 
                                            <Input
                                                inlineStylesContainer={{marginBottom: 10}}
                                                placeholder="Нет организаций"
                                                value=""
                                                onChange={e => {}}
                                                />
                                        }
                                        <Input
                                            placeholder="Внешний номер накладной"
                                            value={redactedBill.externalBillId}
                                            onChange={e => setRedactedBill({...redactedBill, externalBillId: e.target.value})}
                                            />
                                    </div>
                                    <TextArea
                                        classNamesContainer={clsx(styles.field, styles.comment)}
                                        placeholder="Комментарий"
                                        onChange={e => setRedactedBill({...redactedBill, note: e.target.value})}
                                        value={redactedBill.note}
                                        />
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    Доставка
                                </div>
                                <div className={styles.fieldsContainer} style={{flexWrap: "wrap"}}>
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Отправитель"
                                        value={redactedBill.sender}
                                        onChange={e => setRedactedBill({...redactedBill, sender: e.target.value})}
                                        />
                                    <Select 
                                        classNames={styles.field}
                                        placeholder="Перевозчик"
                                        options={transporters}
                                        onChange={val => setRedactedBill({...redactedBill, transporter: val})}
                                        selectedOption={redactedBill.transporter}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input 
                                        classNamesContainer={styles.field}
                                        placeholder="Ожидаемая дата доставки"
                                        type="date"
                                        value={redactedBill.realizeDate?.split('T')[0]}
                                        onChange={e => setRedactedBill({...redactedBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество коробов"
                                        value={redactedBill.boxexCount?.toString()}
                                        type="number"
                                        onChange={e => setRedactedBill({...redactedBill, boxexCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество паллет"
                                        type="number"
                                        value={redactedBill.palletsCount?.toString()}
                                        onChange={e => setRedactedBill({...redactedBill, palletsCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <TextArea
                                        classNamesContainer={styles.field}
                                        inlineStylesContainer={{height: 100}}
                                        placeholder="Коментарий"
                                        value={redactedBill.deliveryNote}
                                        onChange={e => setRedactedBill({...redactedBill, deliveryNote: e.target.value})}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className={styles.products}>
                            <div className={styles.products__buttons}>
                                <Button 
                                    text="Добавить товар"
                                    onClick={() => {
                                        setAddProductModalVisible(true);
                                    }}
                                    />
                            </div>
                            <Table columns={columns}>
                                {addedProducts != null && addedProducts.length > 0 
                                ?
                                    addedProducts!.map(product => <TableItem inlineStyles={{marginBottom: 10}}>
                                        <>
                                            <TableItemContainer width={columns[0].width}><img src={product.images != null ? product.images[0] : img} className={styles.img}/></TableItemContainer>
                                            <TableItemContainer width={columns[1].width}><div className={styles.text}>{product.barcode}</div></TableItemContainer>
                                            <TableItemContainer width={columns[2].width}><div className={styles.text}>{product.name}</div></TableItemContainer>
                                            <TableItemContainer width={columns[3].width}><div className={styles.text}>{product.color}</div></TableItemContainer>
                                            <TableItemContainer width={columns[4].width}><div className={styles.text}>-</div></TableItemContainer>
                                            <TableItemContainer width={columns[5].width}><div className={styles.text}>{product.serviceProductPrice}</div></TableItemContainer>
                                            <TableItemContainer width={columns[6].width}>
                                                <Input 
                                                    type="number"
                                                    onChange={e => 
                                                        setQuantities(prevState =>
                                                            {   
                                                                if (prevState.find(pv => pv.id == product.id)) {
                                                                    return prevState.map(pv => pv.id == product.id 
                                                                        ? {...pv, quantity: Number(e.target.value)} 
                                                                        : pv
                                                                        )
                                                                }
                                                                return [...prevState, {id: product.id!, quantity: Number(e.target.value)}]
                                                            } 
                                                            )
                                                        } 
                                                    value={quantities!.find(q => q.id == product.id)!.quantity.toString()}
                                                    />
                                            </TableItemContainer>
                                            <TableItemContainer width={columns[7].width}><div className={styles.text}>{product.organizationName}</div></TableItemContainer>
                                        </>
                                    </TableItem>)
                                : <></>
                                }
                            </Table>
                        </div>
                    </div>
                    <Modal isCloseCloseIcon isOpen={addProductModalVisible} classNames={styles.modalContainer} close={() => setAddProductModalVisible(prevState => !prevState)}>
                        <div style={{width: "100%"}}>
                            <TitleForm title="Выберите товары"/>
                            <div className={styles.searchContainer}>
                                <SearchInput
                                    value={modalSearch}
                                    onChange={e => setModalSearch(e)}
                                    classNames={styles.searchInput}
                                    />
                            </div>
                            <div style={{marginTop: 40}}>
                                {productsByUser?.map(product => 
                                    <TableItem 
                                        onClick={() => {
                                            if (addedProducts.map(x => x.id).includes(product.id)) {
                                                setAddedProducts(prevState => prevState.filter(el => el.id != product.id));
                                                setQuantities(prevState => prevState.filter(el => el.id != product.id));
                                            }
                                            else {
                                                setAddedProducts(prevState => [...prevState, product]);
                                                setQuantities(prevState => [...prevState, {id: product.id!, quantity: 0}])
                                            }
                                        }}   
                                        classNames={clsx(addedProducts.filter(el => el.id == product.id).length != 0 ? styles.itemSelected : "", styles.hover)} 
                                        inlineStyles={{marginBottom: 10, width: 690, padding: 20}}
                                        >
                                        <div>
                                            <div style={{display: "flex"}}>
                                                <img src={product.images != null ? product.images[0] : img} className={styles.modalImg}/>
                                                <div>
                                                    <div className={styles.modalText}>Название: {product.name}</div>
                                                    <div className={styles.modalText}>Артикул: {product.article}</div>
                                                    <div className={styles.modalText}>Штрих-код: {product.barcode}</div>
                                                    <div className={styles.modalText}>Внешний штрих-код: {product.factoryBarcode}</div>
                                                    <div className={styles.modalText}>Остаток на складе: {product.quantity}</div>
                                                    <Button
                                                        inlineStyles={{marginTop: 15}}
                                                        text="Посмотреть описание"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (isVisibleDescriptions.includes(product.id!)) {
                                                                setIsVisibleDescriptions(prevState => prevState.filter(el => el != product.id));
                                                            }
                                                            else {
                                                                setIsVisibleDescriptions(prevState => [...prevState, product.id!]);
                                                            }
                                                        }}
                                                        />
                                                </div>
                                            </div>
                                            {
                                                isVisibleDescriptions.includes(product.id!) &&
                                                <div>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde ex dolore odit impedit nam placeat, in inventore deleniti maxime at quidem ab accusamus odio nisi asperiores tempora repudiandae delectus nostrum.
                                                </div>
                                            }
                                        </div>
                                    </TableItem>
                                )}
                            </div>
                            <Button
                                className={styles.button}
                                text="Закрыть"
                                onClick={e => setAddProductModalVisible(false)}
                                />
                        </div>
                    </Modal>
                </>
            </Container>
        </Layout>
    )
};

export {RedactAcceptanceBillPage};