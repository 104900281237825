export type ProductType = {
    article: string, 
    barcode: string,
    billProducts: Array<string>,
    color: string,
    factoryBarcode: string,
    id: string,
    imageLinks: null | string,
    name: string,
    objectCreateDate: string,
    owner: {name: string, email: string, imageId: string, image: null, password: string, receivedProducts: null, telephone: null},
    ownerId: string,
    receivedProducts: null,
    quantity? : number | null
};
export type ProductsBillsType = {
    actualQuantity: number,
    barcode: string,
    billId: string,
    id: string,
    objectCreateDate: string,
    product: ProductType,
    productId: string,
    quantity: number,
};
export enum BillStatusEnum {
	New,
	Removed,
	Working,
	Accepted,
	Closed
}
