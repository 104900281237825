import React, { CSSProperties, useState } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import { ArrowIcon } from "../icons/icons";

type Props = {
    options: string[];
    selectedOption?: string | undefined | null;
    onChange: (e: string) => void;
    placeholder: string;
    classNames?: string;
    inlineStyles?: CSSProperties;
}

const Select = ({placeholder, options, selectedOption, onChange, classNames, inlineStyles}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const isOptionSelected = selectedOption != "";

    return (
        <div className={clsx(styles.container, classNames, isOptionSelected && styles.selected, isOpen && styles.opened)} style={inlineStyles} onClick={e => {e.stopPropagation(); setIsOpen(!isOpen)}}>
            <div className={styles.top}>
                <div className={clsx(styles.placeholder)}>{placeholder}</div>
                {isOptionSelected ? <div className={styles.selectedOptionWithArrow}>
                    {selectedOption} 
                        <div className={styles.arrow}>
                            <ArrowIcon fill="#322C36" width="6" height="3" inlineStyles={{transform: isOpen ? "rotate(180deg)" : "rotate(0)" + "translateY(1px)" }}/>
                        </div>
                    </div>
                    :
                    <div className={styles.arrow}>
                        <ArrowIcon fill="#cacaca" width="6" height="3" inlineStyles={{transform: isOpen ? "rotate(180deg)" : "rotate(0)"}}/>
                    </div>
                }
                
            </div>
            {
                isOpen
                    && (
                        <div className={styles.bottom}>
                            {options.map(el => {
                                return (
                                    <div
                                        onClick={() => onChange(el)} 
                                        className={clsx(styles.option, selectedOption == el && styles.selectedOption)}
                                        >
                                        {el}
                                    </div>
                                )
                            })}
                        </div>
                        )
            }
        </div>
    );
}

export {
    Select
};