import Layout from "widgets/Layout/Layout";
import React, { useState } from "react";
import { tableHeadName } from "pages/Storage/consts";
import ProductsStorageModal from "pages/Modals/ProductsStorageModal";
import Modal from "shared/ui/Modal/Modal";
import styles from "./index.module.scss";
import { OpenListIcon } from "shared/ui/icons/icons";
import {Button} from "shared/ui/Button";
import Container from "widgets/Container/ui";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { useGetStoragePlacesQuery } from "api";
import { Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { getPercentColor } from "functions/color";

const StoragePage = () => {
    const [targetCellId, setTargetCellId] = useState<number | null>(null);

    const {data: cells, refetch} = useGetStoragePlacesQuery({warehouseId: 1});

    const [isOpen, setIsOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Ячейки"]);
    const [searchText, setSearchText] = useState("");

    const options = ["Ячейки", "Заполненность", "Код (ID)", "Имя", "Склад"];

    const filteredData = cells?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.place, option: "Ячейки"},
        {value: el.occupancyPercent, option: "Заполненность"},
        {value: el.code, option: "Код (ID)"},
        {value: el.name, option: "Имя"},
        {value: el.warehouse?.name, option: "Склад"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("Заполненность");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.place, value1: el1.place, option: "Ячейки"},
        {value2: el2.name,  value1: el1.name, option: "Заполненность"},
        {value2: el2.code, value1: el1.code, option: "Код (ID)"},
        {value2: el2.name, value1: el1.name, option: "Имя"},
        {value2: el2.warehouse?.name, value1: el1.warehouse?.name, option: "Склад"},
    ], selectedOption, isDesc));
   
    return (
        <Layout>
        <>
            <Container>
                <>
                <div style={{display: "flex", marginBottom: "20px", justifyContent: 'space-between'}}>
                    <div>
                        <Button
                            inlineStyles={{marginRight: 10}}
                            text="Обновить"
                            onClick={refetch}
                            />
                        {/* <ImportButton inlineStyles={{marginRight: 4}} text="Импорт" addFile={addFile}/> */}
                        {/* <Button text="Скачать шаблон" onClick={() => window.open(BASE_URL + "/media/productsTemplate.xlsx","_blank")}/> */}
                    </div>
                    <div style={{display: "flex"}}>
                        <SearchInput
                            classNames={styles.searchInput}
                            value={searchText}
                            onChange={setSearchText}
                            inlineStyles={{marginRight: 10}}
                            />
                        <MultipleSelect 
                            text="Параметры"
                            options={options} 
                            selectedOptions={selectedOptions} 
                            setSelectedOptions={setSelectedOptions}
                            inlineStyles={{marginRight: 10, width: 135}}
                            />
                        <Sort 
                            options={options}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setIsDesc={setIsDesc}
                            isDesc={isDesc}
                            selectClassNames={styles.select}
                            />
                    </div>
                </div>
                <Table columns={tableHeadName}>
                    {sortedData?.map(el => (
                        <TableItem inlineStyles={{marginBottom: 10}}>
                            <>
                                <TableItemContainer width={tableHeadName[0].width}><div className={styles.name}>{el.place} </div></TableItemContainer>
                                <TableItemContainer width={tableHeadName[1].width}><div style={{color: getPercentColor(el.occupancyPercent!)}}>{el.occupancyPercent}%</div></TableItemContainer>
                                <TableItemContainer width={tableHeadName[2].width}>{el.code}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[3].width}>{el.name}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[4].width}>{el.warehouse?.name}</TableItemContainer>
                                <TableItemContainer width={tableHeadName[5].width}> <OpenListIcon onClick={() => {setIsOpen(true);setTargetCellId(el.id!);}} inlineClass={styles.openListIcon}/></TableItemContainer>
                                <TableItemContainer width={tableHeadName[6].width}><Button text="Печать" onClick={() => {}}/></TableItemContainer>
                            </>
                        </TableItem>
                    ))}
                </Table>

                {/* <div className={styles.mobile}>
                    { sortedData?.map(el => (
                        <div className={styles.item_m}>
                            <div className={styles.bold} style={{marginBottom: 5, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div>
                                    Ячейка:
                                </div>
                                <div 
                                    style={{
                                        color: "#C5A2E0", 
                                        fontWeight: 500, 
                                        padding: "5px 10px", 
                                        backgroundColor: "#EAE4EE",
                                        borderRadius: 5,
                                        marginTop: 5
                                    }}
                                    >
                                    {el.place}
                                </div>
                            </div>
                            <div style={{marginBottom: 5}} className={styles.bold}>
                                Заполненность:
                                <span style={{color: "#C5A2E0", fontWeight: 500, marginLeft: 3}}>
                                    {el.occupancyPercent}%
                                </span>
                            </div>
                            <div style={{marginBottom: 5}} className={styles.bold}>
                                Код (ID): 
                                <span style={{fontWeight: 500, marginLeft: 3}}>
                                    {el.code}
                                </span>
                            </div>
                            <div style={{marginBottom: 5}} className={styles.bold}>
                                Имя: 
                                <span style={{fontWeight: 500, marginLeft: 3}}>
                                    {el.name}
                                </span>
                            </div>
                            <div className={styles.bold} style={{marginBottom: 5}}>
                                Склад:
                                <span style={{fontWeight: 500, marginLeft: 3}}>
                                    {el.warehouse.name}
                                </span>
                            </div>
                            <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <div className={styles.bold} style={{marginBottom: 5}}>
                                    Этикетка:
                                </div>
                                <div className={styles.button}>
                                    Печать
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setIsOpen(true);
                                    handlerClick(el.id);
                                }}
                                style={{cursor: 'pointer', position: 'absolute', top: 10, right: 10}}
                                >
                                <OpenListIcon/>
                            </div>
                        </div>
                    ))
                    }
                </div> */}

                {/* <TableNavbar 
                    text={"Ячейки"} 
                    buttonsNavbar={buttonsNavbar}
                    updateButton={updateButton}
                    onSearch={()=>{}}
                    searchValue=""
                /> */}
                </>
                
                {/* <ContentCard header={tableHeadName}>
                    {storagePlaces.map(item => (
                        <StorageItem 
                            key={item.name}
                            setIsOpenModal={setIsOpen}
                            item={item}
                            onItemClick={handlerClick}
                        />
                    ))}
                </ContentCard> */}
            </Container>
            {targetCellId != null
                &&
                <Modal 
                    setIsOpen={setIsOpen} 
                    isOpen={isOpen} 
                    isCloseCloseIcon
                    classNames={styles.modal}
                    close={() => setIsOpen(false)}
                >
                    <ProductsStorageModal id={targetCellId} closeModal={() => setIsOpen(false)}/>
                </Modal>}
        </>
        </Layout>
    )
};

export {StoragePage};