import CSS from 'csstype';
import styles from './index.module.scss';

type Props = {
  icon: JSX.Element;
  text: string;
  width?: string;
  height?: string;
  inlineStyles?: CSS.Properties;
  stroke?: string;
  fill?: string;
  inlineClass?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const RemoveIcon = ({
    width = "10",
    height = "10",
    inlineStyles,
    inlineClass,
    onClick
}: Pick<Props, 'onClick' | 'width' | 'height' | 'inlineStyles' | 'inlineClass'>) => (
    <svg 
        onClick={onClick}
        width={width} 
        height={height} 
        viewBox="0 0 10 10" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        style={inlineStyles}
        className={inlineClass}
        >
        <path d="M1 1L9 9" stroke="#C5A2E0"/>
        <path d="M9 1L1 9" stroke="#C5A2E0"/>
    </svg>
);

const LogoIcon = ({
  width = '307',
  height = '42',
  inlineStyles,
  inlineClass,
}: Pick<Props, 'width' | 'height' | 'inlineStyles' | 'inlineClass'>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 307 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={inlineStyles}
    className={inlineClass}
  >
    <path
      d="M117.861 0H82.9467H82.541H73.5545L59.7256 33.6547L45.8967 0H38.6131H36.5045H11.6006L0 12.3048V41.9026H35.3205C37.1364 41.9026 38.6131 40.3363 38.6131 38.4101C38.6131 36.484 37.1364 34.9177 35.3205 34.9177H8.86672V22.5353H32.7064C34.5223 22.5353 35.999 20.9689 35.999 19.0428C35.999 17.1166 34.5223 15.5503 32.7064 15.5503H8.86672V6.97788H38.6131V5.11524L53.7258 41.8956H65.7254L82.541 0.973641V41.8956H112.78L119.365 34.9106H91.4077V22.5282H115.247C117.063 22.5282 118.54 20.9619 118.54 19.0357C118.54 17.1096 117.063 15.5433 115.247 15.5433H91.4077V6.97082H117.861C119.677 6.97082 121.154 5.40451 121.154 3.47836C121.154 1.55926 119.677 0 117.861 0Z"
      fill="white"
    />
    <path
      d="M209.163 0H169.685H168.102H160.812V29.8448L138.375 0H128.464V37.2037C128.464 39.8001 130.447 41.9097 132.901 41.9097C135.349 41.9097 137.338 39.8001 137.338 37.2037V12.0649L159.774 41.9097H169.685V6.98494H185.842V40.7879L194.715 33.2244V6.98494H209.163C210.979 6.98494 212.455 5.41861 212.455 3.49246C212.455 1.55925 210.979 0 209.163 0Z"
      fill="white"
    />
    <path
      d="M293.985 0H283.569L276.983 6.98494C277.083 6.98494 277.19 6.98494 277.289 6.98494H294.511C296.074 6.98494 297.644 6.98494 297.644 9.20742V32.7022C297.644 34.9247 296.081 34.9247 294.511 34.9247H277.289C275.726 34.9247 274.157 34.9247 274.157 32.7022V9.98353L265.29 19.3885V33.3302C265.29 41.9026 271.549 41.9026 277.815 41.9026H293.992C300.251 41.9026 306.517 41.9026 306.517 33.3302V8.56537C306.51 -1.14387e-05 300.244 0 293.985 0Z"
      fill="white"
    />
    <path
      d="M242.967 0H232.55L225.965 6.98494C226.065 6.98494 226.171 6.98494 226.271 6.98494H243.492C245.055 6.98494 246.625 6.98494 246.625 9.20742V32.7022C246.625 34.9247 245.062 34.9247 243.492 34.9247H226.271C224.708 34.9247 223.138 34.9247 223.138 32.7022V9.98353L214.271 19.3885V33.3302C214.271 41.9026 220.531 41.9026 226.796 41.9026H242.973C249.233 41.9026 255.498 41.9026 255.498 33.3302V8.56537C255.492 -1.14387e-05 249.233 0 242.967 0Z"
      fill="white"
    />
  </svg>
);

const HomeIcon = ({
  width = '24',
  height = '24',
  inlineStyles,
  inlineClass,
  fill = '#6D6D6D',
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'fill'
>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    style={inlineStyles}
    className={inlineClass}
  >
    <path
      d="M17 17.9963V24H21C22.6568 24 24 22.656 24 20.9981V11.8715C24.0002 11.3516 23.7983 10.8521 23.437 10.4786L14.939 1.28584C13.4396 -0.337513 10.9089 -0.437185 9.28655 1.06319C9.20949 1.13448 9.13523 1.20873 9.06403 1.28584L0.581016 10.4756C0.208734 10.8506 -0.000140554 11.3579 7.09607e-08 11.8865V20.9981C7.09607e-08 22.656 1.34316 24 3 24H6.99998V17.9963C7.01869 15.2677 9.22027 13.0396 11.8784 12.9754C14.6255 12.9091 16.9791 15.1752 17 17.9963Z"
      fill={fill}
    />
    <path
      d="M12 14.9937C10.3432 14.9937 9 16.3376 9 17.9955V23.9993H15V17.9955C15 16.3376 13.6568 14.9937 12 14.9937Z"
      fill={fill}
    />
  </svg>
);

const OpenListIcon = ({
  width = '37',
  height = '37',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => (
  <svg 
    onClick={onClick}
    style={inlineStyles}
    className={inlineClass} 
    width={width} 
    height={height}
    viewBox="0 0 37 37" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
    <rect x="0.5" y="0.5" width="36" height="36" rx="9.5" stroke="#C5A2E0"/>
    <path d="M14.666 16.5833H22.3327" stroke="#322C36" stroke-linecap="round"/>
    <path d="M12.75 12.7503H24.25" stroke="#322C36" stroke-linecap="round"/>
    <path d="M12.75 20.4163H24.25" stroke="#322C36" stroke-linecap="round"/>
    <path d="M14.666 24.2503H22.3327" stroke="#322C36" stroke-linecap="round"/>
  </svg>
);

const CloseIcon = ({
  width = '20',
  height = '20',
  inlineStyles,
  inlineClass,
  fill = '#C5A2E0',
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'fill' | 'onClick'
>) => (
  <svg onClick={onClick} width={width} height={height} className={inlineClass} style={inlineStyles} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.08945 20C1.55835 20 1.02725 19.8044 0.607966 19.3853C-0.202655 18.5749 -0.202655 17.2337 0.607966 16.4233L16.4291 0.607754C17.2397 -0.202585 18.5814 -0.202585 19.392 0.607754C20.2027 1.41809 20.2027 2.75934 19.392 3.56968L3.57093 19.3853C3.17959 19.8044 2.62054 20 2.08945 20Z" fill={fill}/>
    <path d="M17.9106 20C17.3795 20 16.8484 19.8044 16.4291 19.3853L0.607966 3.56968C-0.202655 2.75934 -0.202655 1.41809 0.607966 0.607754C1.41859 -0.202585 2.76031 -0.202585 3.57093 0.607754L19.392 16.4233C20.2027 17.2337 20.2027 18.5749 19.392 19.3853C18.9727 19.8044 18.4417 20 17.9106 20Z" fill={fill}/>
  </svg>
);

const SearchMobileIcon = ({
  width = '15',
  height = '15',
  inlineStyles,
  inlineClass,
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass'
>) => (
  <svg className={inlineClass} style={inlineStyles} width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.1875 13.5938C3.65625 13.5938 0.78125 10.7188 0.78125 7.1875C0.78125 3.65625 3.65625 0.78125 7.1875 0.78125C10.7188 0.78125 13.5938 3.65625 13.5938 7.1875C13.5938 10.7188 10.7188 13.5938 7.1875 13.5938ZM7.1875 1.71875C4.16875 1.71875 1.71875 4.175 1.71875 7.1875C1.71875 10.2 4.16875 12.6562 7.1875 12.6562C10.2062 12.6562 12.6562 10.2 12.6562 7.1875C12.6562 4.175 10.2062 1.71875 7.1875 1.71875Z" fill="#CACACA"/>
    <path d="M13.7499 14.2187C13.6312 14.2187 13.5124 14.1749 13.4187 14.0812L12.1687 12.8312C11.9874 12.6499 11.9874 12.3499 12.1687 12.1687C12.3499 11.9874 12.6499 11.9874 12.8312 12.1687L14.0812 13.4187C14.2624 13.5999 14.2624 13.8999 14.0812 14.0812C13.9874 14.1749 13.8687 14.2187 13.7499 14.2187Z" fill="#CACACA"/>
  </svg>
);

const OptionsIcon = ({
  width = '15',
  height = '15',
  inlineStyles,
  inlineClass,
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass'
>) => (
  <svg 
    width={width} 
    height={height} 
    className={inlineClass} 
    style={inlineStyles} 
    version="1.1" 
    viewBox="0 0 32 32" 
    xmlSpace="preserve" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g>
      <path d="M2,17.5h9.25531C11.89697,19.5213,13.76917,21,16,21s4.10303-1.4787,4.74469-3.5H30c0.82861,0,1.5-0.67139,1.5-1.5   s-0.67139-1.5-1.5-1.5h-9.25531C20.10303,12.4787,18.23083,11,16,11s-4.10303,1.4787-4.74469,3.5H2c-0.82861,0-1.5,0.67139-1.5,1.5   S1.17139,17.5,2,17.5z M16,14c1.10303,0,2,0.89697,2,2s-0.89697,2-2,2s-2-0.89697-2-2S14.89697,14,16,14z"/>
      <path d="M2,7h2.75531C5.39697,9.0213,7.26917,10.5,9.5,10.5s4.10303-1.4787,4.74469-3.5H30c0.82861,0,1.5-0.67139,1.5-1.5   S30.82861,4,30,4H14.24469C13.60303,1.9787,11.73083,0.5,9.5,0.5S5.39697,1.9787,4.75531,4H2C1.17139,4,0.5,4.67139,0.5,5.5   S1.17139,7,2,7z M9.5,3.5c1.10303,0,2,0.89697,2,2s-0.89697,2-2,2s-2-0.89697-2-2S8.39697,3.5,9.5,3.5z"/>
      <path d="M2,28h15.75531c0.64166,2.0213,2.51385,3.5,4.74469,3.5s4.10303-1.4787,4.74469-3.5H30c0.82861,0,1.5-0.67139,1.5-1.5   S30.82861,25,30,25h-2.75531c-0.64166-2.0213-2.51385-3.5-4.74469-3.5s-4.10303,1.4787-4.74469,3.5H2   c-0.82861,0-1.5,0.67139-1.5,1.5S1.17139,28,2,28z M22.5,24.5c1.10303,0,2,0.89697,2,2s-0.89697,2-2,2s-2-0.89697-2-2   S21.39697,24.5,22.5,24.5z"/>
    </g>
  </svg>
);

const SortIcon = ({
  width = '24',
  height = '24',
  inlineStyles,
  inlineClass,
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass'
>) => (
  <svg width={width} height={height} className={inlineClass} style={inlineStyles} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 20C14 20.5523 14.4477 21 15 21C15.5523 21 16 20.5523 16 20V6.41424L18.2929 8.70714C18.6834 9.09766 19.3166 9.09766 19.7071 8.70714C20.0976 8.31661 20.0976 7.68345 19.7071 7.29292L15.7071 3.29292C15.4211 3.00692 14.991 2.92137 14.6173 3.07615C14.2436 3.23093 14 3.59557 14 4.00003V20ZM10 4.00003C10 3.44775 9.55228 3.00003 9 3.00003C8.44772 3.00003 8 3.44775 8 4.00003V17.5858L5.70711 15.2929C5.31658 14.9024 4.68342 14.9024 4.29289 15.2929C3.90237 15.6834 3.90237 16.3166 4.29289 16.7071L8.29289 20.7071C8.57889 20.9931 9.00901 21.0787 9.38268 20.9239C9.75636 20.7691 10 20.4045 10 20V4.00003Z" fill="#C5A2E0"/>
  </svg>
);

const BurgerIcon = ({
  width = '30',
  height = '30',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => (
  <svg onClick={onClick} width={width} height={height} style={inlineStyles} className={inlineClass} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7.5L17.5 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 15L25 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 22.5L12.5 22.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

const Logo = ({
  width = '100',
  height = '40',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => (
  <svg onClick={onClick} width={width} height={height} style={inlineStyles} className={inlineClass}  fill="#fff" id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.3 70">
    <path style={{strokeWidth: 0}} d="M91.2,0h23.6v70h-23.6V0Z"/>
    <path style={{strokeWidth: 0}} d="M147,17.8v12h29.2v17.8h-29.2v22.4h-23.6V0h56.9v17.8h-33.3Z"/>
    <g>
      <path style={{strokeWidth: 0}} d="M77.65,16.4c-3.3-5.27-7.93-9.32-13.9-12.15C57.78,1.42,50.9,0,43.1,0h-10.9v18.4h9.9c5.07,0,9.12,1.45,12.15,4.35,3.03,2.9,4.55,6.98,4.55,12.25s-1.52,9.35-4.55,12.25c-3.03,2.9-7.08,4.35-12.15,4.35h-9.9v18.4h10.9c7.8,0,14.68-1.42,20.65-4.25,5.97-2.83,10.6-6.88,13.9-12.15,3.3-5.27,4.95-11.47,4.95-18.6s-1.65-13.33-4.95-18.6Z"/>
      <rect style={{strokeWidth: 0}} width="23.6" height="70"/>
    </g>
  </svg>
);

const Icon = ({
  width = '100',
  height = '85',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => (
<svg width={width} height={height} id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.26 118.04">
  <g>
    <path style={{fill: "#c5a2e0", strokeWidth: 0}} d="M74.56,46.84c-2.17-3.46-5.22-6.12-9.14-7.99-3.92-1.86-8.45-2.79-13.57-2.79h-7.17v12.1h6.51c3.33,0,5.99.95,7.99,2.86,1.99,1.91,2.99,4.59,2.99,8.05s-1,6.15-2.99,8.05c-1.99,1.91-4.66,2.86-7.99,2.86h-6.51v12.1h7.17c5.13,0,9.65-.93,13.57-2.79,3.92-1.86,6.97-4.52,9.14-7.99,2.17-3.46,3.25-7.54,3.25-12.23s-1.08-8.76-3.25-12.23Z"/>
    <rect style={{fill: "#c5a2e0", strokeWidth: 0}} x="23.52" y="36.06" width="15.51" height="46.02"/>
  </g>
  <polygon style={{fill: 'none', stroke: "#c5a2e0", strokeMiterlimit: 10, strokeWidth: 16}} points="93.18 83.87 93.18 34.26 50.66 9.46 8.14 34.26 8.14 83.87 50.66 108.67 93.18 83.87"/>
</svg>
);

const ArrowIcon = ({
  width = '16',
  height = '10',
  inlineStyles,
  inlineClass,
  onClick,
  fill="#fff"
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick' | 'fill'
>) => (
  <svg onClick={onClick} fill="none" style={inlineStyles} className={inlineClass} width={width} height={height} viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L8 8L14 2" stroke={fill} stroke-width="2" stroke-linecap="square"/>
  </svg>
);

const NotificationsIcon = ({
  width = '24',
  height = '24',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => (
  <svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0199 20.5299C9.68987 20.5299 7.35987 20.1599 5.14987 19.4199C4.30987 19.1299 3.66987 18.5399 3.38987 17.7699C3.09987 16.9999 3.19987 16.1499 3.65987 15.3899L4.80987 13.4799C5.04987 13.0799 5.26987 12.2799 5.26987 11.8099V8.91992C5.26987 5.19992 8.29987 2.16992 12.0199 2.16992C15.7399 2.16992 18.7699 5.19992 18.7699 8.91992V11.8099C18.7699 12.2699 18.9899 13.0799 19.2299 13.4899L20.3699 15.3899C20.7999 16.1099 20.8799 16.9799 20.5899 17.7699C20.2999 18.5599 19.6699 19.1599 18.8799 19.4199C16.6799 20.1599 14.3499 20.5299 12.0199 20.5299ZM12.0199 3.66992C9.12987 3.66992 6.76987 6.01992 6.76987 8.91992V11.8099C6.76987 12.5399 6.46987 13.6199 6.09987 14.2499L4.94987 16.1599C4.72987 16.5299 4.66987 16.9199 4.79987 17.2499C4.91987 17.5899 5.21987 17.8499 5.62987 17.9899C9.80987 19.3899 14.2399 19.3899 18.4199 17.9899C18.7799 17.8699 19.0599 17.5999 19.1899 17.2399C19.3199 16.8799 19.2899 16.4899 19.0899 16.1599L17.9399 14.2499C17.5599 13.5999 17.2699 12.5299 17.2699 11.7999V8.91992C17.2699 6.01992 14.9199 3.66992 12.0199 3.66992Z" fill="#322C36"/>
    <path d="M13.8796 3.93969C13.8096 3.93969 13.7396 3.92969 13.6696 3.90969C13.3796 3.82969 13.0996 3.76969 12.8296 3.72969C11.9796 3.61969 11.1596 3.67969 10.3896 3.90969C10.1096 3.99969 9.80963 3.90969 9.61963 3.69969C9.42963 3.48969 9.36963 3.18969 9.47963 2.91969C9.88963 1.86969 10.8896 1.17969 12.0296 1.17969C13.1696 1.17969 14.1696 1.85969 14.5796 2.91969C14.6796 3.18969 14.6296 3.48969 14.4396 3.69969C14.2896 3.85969 14.0796 3.93969 13.8796 3.93969Z" fill="#322C36"/>
    <path d="M12.0195 22.8096C11.0295 22.8096 10.0695 22.4096 9.36953 21.7096C8.66953 21.0096 8.26953 20.0496 8.26953 19.0596H9.76953C9.76953 19.6496 10.0095 20.2296 10.4295 20.6496C10.8495 21.0696 11.4295 21.3096 12.0195 21.3096C13.2595 21.3096 14.2695 20.2996 14.2695 19.0596H15.7695C15.7695 21.1296 14.0895 22.8096 12.0195 22.8096Z" fill="#322C36"/>
  </svg>
);

const Close2Icon = ({
  width = '10',
  height = '10',
  inlineStyles,
  inlineClass,
  onClick,
  fill = "none",
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick' | 'fill'
>) => ( 
  <svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 10 10" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L9 9" stroke="#CACACA"/>
    <path d="M9 1L1 9" stroke="#CACACA"/>
  </svg>
);


const Search2Icon = ({
  width = '14',
  height = '14',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => ( 
  <svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.70817 12.6878C3.41234 12.6878 0.729004 10.0045 0.729004 6.70866C0.729004 3.41283 3.41234 0.729492 6.70817 0.729492C10.004 0.729492 12.6873 3.41283 12.6873 6.70866C12.6873 10.0045 10.004 12.6878 6.70817 12.6878ZM6.70817 1.60449C3.89067 1.60449 1.604 3.89699 1.604 6.70866C1.604 9.52033 3.89067 11.8128 6.70817 11.8128C9.52567 11.8128 11.8123 9.52033 11.8123 6.70866C11.8123 3.89699 9.52567 1.60449 6.70817 1.60449Z" fill="#CACACA"/>
    <path d="M12.8332 13.2707C12.7223 13.2707 12.6115 13.2298 12.524 13.1423L11.3573 11.9757C11.1882 11.8065 11.1882 11.5265 11.3573 11.3573C11.5265 11.1882 11.8065 11.1882 11.9757 11.3573L13.1423 12.524C13.3115 12.6932 13.3115 12.9732 13.1423 13.1423C13.0548 13.2298 12.944 13.2707 12.8332 13.2707Z" fill="#CACACA"/>
  </svg>
);

const FileExportIcon = ({
  width = '20',
  height = '20',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => ( 
  <svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.20939 15.3498C7.11637 15.3498 7.03265 15.3312 6.93963 15.294C6.67916 15.1917 6.51172 14.9312 6.51172 14.6521V9.07072C6.51172 8.68933 6.828 8.37305 7.20939 8.37305C7.59079 8.37305 7.90707 8.68933 7.90707 9.07072V12.9684L8.57684 12.2986C8.8466 12.0289 9.29311 12.0289 9.56288 12.2986C9.83265 12.5684 9.83265 13.0149 9.56288 13.2847L7.70242 15.1451C7.57218 15.2754 7.38614 15.3498 7.20939 15.3498Z" fill="#322C36"/>
    <path d="M7.20913 15.3492C7.03239 15.3492 6.85565 15.2841 6.71611 15.1445L4.85565 13.2841C4.58588 13.0143 4.58588 12.5678 4.85565 12.298C5.12541 12.0283 5.57193 12.0283 5.84169 12.298L7.70216 14.1585C7.97193 14.4283 7.97193 14.8748 7.70216 15.1445C7.56262 15.2841 7.38588 15.3492 7.20913 15.3492Z" fill="#322C36"/>
    <path d="M12.7907 20H7.2093C2.15814 20 0 17.8419 0 12.7907V7.2093C0 2.15814 2.15814 0 7.2093 0H11.8605C12.2419 0 12.5581 0.316279 12.5581 0.697674C12.5581 1.07907 12.2419 1.39535 11.8605 1.39535H7.2093C2.92093 1.39535 1.39535 2.92093 1.39535 7.2093V12.7907C1.39535 17.0791 2.92093 18.6047 7.2093 18.6047H12.7907C17.0791 18.6047 18.6047 17.0791 18.6047 12.7907V8.13953C18.6047 7.75814 18.9209 7.44186 19.3023 7.44186C19.6837 7.44186 20 7.75814 20 8.13953V12.7907C20 17.8419 17.8419 20 12.7907 20Z" fill="#322C36"/>
    <path d="M19.3026 8.83779H15.5817C12.4003 8.83779 11.1631 7.60058 11.1631 4.41918V0.698254C11.1631 0.419185 11.3305 0.158719 11.591 0.0563939C11.8515 -0.055234 12.1491 0.00988236 12.3538 0.205231L19.7956 7.64709C19.991 7.84244 20.0561 8.14942 19.9445 8.40988C19.8329 8.67035 19.5817 8.83779 19.3026 8.83779ZM12.5584 2.38198V4.41918C12.5584 6.81918 13.1817 7.44244 15.5817 7.44244H17.6189L12.5584 2.38198Z" fill="#322C36"/>
  </svg>
);

const PdfIcon = ({
  width = '18',
  height = '18',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => ( 
  <svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.85792 0C3.15081 0 2.57227 0.50625 2.57227 1.125V16.875C2.57227 17.4938 3.15081 18 3.85792 18H16.7145C17.4216 18 18.0001 17.4938 18.0001 16.875V4.5L12.8575 0H3.85792Z" fill="#E2E5E7"/>
    <path d="M14.1431 4.5H18L12.8574 0V3.375C12.8574 3.99375 13.436 4.5 14.1431 4.5Z" fill="#B0B7BD"/>
    <path d="M17.9976 7.875L14.1406 4.5H17.9976V7.875Z" fill="#CAD1D8"/>
    <path d="M15.4279 14.625C15.4279 14.9344 15.1386 15.1875 14.785 15.1875H0.642827C0.289272 15.1875 0 14.9344 0 14.625V9C0 8.69062 0.289272 8.4375 0.642827 8.4375H14.785C15.1386 8.4375 15.4279 8.69062 15.4279 9V14.625Z" fill="#F15642"/>
    <path d="M2.80273 10.6577C2.80273 10.5092 2.93644 10.3472 3.15179 10.3472H4.33909C5.00763 10.3472 5.60932 10.7387 5.60932 11.489C5.60932 12.2 5.00763 12.596 4.33909 12.596H3.48092V13.19C3.48092 13.388 3.33692 13.5 3.15179 13.5C2.98208 13.5 2.80273 13.388 2.80273 13.19V10.6577ZM3.48092 10.9136V12.0341H4.33909C4.68365 12.0341 4.95621 11.768 4.95621 11.489C4.95621 11.1746 4.68365 10.9136 4.33909 10.9136H3.48092Z" fill="white"/>
    <path d="M6.61656 13.5C6.44685 13.5 6.26172 13.419 6.26172 13.2216V10.6667C6.26172 10.5053 6.44685 10.3877 6.61656 10.3877H7.79358C10.1425 10.3877 10.091 13.5 7.83986 13.5H6.61656ZM6.94054 10.9367V12.9516H7.79358C9.18144 12.9516 9.24315 10.9367 7.79358 10.9367H6.94054Z" fill="white"/>
    <path d="M10.9244 10.9728V11.6877H12.2351C12.4202 11.6877 12.6053 11.8497 12.6053 12.0066C12.6053 12.1551 12.4202 12.2766 12.2351 12.2766H10.9244V13.2211C10.9244 13.3786 10.7964 13.4995 10.6164 13.4995C10.3902 13.4995 10.252 13.3786 10.252 13.2211V10.6662C10.252 10.5048 10.3908 10.3872 10.6164 10.3872H12.4209C12.6471 10.3872 12.7808 10.5048 12.7808 10.6662C12.7808 10.8102 12.6471 10.9722 12.4209 10.9722H10.9244V10.9728Z" fill="white"/>
    <path d="M14.786 15.1875H2.57227V15.75H14.786C15.1395 15.75 15.4288 15.4969 15.4288 15.1875V14.625C15.4288 14.9344 15.1395 15.1875 14.786 15.1875Z" fill="#CAD1D8"/>
  </svg>
);

const SearchIcon = ({
  width = '26',
  height = '26',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => ( 
<svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2002_781)">
      <path d="M16.5 3.35449H12.75C12.4938 3.35449 12.2812 3.15616 12.2812 2.91699C12.2812 2.67783 12.4938 2.47949 12.75 2.47949H16.5C16.7562 2.47949 16.9688 2.67783 16.9688 2.91699C16.9688 3.15616 16.7562 3.35449 16.5 3.35449Z" fill="#23457A"/>
      <path d="M14.625 5.10449H12.75C12.4938 5.10449 12.2812 4.90616 12.2812 4.66699C12.2812 4.42783 12.4938 4.22949 12.75 4.22949H14.625C14.8812 4.22949 15.0938 4.42783 15.0938 4.66699C15.0938 4.90616 14.8812 5.10449 14.625 5.10449Z" fill="#23457A"/>
      <path d="M11.1875 12.6878C7.65625 12.6878 4.78125 10.0045 4.78125 6.70866C4.78125 3.41283 7.65625 0.729492 11.1875 0.729492C11.4437 0.729492 11.6562 0.927826 11.6562 1.16699C11.6562 1.40616 11.4437 1.60449 11.1875 1.60449C8.16875 1.60449 5.71875 3.89699 5.71875 6.70866C5.71875 9.52033 8.16875 11.8128 11.1875 11.8128C14.2063 11.8128 16.6562 9.52033 16.6562 6.70866C16.6562 6.46949 16.8687 6.27116 17.125 6.27116C17.3813 6.27116 17.5938 6.46949 17.5938 6.70866C17.5938 10.0045 14.7188 12.6878 11.1875 12.6878Z" fill="#23457A"/>
      <path d="M17.7504 13.2707C17.6316 13.2707 17.5129 13.2298 17.4191 13.1423L16.1691 11.9757C15.9879 11.8065 15.9879 11.5265 16.1691 11.3573C16.3504 11.1882 16.6504 11.1882 16.8316 11.3573L18.0816 12.524C18.2629 12.6932 18.2629 12.9732 18.0816 13.1423C17.9879 13.2298 17.8691 13.2707 17.7504 13.2707Z" fill="#23457A"/>
      </g>
      <defs>
      <filter id="filter0_d_2002_781" x="0" y="0" width="23" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2002_781"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2002_781" result="shape"/>
      </filter>
      </defs>
  </svg>
);

const ImageEditIcon = ({
  width = '25',
  height = '25',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => ( 
<svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.37533 11.1979C7.79199 11.1979 6.51074 9.91667 6.51074 8.33333C6.51074 6.75 7.79199 5.46875 9.37533 5.46875C10.9587 5.46875 12.2399 6.75 12.2399 8.33333C12.2399 9.91667 10.9587 11.1979 9.37533 11.1979ZM9.37533 7.03125C8.65658 7.03125 8.07324 7.61458 8.07324 8.33333C8.07324 9.05208 8.65658 9.63542 9.37533 9.63542C10.0941 9.63542 10.6774 9.05208 10.6774 8.33333C10.6774 7.61458 10.0941 7.03125 9.37533 7.03125Z" fill="#CACACA"/>
<path d="M15.6247 23.6986H9.37467C3.71842 23.6986 1.30176 21.2819 1.30176 15.6257V9.37565C1.30176 3.7194 3.71842 1.30273 9.37467 1.30273H13.5413C13.9684 1.30273 14.3226 1.6569 14.3226 2.08398C14.3226 2.51107 13.9684 2.86523 13.5413 2.86523H9.37467C4.57259 2.86523 2.86426 4.57357 2.86426 9.37565V15.6257C2.86426 20.4277 4.57259 22.1361 9.37467 22.1361H15.6247C20.4268 22.1361 22.1351 20.4277 22.1351 15.6257V10.4173C22.1351 9.99023 22.4893 9.63607 22.9163 9.63607C23.3434 9.63607 23.6976 9.99023 23.6976 10.4173V15.6257C23.6976 21.2819 21.2809 23.6986 15.6247 23.6986Z" fill="#CACACA"/>
<path d="M16.3227 10.1566C15.9164 10.1566 15.5414 10.0107 15.2706 9.72949C14.9477 9.40657 14.7914 8.92741 14.8643 8.43782L15.0727 6.98991C15.1248 6.62532 15.3435 6.17741 15.6143 5.91699L19.3956 2.13574C20.906 0.625323 22.2185 1.48991 22.8643 2.13574C23.4789 2.75032 23.7602 3.39616 23.6977 4.06282C23.6456 4.59407 23.3748 5.10449 22.8643 5.60449L19.0831 9.38574C18.8227 9.64616 18.3748 9.86491 18.0102 9.92741L16.5623 10.1357C16.4789 10.1566 16.3956 10.1566 16.3227 10.1566ZM20.4893 3.25032L16.7081 7.03157C16.6768 7.06282 16.6248 7.17741 16.6143 7.22949L16.4164 8.58366L17.781 8.39616C17.8227 8.38574 17.9373 8.33366 17.9789 8.29199L21.7602 4.51074C21.9893 4.28157 22.1248 4.07324 22.1352 3.91699C22.156 3.69824 21.9373 3.42741 21.7602 3.25032C21.2185 2.70866 20.9685 2.77116 20.4893 3.25032Z" fill="#CACACA"/>
<path d="M21.7711 6.38537C21.6982 6.38537 21.6253 6.37496 21.5628 6.35412C20.167 5.95829 19.0524 4.84371 18.6565 3.44787C18.5419 3.03121 18.7815 2.60412 19.1982 2.48954C19.6149 2.37496 20.0419 2.61454 20.1565 3.03121C20.4065 3.90621 21.1045 4.61454 21.9899 4.86454C22.4065 4.97912 22.6461 5.41662 22.5315 5.82287C22.4169 6.15621 22.1044 6.38537 21.7711 6.38537Z" fill="#CACACA"/>
<path d="M2.78151 20.5215C2.53151 20.5215 2.28151 20.3965 2.13568 20.1777C1.8961 19.8236 1.98985 19.334 2.34401 19.0944L7.47943 15.6465C8.60443 14.8965 10.1565 14.9798 11.1773 15.8444L11.5211 16.1465C12.0419 16.5944 12.9273 16.5944 13.4378 16.1465L17.7711 12.4277C18.8753 11.4798 20.6149 11.4798 21.7294 12.4277L23.4273 13.8861C23.7503 14.1673 23.7919 14.6569 23.5107 14.9902C23.2294 15.3132 22.7399 15.3548 22.4065 15.0736L20.7086 13.6152C20.1878 13.1673 19.3024 13.1673 18.7919 13.6152L14.4586 17.334C13.3544 18.2819 11.6148 18.2819 10.5003 17.334L10.1565 17.0319C9.67735 16.6257 8.88568 16.584 8.35443 16.9486L3.22943 20.3965C3.0836 20.4798 2.92735 20.5215 2.78151 20.5215Z" fill="#CACACA"/>
</svg>
);

const ImageRemoveIcon = ({
  width = '25',
  height = '25',
  inlineStyles,
  inlineClass,
  onClick
}: Pick<
  Props,
  'width' | 'height' | 'inlineStyles' | 'inlineClass' | 'onClick'
>) => ( 
<svg width={width} height={height} style={inlineStyles} className={inlineClass} onClick={onClick}viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.37533 11.1979C7.79199 11.1979 6.51074 9.91667 6.51074 8.33333C6.51074 6.75 7.79199 5.46875 9.37533 5.46875C10.9587 5.46875 12.2399 6.75 12.2399 8.33333C12.2399 9.91667 10.9587 11.1979 9.37533 11.1979ZM9.37533 7.03125C8.65658 7.03125 8.07324 7.61458 8.07324 8.33333C8.07324 9.05208 8.65658 9.63542 9.37533 9.63542C10.0941 9.63542 10.6774 9.05208 10.6774 8.33333C10.6774 7.61458 10.0941 7.03125 9.37533 7.03125Z" fill="#CACACA"/>
<path d="M15.6247 23.6986H9.37467C3.71842 23.6986 1.30176 21.2819 1.30176 15.6257V9.37565C1.30176 3.7194 3.71842 1.30273 9.37467 1.30273H13.5413C13.9684 1.30273 14.3226 1.6569 14.3226 2.08398C14.3226 2.51107 13.9684 2.86523 13.5413 2.86523H9.37467C4.57259 2.86523 2.86426 4.57357 2.86426 9.37565V15.6257C2.86426 20.4277 4.57259 22.1361 9.37467 22.1361H15.6247C20.4268 22.1361 22.1351 20.4277 22.1351 15.6257V10.4173C22.1351 9.99023 22.4893 9.63607 22.9163 9.63607C23.3434 9.63607 23.6976 9.99023 23.6976 10.4173V15.6257C23.6976 21.2819 21.2809 23.6986 15.6247 23.6986Z" fill="#CACACA"/>
<path d="M17.2503 8.01042C17.0524 8.01042 16.8545 7.9375 16.6982 7.78125C16.3962 7.47917 16.3962 6.97917 16.6982 6.67708L20.7503 2.625C21.0524 2.32292 21.5524 2.32292 21.8545 2.625C22.1566 2.92708 22.1566 3.42708 21.8545 3.72917L17.8024 7.78125C17.6462 7.9375 17.4482 8.01042 17.2503 8.01042Z" fill="#CACACA"/>
<path d="M21.2917 8.01042C21.0938 8.01042 20.8958 7.9375 20.7396 7.78125L16.6875 3.72917C16.3854 3.42708 16.3854 2.92708 16.6875 2.625C16.9896 2.32292 17.4896 2.32292 17.7917 2.625L21.8438 6.67708C22.1458 6.97917 22.1458 7.47917 21.8438 7.78125C21.6979 7.9375 21.5 8.01042 21.2917 8.01042Z" fill="#CACACA"/>
<path d="M2.78151 20.5215C2.53151 20.5215 2.28151 20.3965 2.13568 20.1777C1.8961 19.8236 1.98985 19.334 2.34401 19.0944L7.47943 15.6465C8.60443 14.8965 10.1565 14.9798 11.1773 15.8444L11.5211 16.1465C12.0419 16.5944 12.9273 16.5944 13.4378 16.1465L17.7711 12.4277C18.8753 11.4798 20.6149 11.4798 21.7294 12.4277L23.4273 13.8861C23.7503 14.1673 23.7919 14.6569 23.5107 14.9902C23.2294 15.3132 22.7399 15.3548 22.4065 15.0736L20.7086 13.6152C20.1878 13.1673 19.3024 13.1673 18.7919 13.6152L14.4586 17.334C13.3544 18.2819 11.6148 18.2819 10.5003 17.334L10.1565 17.0319C9.67735 16.6257 8.88568 16.584 8.35443 16.9486L3.22943 20.3965C3.0836 20.4798 2.92735 20.5215 2.78151 20.5215Z" fill="#CACACA"/>
</svg>
);

export {
  BurgerIcon,
  LogoIcon,
  HomeIcon,
  RemoveIcon,
  OpenListIcon,
  CloseIcon,
  SearchMobileIcon,
  OptionsIcon,
  SortIcon,
  Logo,
  Icon,
  ArrowIcon,
  NotificationsIcon,
  Close2Icon,
  Search2Icon,
  SearchIcon,
  FileExportIcon,
  PdfIcon,
  ImageEditIcon,
  ImageRemoveIcon,
};