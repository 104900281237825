import { CSSProperties, FC, ReactNode } from "react";
import { Link, useMatch } from "react-router-dom";
import styles from "./CustomLink.module.scss";
import clsx from "clsx";

type CustomLinkProps = {
    children: ReactNode,
    to: string,
    style?: CSSProperties,
    classNames?: string,
}

const CustomLink: FC<CustomLinkProps> = ({children, to, style, classNames}) => {
    const match = useMatch(to);

    return (
        <Link 
            to={to}
            style={style}
            className={clsx(match ? styles.active : styles.default, classNames)}
        >
            {children}
        </Link>
    )
};

export default CustomLink;