import { CSSProperties } from "react";
import styles from "./UserProfile.module.scss";
import { useAppSelector } from "app/store/hooks/redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

type Props = {
    className?: string;
    style?: CSSProperties;
}

const UserProfile = ({className, style}: Props) => {
    const user = useAppSelector(state => state.userReducer);
    const navigate = useNavigate();

    const clickEventHandler = ()=>{
        navigate("/profile")
    };

    return (
        <div onClick={clickEventHandler} className={clsx(styles.userProfile, className)} style={style}>
            <div className={styles.userName}>{user.name  ? user.name : "Профиль"}</div>
        </div>
    )
};

export default UserProfile;