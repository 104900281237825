import clsx from "clsx";
import { CSSProperties, useRef, useState } from "react";
import styles from "./index.module.scss";

type PropsType = {
    classNames?: string;
    classNamesContainer?: string;
    inlineStyles?: CSSProperties;
    inlineStylesContainer?: CSSProperties;
    value: string | null | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: string;
}

const Input = ({classNames, onChange, value="", inlineStyles, placeholder, type="text", classNamesContainer, inlineStylesContainer}: PropsType) => {
    const ref = useRef<HTMLInputElement>(null);
    const [isFocus, setIsFocus] = useState(false);
    
    return (
        <div onClick={e => e.stopPropagation()} className={clsx(styles.container, isFocus && styles.inputFocus, isFocus || value ? styles.inputValue : "", classNamesContainer)} style={inlineStylesContainer}>
            {value?.length != 0 || isFocus ? <div className={clsx(styles.placeholder)}>{placeholder}</div> : ""}
            <input 
                type={type}
                className={clsx(styles.input, classNames)} 
                style={inlineStyles} 
                value={value ? value : ""} 
                onChange={onChange}
                placeholder={!isFocus ? placeholder : ""}
                ref={ref}
                onFocus={e => setIsFocus(true)}
                onBlur={e => setIsFocus(false)}
                />
        </div>
    );
};

export {
    Input
};