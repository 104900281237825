import { PaymentStatusEnum } from "api";

export const getPaymentBillStatus = (status: PaymentStatusEnum) => {
    if (status == 0) {
        return "Оплачен";
    }
    else if (status == 1) { 
        return "Не выплачено";
    }
    else if (status == 2) {
        return "Отменен";
    }
}

export const getColorByPaymentBillStatus = (status: PaymentStatusEnum) => {
    if (status == 0) {
        return "#6CCD6A";
    }
    else if (status == 1) { 
        return "#FF6C6C";
    }
    else if (status == 2) {
        return "#cacaca";
    }
}