import { ReactNode } from "react";
import styles from "./index.module.scss";

type PropsType = {
    children: ReactNode;
    width: string;
}

const TableItemContainer = ({children, width}: PropsType) => {
    return (
        <div className={styles.container} style={{width: width}}>
            {children}
        </div>
    );
};

export {
    TableItemContainer
};