import Layout from "widgets/Layout/Layout";
import React, { useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/ui/Button";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import Container from "widgets/Container/ui";
import {Container as SharedContainer} from "shared/ui/Container";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetPaymentsBillsQuery } from "api";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useAppSelector } from "app/store/hooks/redux";
import { getColorByPaymentBillStatus, getPaymentBillStatus } from "functions/paymentBillStatus";
import clsx from "clsx";
import { FileExportIcon } from "shared/ui/icons/icons";

const PaymentBillsPage = () => {
    const {id: userId} = useAppSelector(state => state.userReducer);

    const {data: bills, refetch } = useGetPaymentsBillsQuery({userId: userId});
    // const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
    // const {data: document} = useGetDocumentsExcelBillsQuery({billId: selectedBillId!}, {skip: selectedBillId == null});

    // useEffect(() => {
    //     if (document != null)
    //     {
    //         window.open(BASE_URL_FILES + document.name, '_blank');
    //     }
    // }, [document]);

    const [searchText, setSearchText] = useState("");
    
    const navigate = useNavigate();

    const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(["Юр.лицо"]);
    const options = ["Юр.лицо", "ID Счёта", "Статус оплаты", "Сумма выплаты"];

    const filteredData = bills?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.organization?.name, option: "Юр.лицо"},
        {value: el.id, option: "ID Счёта"},
        {value: getPaymentBillStatus(el.paymentStatus!), option: "Статус оплаты"},
        {value: el.paymentAmount, option: "Сумма выплаты"},
    ]));
    
    const [selectedOption, setSelectedOption] = useState("Юр.лицо");

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.organization?.name, value1: el1.organization?.name, option: "Юр.лицо"},
        {value2: el2.id,  value1: el1.id, option: "ID Счёта"},
        {value2: getPaymentBillStatus(el2.paymentStatus!), value1: getPaymentBillStatus(el1.paymentStatus!), option: "Статус оплаты"},
        {value2: el2.paymentAmount, value1: el1.paymentAmount, option: "Сумма выплаты"},
    ], selectedOption, isDesc));

    return (
        <Layout>
            <Container>
                <div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Обновить" onClick={refetch} />
                        <Button text="Создать счёт" onClick={() => navigate(LinkPagesEnum.AddingAcceptanceBill)}/>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SearchInput 
                            value={searchText} 
                            onChange={setSearchText}
                            inlineStyles={{marginRight: 10}}
                            classNames={styles.searchInput}
                            />
                        <MultipleSelect 
                            text="Параметры"
                            options={options} 
                            selectedOptions={selectedOptions} 
                            setSelectedOptions={setSelectedOptions}
                            inlineStyles={{marginRight: 10}}
                            inlineClasses={styles.select}
                            />
                        <Sort 
                            options={options}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setIsDesc={setIsDesc}
                            isDesc={isDesc}
                            selectClassNames={styles.select}
                            />
                    </div>
                </div>
                <div className={styles.body}>
                    {sortedData?.map(paymentBill => 
                        <SharedContainer inlineClasses={styles.paymentBill} onClick={() => navigate(LinkPagesEnum.PaymentBill + "?billId=" + paymentBill.id)}>
                            <div className={styles.textBlock}>
                                <div>Юр лицо:</div>
                                <div>{paymentBill.organization?.name}</div>
                            </div>
                            <div className={styles.textBlock}>
                                <div>Общая сумма выплаты:</div>
                                <div>{paymentBill.paymentAmount}</div>
                            </div>
                            <div className={styles.textBlock}>
                                <div>
                                    Статус оплаты:
                                </div>
                                <div style={{color: getColorByPaymentBillStatus(paymentBill.paymentStatus!)}}>
                                    {getPaymentBillStatus(paymentBill.paymentStatus!)}
                                </div>
                            </div>
                            <div className={styles.textBlock}>
                                <div>
                                    ID счёта:
                                </div>
                                <div>
                                    {paymentBill.id}
                                </div>
                            </div>
                            <FileExportIcon inlineClass={styles.fileExport} onClick={() => {}}/>
                        </SharedContainer>
                    )}
                </div>
            </Container>
        </Layout>
    )
};

export {PaymentBillsPage};